import * as $ from 'jquery';
import { Webinar } from '../../../interfaces/webinar';

import { l10n } from './l10n';
import { TranslationKey } from '../enums/translationKey';
import { WebinarItemName } from '../enums/webinarItemName';

export interface IWebinar {
  [WebinarItemName.ID]: string | number;
  [WebinarItemName.STARTS_AT_DATE]: string;
  [WebinarItemName.STARTS_AT_TIME]: string;
  [WebinarItemName.STARTS_AT_TIME_ZONE]: string;
  [WebinarItemName.NAME]?: string;
  [WebinarItemName.ROOM_LINK]?: string;
}

export class WebinarHelper {
  public static getDefaultWebinar(): IWebinar {
    const now = new Date();
    const dateTimeFormat = Intl.DateTimeFormat(navigator.language);
    const startsAtDate = dateTimeFormat.format(now);
    const startsAtTime = Intl.DateTimeFormat(navigator.language, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(now);
    const startsAtTimeZone = dateTimeFormat.resolvedOptions().timeZone;

    return {
      id: '',
      name: l10n.get(TranslationKey.ModSqueezePageLPCCreatePageWebinarTitle),
      starts_at_date: startsAtDate,
      starts_at_time: startsAtTime,
      starts_at_time_zone: startsAtTimeZone,
    };
  }

  public static getWebinar(webinarsList: Webinar[], id: string): Webinar {
    const l = webinarsList.length;

    for (let i = 0; i < l; i++) {
      if (webinarsList[i].id === Number(id)) {
        return webinarsList[i];
      }
    }

    return null;
  }

  public static getWebinarElement(): JQuery<HTMLElement> {
    return $(`<div>${window.APP.getItem('lpsInitialData').variants[0].content}</div>`).find(
      '[data-editable="webinarNew"]',
    );
  }

  public static hasWebinarId(): boolean {
    const webinar = this.getWebinarElement();
    const webinarId = webinar ? webinar.attr('data-webinar-id') : null;

    return !!webinarId;
  }

  public static hasWebinar(): boolean {
    return this.getWebinarElement().length > 0;
  }
}
