import stripeIntegrationHelper from '../../../editor/helpers/stripeIntegration';

import { OtoPage } from '../../prototypes/otoPage';

import { OtoPageStrategy } from './otoPage';

import { LPSData } from '../../models/config';

import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';
import AutoFunnelHelper from '../../helpers/autofunnel';

import { StripePaymentIntentStatus } from '../../enums/stripePaymentIntentStatus';

interface IPaymentResponse {
  status: StripePaymentIntentStatus;
  client_secret: string;
  uuid: string;
}

export class StripeOtoPageStrategy extends OtoPageStrategy {
  private readonly stripeIntegration: any;

  constructor() {
    super();
    this.stripeIntegration = stripeIntegrationHelper.getStripeIntegration(LPSData.getPaymentStripeAPIKey());
  }

  public proceedPayment(otoPageAcceptUrl: string, productId: string): void {
    if (this.stripeIntegration) {
      AutoFunnelHelper.startProcessPayment(
        this.getOTOPaymentData(productId),
        otoPageAcceptUrl,
        this.paymentDoneCallback,
      );
    } else {
      logger.warn('Missing stripe integration');
    }
  }

  private paymentDoneCallback(response: IPaymentResponse): void {
    if (response.status === StripePaymentIntentStatus.REQUIRES_CONFIRMATION) {
      stripeIntegrationHelper.submitPaymentWithConfirmation(response.client_secret, {}, (paymentResponse) => {
        if (paymentResponse.error) {
          notify.alert(paymentResponse.error.message);
          OtoPage.enableProductBoxButtons();
        } else if (paymentResponse.paymentIntent) {
          if (paymentResponse.paymentIntent.status === StripePaymentIntentStatus.SUCCEEDED) {
            AutoFunnelHelper.checkPaymentStatus(response.uuid, AutoFunnelHelper.checkPaymentStatusCallback.bind(this));
          }
        }
      });
    } else {
      AutoFunnelHelper.checkPaymentStatus(response.uuid, AutoFunnelHelper.checkPaymentStatusCallback.bind(this));
    }
  }
}
