import * as WebFontLoader from 'webfontloader';
import { ErrorMonitor } from '../../monitors/errorMonitor/ErrorMonitor';
import { BaseLogger } from '../logger';
import { ErrorType } from '../../monitors/errorMonitor/enums/ErrorType';

export class WebFont {
  private static onInactive(): void {
    BaseLogger.log('None of the fonts could be loaded.');
  }

  private static onFontInactive(familyName: string, fontDescription: string): void {
    BaseLogger.log(`Failed to load font: ${familyName} - ${fontDescription}`);
  }

  public static loadScript(): Promise<{ load: (data: WebFontLoader.Config) => void }> {
    return import(/* webpackChunkName: "lps-webfont-module" */ 'webfontloader').catch((error) => {
      BaseLogger.log('Failed to load Web Font Loader script');
      ErrorMonitor.captureException(error, {
        tags: {
          script: 'webfont.min',
          error_type: ErrorType.ScriptLoadError,
        },
      });
      throw error;
    });
  }

  public static load(data: WebFontLoader.Config): Promise<void> {
    return WebFont.loadScript().then(({ load }) => {
      load({
        ...data,
        inactive: WebFont.onInactive,
        fontinactive: WebFont.onFontInactive,
      });
    });
  }
}
