var div = document.getElementsByClassName('.backdrop')[0];

var helper = {
  on: function (element, type, fn) {
    if (element.addEventListener) {
      element.addEventListener(type, fn, false);
    } else {
      if (element.attachEvent) {
        element.attachEvent('on' + type, fn);
      }
    }
  },
  off: function (element, type, fn) {
    if (element.removeEventListener) {
      element.removeEventListener(type, fn, false);
    } else {
      if (element.detachEvent) {
        element.detachEvent('on' + type, fn);
      }
    }
  },
  cancelEvent: function (e) {
    e = e ? e : window.event;
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.cancelBubble = true;
    e.cancel = true;
    e.returnValue = false;
    return false;
  },
  parent: function (element, selector, cx) {
    var all,
      parent = element.parentNode;

    if (selector) {
      all = Array.prototype.slice.call((cx || element.ownerDocument).querySelectorAll(selector));

      while (parent && !all.includes(parent)) {
        parent = parent.parentNode;
      }
    }

    return parent;
  },
  webform: function (webform) {
    var inputs = Array.prototype.slice.call(
        webform.querySelectorAll('input[type="text"], input[type="email"], textarea'),
      ),
      ln = inputs.length,
      i;

    for (i = 0; i < ln; i += 1) {
      if (!inputs[i].labels) {
        inputs[i].labels = webform.querySelectorAll('label[for="' + inputs[i].id + '"]');
      }

      inputs[i].disabled = false;

      helper.placeholder(webform, inputs[i]);
    }
  },

  placeholder: function (webform, input) {
    var isNewWebform = webform.getAttribute('data-editable') === 'webformNew';
    var isNewWebinar = webform.getAttribute('data-editable') === 'webinarNew';

    if (isNewWebform || isNewWebinar) {
      (function () {
        if (webform.getAttribute('data-label-type') === 'placeholder') {
          var inputId = (input.id || '').replace('-for', ''); //Remove it
          //Special case, 2 labels with the same uid and id (phone field) for the rest should be only one
          var label,
            labelFields = Array.prototype.slice.call(
              webform.querySelectorAll('[data-editable-item="label"]#' + inputId),
            );

          for (var i = 0; i < labelFields.length; i++) {
            var label = labelFields[i];

            //There can be hidden label when phone-field is in placeholder mode
            if (!$(label).is(':hidden')) {
              helper.on(
                label,
                'click',
                function (input, e) {
                  input.focus();
                }.bind(null, input),
              );

              helper.on(
                label,
                'click',
                function (input, label, e) {
                  label.style.display = 'none';
                  input.focus();
                }.bind(null, input, label),
              );

              helper.on(
                input,
                'focus',
                function (input, label) {
                  label.style.display = 'none';
                }.bind(null, input, label),
              );

              helper.on(
                input,
                'blur',
                function (input, label, e) {
                  if (input.value.trim()) {
                    return;
                  }

                  label.style.display = 'inline-block';
                }.bind(null, input, label),
              );

              if (input.value) {
                label.style.display = 'none';
              }
            }
          }
        }
      })();
    } else {
      (function () {
        var offsetParent = helper.parent(input, 'div.type-placeholder'),
          label,
          parent;

        if (!offsetParent) {
          return;
        }

        if (input.labels && input.labels[0]) {
          label = input.labels[0];
        } else {
          label = offsetParent.getElementsByTagName('label')[0];
        }

        parent = label.parentNode;

        if (parent) {
          if (!input.value.trim()) {
            parent.style.display = 'block';
          }

          parent.style.width = input.offsetWidth + 'px';
          //Ustaw wysokosc div.label aby wypozyjonowal sie odpowiednio wzgledem inputa
          parent.style.height = input.offsetHeight + 'px';
        }

        helper.on(input, 'focus', function () {
          try {
            parent.style.display = 'none';
          } catch (ex) {}
        });

        helper.on(input, 'blur', function () {
          if (input.value.trim()) {
            return;
          }
          try {
            parent.style.display = 'block';
          } catch (ex) {}
        });
      })();
    }
  },
};

export default {
  initialize: function () {
    var webforms = Array.prototype.slice.call(
      document.body.querySelectorAll(
        '[data-editable="webform"], [data-editable="webformNew"], [data-editable="webinarNew"]',
      ),
    );

    if (div) {
      div.parentNode.removeChild(div);
    }

    for (var i = 0; i < webforms.length; i += 1) {
      helper.webform(webforms[i]);
    }
  },
};
