import { BaseFormElement } from '../strategies/formElement/baseFormElement';
import { BlueSnapFormElement } from '../strategies/formElement/blueSnapFormElement';
import { PayPalFormElement } from '../strategies/formElement/paypalFormElement';
import { PayUFormElement } from '../strategies/formElement/payuFormElement';
import { QiwiFormElement } from '../strategies/formElement/qiwiFormElement';
import { SquareFormElement } from '../strategies/formElement/squareFormElement';
import { StripeFormElement } from '../strategies/formElement/stripeFormElement';
import { YandexFormElement } from '../strategies/formElement/yandexFormElement';

import { LPSData } from '../models/config';

export class FormElement {
  public static createFormElement(form: HTMLFormElement): BaseFormElement {
    if (LPSData.hasPayPalIntegrationAndCorrectElements(form)) {
      return new PayPalFormElement(form);
    } else if (LPSData.hasPayUIntegrationAndCorrectElements(form)) {
      return new PayUFormElement(form);
    } else if (LPSData.hasStripeIntegrationAndCorrectElements(form)) {
      return new StripeFormElement(form);
    } else if (LPSData.hasBlueSnapIntegrationAndCorrectElements(form)) {
      return new BlueSnapFormElement(form);
    } else if (LPSData.hasSquareIntegrationAndCorrectElements(form)) {
      return new SquareFormElement(form);
    } else if (LPSData.hasQiwiIntegrationAndCorrectElements(form)) {
      return new QiwiFormElement(form);
    } else if (LPSData.hasYandexIntegrationAndCorrectElements(form)) {
      return new YandexFormElement(form);
    }

    return new BaseFormElement(form);
  }
}
