import $ from 'jquery';
import { ProductList } from '../../../enums/productList';
import { CurrencyHelper } from '../../../helpers/currency';

import { LPSData } from '../models/config';

function ProductListElement(form, productListElement) {
  this.form = form;
  this.el = productListElement;
  this.elTotalPrice = this.el.querySelector('[data-component-part="product-list-total-price"]');
  $(this.getProductsInputs()).on('change', () => {
    this.updateTotalPrice();
    if (this.hasError()) {
      this.toggleProductsError(this.hasSelectedProducts());
    }
  });

  this.updateTotalPrice();
}

ProductListElement.prototype.updateTotalPrice = function () {
  const selectedProducts = this.getSelectedProducts();
  const shopId = this.getShopId();
  const currency = LPSData.getECommerceShopCurrency(shopId);
  const products = LPSData.getECommerceShopProducts(shopId);
  const floatingPointErrorReducer = 100;
  const sum = products.reduce((actualSum, product) => {
    if (selectedProducts.hasOwnProperty(product.id)) {
      return actualSum + product.price * floatingPointErrorReducer * selectedProducts[product.id];
    }
    return actualSum;
  }, 0);

  this.elTotalPrice.innerHTML = CurrencyHelper.format(
    String(sum / floatingPointErrorReducer),
    window.grLpsInitialData.user_panel_lang,
    currency,
  );
};

ProductListElement.prototype.getTotalPrice = function () {
  return Number(this.elTotalPrice.innerText.replace(/\D/g, ''));
};

ProductListElement.prototype.getShopId = function () {
  const isAutoFunnelConnected = LPSData.isAutoFunnelConnected();
  let shopId = this.el.getAttribute('data-shop-id');

  if (!shopId && isAutoFunnelConnected) {
    shopId = LPSData.getFunnelShopId();
  }

  return shopId;
};

ProductListElement.prototype.hasError = function () {
  const $elProductList = $(this.el);
  return $elProductList.hasClass('error');
};

ProductListElement.prototype.toggleProductsError = function (hasProductsSelected) {
  const $elProductList = $(this.el);

  if (hasProductsSelected) {
    $elProductList.removeClass('error');
    $elProductList.removeAttr('data-error-msg');
  } else {
    $elProductList.addClass('error');
    $elProductList.attr(
      'data-error-msg',
      window.grLpsInitialData.l10n.ModSqueezePageLPCCreatePageAutoFunnelProductsNotSelected,
    );
  }
};

ProductListElement.prototype.hasSelectedProducts = function () {
  return _.keys(this.getSelectedProducts()).length > 0;
};

ProductListElement.prototype.getSelectedProducts = function () {
  const elements = Array.prototype.slice.call(this.form.elements);
  const quantity = elements.reduce((actualProducts, element) => {
    if (element.name === ProductList.QUANTITY_FIELD_NAME) {
      const productId = element.getAttribute('data-product-id-quantity');
      const productQuantity = Number(element.value);
      if (productId && !window.isNaN(productQuantity)) {
        actualProducts[productId] = productQuantity;
      }
    }
    return actualProducts;
  }, {});
  return elements.reduce((actualProducts, element) => {
    if (element.name === ProductList.FIELD_NAME) {
      if (element.checked) {
        actualProducts[element.value] = quantity.hasOwnProperty(element.value) ? quantity[element.value] : 1;
      }
    }
    return actualProducts;
  }, {});
};

ProductListElement.prototype.getProductsInputs = function () {
  let productsInputs = this.form.elements[ProductList.FIELD_NAME];
  let productsQuantity = this.form.elements[ProductList.QUANTITY_FIELD_NAME];

  if (productsInputs) {
    //For one element, form.elements returns element which is not in an array
    if (!productsInputs.length) {
      productsInputs = [productsInputs];
    }
  } else {
    productsInputs = [];
  }
  if (productsQuantity) {
    //For one element, form.elements returns element which is not in an array
    if (!productsQuantity.length) {
      productsQuantity = [productsQuantity];
    }
  } else {
    productsQuantity = [];
  }

  return [...productsInputs, ...productsQuantity]; // "..." bo elements mogłobyć NodeList-ą, a chce uzywac jej jako tablicy
};

export default ProductListElement;
