import { WebinarHelper } from '../../editor/helpers/webinar';

function setWebinarData(elWebinar, webinar) {
  var matchwords = Array.prototype.slice.call(elWebinar.querySelectorAll('[data-webinar-item]'));

  elWebinar.classList.remove('no-webinar');

  //fill out with data only when webinar exist, otherwise show old content
  if (webinar) {
    matchwords.forEach((el) => {
      var name = el.getAttribute('data-webinar-item'),
        value = webinar[name] || '';

      if (name === 'room_link') {
        el.href = value;
      } else {
        el.innerHTML = value;
      }
    });
  }
}

function fakeTabComponent(el, webinar, index) {
  var tabPanels = Array.prototype.slice.call(el.querySelectorAll('[role="tabpanel"]')),
    i;

  for (i = 0; i < tabPanels.length; i++) {
    tabPanels[i].setAttribute('aria-hidden', true);
  }

  tabPanels[index].removeAttribute('aria-hidden');
}

function resetConferenceId(element) {
  var webinarId = element.getAttribute('data-webinar-id'),
    conferenceIdInput = element.querySelector('[name="gr_conference_id"]');
  if (conferenceIdInput && conferenceIdInput.value !== webinarId) {
    conferenceIdInput.setAttribute('value', webinarId);
  }
}

export default {
  initialize: () => {
    var webinars = Array.prototype.slice.call(document.querySelectorAll('[data-editable="webinar"]')),
      webinarsNew = Array.prototype.slice.call(document.querySelectorAll('[data-editable="webinarNew"]'));

    webinars.forEach((el) => {
      var webinarId = el.getAttribute('data-webinar-id'),
        webinar = WebinarHelper.getWebinar(window.grLpsInitialData.webinars.list, webinarId),
        index = 0;

      fakeTabComponent(el, webinar, index);
      setWebinarData(el, webinar);
    });

    webinarsNew.forEach(resetConferenceId);
  },
};
