function setMinHeightWhenOneSection() {
  const allSection = document.querySelectorAll('#Preview > section');

  if (1 !== allSection.length) {
    return;
  }

  const height = parseInt(window.getComputedStyle(allSection[0], '').height, 10);

  if (isNaN(height) || height < 1365) {
    allSection[0].style.minHeight = '1365px';
  }
}

export default {
  setMinHeightWhenOneSection: setMinHeightWhenOneSection,
};
