export default {
  create: function (name, value, days) {
    var expires = '',
      date;

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }

    document.cookie = name + '=' + value + expires + '; path=/';
  },
  get: function (name) {
    var regexp = new RegExp('(?:;)?' + name + '=([^;]*);?');
    if (regexp.test(document.cookie)) {
      return decodeURIComponent(RegExp.$1);
    }
    return null;
  },
  has: function (name) {
    return !!this.get(name);
  },
  remove: function (name) {
    this.create(name, '', -1);
  },
  /**
   * @deprecated
   *
   * Skopiowany ze starego kodu edytora podczas porządków
   *
   */
  getCookieByName: function getCookieByName(name) {
    var regexp = new RegExp('(?:;)?' + name + '=([^;]*);?');

    if (regexp.test(document.cookie)) {
      return window.decodeURIComponent(RegExp.$1);
    }
  },
};
