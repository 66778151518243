import * as $ from 'jquery';
import { IL10n } from '../interfaces/l10n';
import { TranslationKey } from '../interfaces/lpsShow';

export class FormItem {
  public static initialize(translations: IL10n): void {
    FormItem.initializeDateFields(translations);
  }

  public static initializeDateFields(translations: IL10n): void {
    $<HTMLDivElement>(
      '[data-editable="webformNewItem"][data-item-what="date"] [data-editable-item="field"]',
      document.body,
    ).each((i, field) => {
      FormItem.convertTextFieldToData(translations, field);
    });
  }

  public static convertTextFieldToData(translations: IL10n, field: HTMLDivElement): void {
    const $field = $(field);
    const $input = $field.find<HTMLInputElement>('input[type="text"]');
    const { name } = $input.get(0);
    if (!name) {
      return;
    }
    const $replacement = $('<input type="text" tabindex="-1"/>').insertBefore($input);
    $input
      .attr({
        type: 'date',
        pattern: '\\d{4,6}-\\d{2}-\\d{2}',
        placeholder: translations.getTranslation(TranslationKey.ModSqueezePageLPCCreatePageDatePlaceholder),
      })
      .css({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255,255,255,0)',
        border: 'none',
        outline: 0,
        borderRadius: $replacement.css('borderRadius'),
        fontSize: $replacement.css('fontSize'),
        color: $replacement.css('color'),
        fontWeight: $replacement.css('fontWeight'),
        fontStyle: $replacement.css('fontStyle'),
        fontFamily: $replacement.css('fontFamily'),
        lineHeight: $replacement.css('lineHeight'),
        padding: $replacement.css('padding'),
        margin: $replacement.css('margin'),
        boxSizing: $replacement.css('boxSizing'),
      })
      .on({
        invalid: (event) => {
          event.preventDefault();
          if (!event.target.validity.valid) {
            FormItem.showDateError(translations, $field);
          }
        },
        input: (event) => {
          const { target } = event;
          const { selectionStart, selectionEnd, value } = target;
          if (
            selectionStart !== null &&
            selectionEnd !== null &&
            selectionStart === selectionEnd &&
            !/^(\d{4}-\d{2}-\d{2}|\d{4}-\d{2}-\d{0,2}|\d{4}-\d{0,2}|\d{0,4})$/.test(value)
          ) {
            const toStartValue = FormItem.formatDate(value.slice(0, selectionStart));
            const newValue = FormItem.formatDate(value);

            if (value !== newValue) {
              target.value = newValue;
              target.selectionStart = toStartValue.length;
              target.selectionEnd = toStartValue.length;
            }
          }
        },
        focus: () => {
          FormItem.hideDateError($field);
        },
      });

    if ($field.parents('[data-label-type="placeholder"]').length > 0) {
      $input.css({ opacity: $input.val() ? 1 : 0 }).on({
        focus: () => {
          $input.css({ opacity: 1 });
        },
        blur: () => {
          if (!$input.val()) {
            $input.css({ opacity: 0 });
          }
        },
      });
    }
  }

  public static formatDate(value: string): string {
    const maxDateDigits = 8;
    return value
      .replace(/[^\d]/g, '')
      .slice(0, maxDateDigits)
      .replace(/^(\d{6})(\d)/, '$1-$2')
      .replace(/^(\d{4})(\d)/, '$1-$2');
  }

  public static showDateError(translations: IL10n, $field: JQuery<HTMLDivElement>): void {
    $field
      .addClass('error')
      .attr('data-error-msg', translations.getTranslation(TranslationKey.ModSqueezePageLPCCreatePageInvalidDateFormat));
  }

  public static hideDateError($field: JQuery<HTMLDivElement>): void {
    $field.removeClass('error').removeAttr('data-error-msg');
  }
}
