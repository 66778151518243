import { ZIndexHelper } from '../../../helpers/z_index';

export default {
  initialize: () => {
    /**
     * ===================
     * Handle WF Z-indexes (only new WF)
     * ===================
     */
    var webformFields = Array.prototype.slice.call(
      document.querySelectorAll(
        [
          '[data-editable="webformNew"] [data-editable="webformNewItem"]',
          '[data-editable="webformNew"] [data-editable="webformNewButton"]',
          '[data-editable="orderForm"] [data-editable="orderFormItem"]',
          '[data-editable="webinarNew"] [data-editable="webformNewItem"]',
          '[data-editable="webinarNew"] [data-editable="webformNewButton"]',
        ].join(', '),
      ),
    );

    var sorted = ZIndexHelper.getSortedComponents(
      webformFields,
      (el) => {
        return el.getBoundingClientRect();
      },
      (el) => {
        return el.getAttribute('data-editable');
      },
      (el) => {
        return parseInt(el.getAttribute('data-force-position'), 10) || 0;
      },
    );

    var tabIndex = 1;
    sorted.reverse().forEach(function (fieldElement, index) {
      var webformElements = Array.prototype.slice.call(
        fieldElement.querySelectorAll(['input', 'button', 'select', 'textarea'].join(', ')),
      );

      webformElements.forEach(function (webformElement) {
        webformElement.setAttribute('tabindex', tabIndex++);
      });
    });
  },
};
