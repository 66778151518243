export default {
  _doesWebformAppExist: function (webformId) {
    const webformApps = window.grLpsInitialData.webformApps;

    for (let i = 0; i < webformApps.length; i++) {
      if (webformApps[i].webformId === webformId) {
        return true;
      }
    }

    return false;
  },

  enableScripts: function () {
    //Hide custom codes elements on show
    $('[data-editable="webformApp"] [data-editable-item="caption-wrapper"]').remove();

    $('[data-editable="webformApp"]').each(
      function (index, webformAppEl) {
        const $el = $(webformAppEl);
        const webformId = $el.attr('data-webform-id');
        const landingTemplateId = $el.attr('data-landing-template-id');
        const landingTemplateColorId = $el.attr('data-landing-template-color-id');

        if (!this._doesWebformAppExist(webformId)) {
          $el.remove();
        } else {
          const $code = $el.find('[data-editable-item="code"]');
          const domain = $el.attr('data-publish-domain');
          const page = 'view_webform_v2.js';
          let url = `${domain}${page}?u=${grLpsInitialData.userId}&webforms_id=${webformId}&squeezeVariantId=${grLpsInitialData.variantId}`;

          if (landingTemplateId && landingTemplateColorId) {
            url += `&templateId=${landingTemplateId}&templateColorId=${landingTemplateColorId}`;
          }

          const script = `<script type="text/javascript" src="${url}"></script>`;

          //First parse string to HTML
          $code.html(script);

          //Then show HTML to don't show '<script>' tags etc..
          $code.css({
            display: 'block',
          });
        }
      }.bind(this),
    );
  },
};
