import { AutoFunnelStepType } from '../../../enums/autoFunnelStepType';
import { AutoFunnelType } from '../../../enums/autoFunnelType';
import { GrChatStatus } from '../../../enums/grChatStatus';
import { PaymentProcessor } from '../../../enums/paymentProcessors';

import { IProduct, IProductData } from '../../../enums/product';
import { ProductList } from '../../../enums/productList';
import { AutoFunnelHelper } from '../../../helpers/autofunnel';

import {
  GRChat,
  IAutoFunnel,
  IECommerceData,
  ILpsShowInitialData,
  IShop,
  ITrackingCode,
  TranslationKey,
} from '../../../interfaces/lpsShow';

import { AutoFunnelDefault, ECommerceDataDefault } from '../constants/defaultData';

import { defaultTranslations } from '../helpers/l10n';
import { CryptoName } from '../../../enums/cryptoName';

class LpsShowConfig {
  public getInitialData(): ILpsShowInitialData {
    return window.grLpsInitialData as ILpsShowInitialData;
  }

  public getL10n(): { [key in TranslationKey]: string } {
    return this.getInitialData().l10n || defaultTranslations;
  }

  public getCountryCode(): string {
    return this.getInitialData().country_code;
  }

  public getUserPanelLang(): string {
    return this.getInitialData().user_panel_lang;
  }

  public getUrlProductBoxDefaultImage(): string {
    return this.getInitialData().url_product_box_default_image;
  }

  public getTrackingCodes(): ITrackingCode {
    return this.getInitialData().tracking_codes;
  }

  public getAutoFunnelData(): IAutoFunnel {
    return this.getInitialData().autoFunnel || AutoFunnelDefault;
  }

  public getAutoFunnelId(): string {
    return this.getAutoFunnelData().id || '';
  }

  public getAutoFunnelCartId(): string {
    return this.getAutoFunnelData().cartId || '';
  }

  public getAutoFunnelStepId(): string {
    return this.getAutoFunnelData().stepId || '';
  }

  public getFormFieldsAutoFillValues(): any[] {
    return this.getAutoFunnelData().formFieldsValues || [];
  }

  public getPaymentProcessorToken(): string {
    return this.getAutoFunnelData().paymentProcessorToken || '';
  }

  public getPaymentSquareLocationId(): string {
    return this.getAutoFunnelData().autoFunnelPaymentSquareLocationId || '';
  }

  public getPaymentStripeAPIKey(): string {
    return this.getAutoFunnelData().paymentStripeAPIKey || '';
  }

  public getPaymentStripeCountryCode(): string {
    return this.getAutoFunnelData().paymentStripeCountryCode.toUpperCase();
  }

  public hasAutoFunnel(): boolean {
    return !!this.getAutoFunnelId() && !!this.getAutoFunnelStepId();
  }

  public isAutoFunnelConnected(): boolean {
    return !!(this.getAutoFunnelId() && this.getAutoFunnelStepId());
  }

  public getAutoFunnelCurrency(): string {
    return this.getECommerceShopCurrency(this.getFunnelShopId()) || '';
  }

  public getAutoFunnelStepType(): string {
    return this.getAutoFunnelData().stepType || '';
  }

  public getAutoFunnelBoughtProducts(): IProduct[] {
    return this.getAutoFunnelData().boughtProducts || [];
  }

  public hasAnyPaymentEnabled(): boolean {
    return (
      this.hasPayPalIntegration() ||
      this.hasStripeIntegration() ||
      this.hasBlueSnapIntegration() ||
      this.hasPayUIntegration() ||
      this.hasSquareIntegration() ||
      this.hasQiwiIntegration() ||
      this.hasYandexIntegration()
    );
  }

  public hasIntegrationWithoutCreditCardFields(): boolean {
    return (
      this.hasPayPalIntegration() ||
      this.hasPayUIntegration() ||
      this.hasQiwiIntegration() ||
      this.hasYandexIntegration()
    );
  }

  public hasPayPalIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return this.hasPayPalIntegration() && this.hasAutoFunnel() && this.hasProductsList(formElement);
  }

  public hasPayUIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return this.hasPayUIntegration() && this.hasAutoFunnel() && this.hasProductsList(formElement);
  }

  public hasQiwiIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return this.hasQiwiIntegration() && this.hasAutoFunnel() && this.hasProductsList(formElement);
  }

  public hasYandexIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return this.hasYandexIntegration() && this.hasAutoFunnel() && this.hasProductsList(formElement);
  }

  public hasStripeIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return (
      this.hasStripeIntegration() &&
      this.hasAutoFunnel() &&
      this.hasOrderForm(formElement) &&
      this.hasProductsList(formElement)
    );
  }

  public hasBlueSnapIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return (
      this.hasBlueSnapIntegration() &&
      this.hasAutoFunnel() &&
      this.hasOrderForm(formElement) &&
      this.hasProductsList(formElement)
    );
  }

  public hasSquareIntegrationAndCorrectElements(formElement: HTMLFormElement): boolean {
    return (
      this.hasSquareIntegration() &&
      this.hasAutoFunnel() &&
      this.hasOrderForm(formElement) &&
      this.hasProductsList(formElement)
    );
  }

  public hasOrderForm(formElement: HTMLFormElement): boolean {
    return formElement.querySelector('[data-editable="orderForm"]') !== null;
  }

  public hasProductsList(form: HTMLFormElement): boolean {
    const elements = Array.prototype.slice.call(form.elements);

    return elements.some((formElement) => formElement.name === ProductList.FIELD_NAME);
  }

  public getPaymentProcessor(): PaymentProcessor {
    return this.getAutoFunnelData().paymentProcessor;
  }

  public getPaymentStatusUrl(): string {
    return this.getAutoFunnelData().autoFunnelPaymentStatusUrl;
  }

  public getProcessPaymentUrl(): string {
    return this.getAutoFunnelData().processPaymentUrl;
  }

  public hasPaymentProcessor(type: PaymentProcessor): boolean {
    return this.getPaymentProcessor() === type;
  }

  public hasPayPalIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_PAYPAL);
  }

  public hasStripeIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_STRIPE);
  }

  public hasBlueSnapIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_BLUESNAP);
  }

  public hasPayUIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_PAYU);
  }

  public hasSquareIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_SQUARE);
  }

  public hasQiwiIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_QIWI);
  }

  public hasYandexIntegration(): boolean {
    return this.hasPaymentProcessor(PaymentProcessor.PAYMENT_PROCESSOR_YANDEX);
  }

  public isOfferPage(): boolean {
    return this.getAutoFunnelData().stepType === AutoFunnelStepType.OFFER_PAGES;
  }

  public isOTOPageStep(): boolean {
    return this.getAutoFunnelData().stepType === AutoFunnelStepType.OTO_PAGES;
  }

  public getECommerceData(): IECommerceData {
    return this.getInitialData().eCommerceData || ECommerceDataDefault;
  }

  public getECommerceShops(): IShop[] {
    return this.getECommerceData().shops || [];
  }

  public getFunnelShopId(): string {
    const shop = this.getECommerceShops()[0];
    return shop ? shop.shopId : '';
  }

  public getECommerceShopById(shopId: string): IShop {
    const shops = this.getECommerceShops();
    return (
      shops.find((shop) => shop.shopId === shopId) || {
        shopId: null,
        currency: 'USD',
        productsVariants: [],
      }
    );
  }

  public getECommerceShopCurrency(shopId: string): string {
    return this.getECommerceShopById(shopId).currency;
  }

  public getECommerceShopProducts(shopId: string): IProductData[] {
    const products = this.getECommerceShopById(shopId).productsVariants || [];
    const currency = this.getECommerceShopCurrency(shopId);

    return AutoFunnelHelper.getProductsData(products, currency, this.getUserPanelLang());
  }

  public getECommerceShopProduct(shopId: string, productId: string): IProductData {
    const products = this.getECommerceShopProducts(shopId);

    return products.find((product: IProductData) => product.id === productId);
  }

  public getOrderFormUrl(): string {
    return this.getAutoFunnelData().orderFormUrl;
  }

  public getOTOPageDismissUrl(): string {
    return this.getAutoFunnelData().otoPageDismissUrl;
  }

  public getOTOPageAcceptUrl(): string {
    return this.getAutoFunnelData().otoPageAcceptUrl;
  }

  public isOtoPageActionEnabled(): boolean {
    return this.getAutoFunnelData().enableOtoPageAction;
  }

  public getAutoFunnelType(): AutoFunnelType {
    return this.getAutoFunnelData().autoFunnelType;
  }

  public isLeadMagnet(): boolean {
    return this.getAutoFunnelType() === AutoFunnelType.LEAD_MAGNET;
  }

  public getGRChatData(): GRChat {
    return (
      this.getInitialData().grChat ?? {
        status: GrChatStatus.Disabled,
        urlParams: null,
      }
    );
  }

  public getGrChatStatus(): GrChatStatus {
    return this.getGRChatData().status;
  }

  public getGrChatUrlParams(): string {
    return this.getGRChatData().urlParams;
  }

  public isGrChatEnabled(): boolean {
    return this.getGrChatStatus() === GrChatStatus.Enabled;
  }

  public getUserId(): string {
    return this.getInitialData().userId;
  }

  public getCountryPhoneCodes(): unknown[] {
    return this.getInitialData().country_phone_codes;
  }

  public getCrypto(): string {
    return this.getInitialData().crypto;
  }

  public isSMB(): boolean {
    return this.getCrypto() === CryptoName.Getresponse;
  }

  public isMAX(): boolean {
    return !this.isSMB();
  }

  public getLandingPageEncodedId(): string {
    return this.getInitialData().landingPageEncodedId;
  }

  public getVisitToken(): string {
    return this.getInitialData().visitToken ?? '';
  }

  public isFreemium(): boolean {
    return this.getInitialData().isFreemium ?? false;
  }
}

export const LPSData = new LpsShowConfig();
