export default {
  initialize: () => {
    $(document)
      .on('mouseover', '[data-editable="button"]', function (e) {
        var $button = $(e.currentTarget);

        $button.addClass('hover');
      })
      .on('mouseout', '[data-editable="button"]', function (e) {
        var $button = $(e.currentTarget);

        $button.removeClass('hover');
      });
  },
};
