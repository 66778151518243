import { Logger } from '../helpers/logger';
import { ErrorMonitor } from '../monitors/errorMonitor/ErrorMonitor';
import { ErrorType } from '../monitors/errorMonitor/enums/ErrorType';

interface GrChatModuleData {
  grChatUrlParams: string;
  isMAX?: boolean;
  logger?: Logger;
  isFreemium: boolean;
}

export class ModuleLoader {
  public static loadGrChat(data: GrChatModuleData): void {
    import(/* webpackChunkName: "lps-gr-chat" */ './GrChat/GrChat')
      .then(({ GrChat }) => {
        const chat = new GrChat(data.isFreemium, data.isMAX ?? false, data.logger);
        chat.initialize(data.grChatUrlParams);
      })
      .catch((error) => {
        data.logger?.log('Failed to load GR Chat Module');
        ErrorMonitor.captureException(error, {
          tags: {
            script: 'GrChat',
            error_type: ErrorType.ScriptLoadError,
          },
        });
      });
  }
}
