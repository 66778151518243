export class UrlHelper {
  public static getGetParamsString(params: any): string {
    let strGet = '';

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        strGet += !strGet ? '?' : '&';

        strGet += key + '=' + params[key];
      }
    }

    return strGet;
  }

  public static findGetParameter(parameterName: string): string {
    let result = null;
    let tmp = [];
    location.search
      .substr(1)
      .split('&')
      .forEach((item) => {
        tmp = item.split('=');

        if (tmp[0] === parameterName) {
          result = decodeURIComponent(tmp[1]);
        }
      });
    return result;
  }
}
