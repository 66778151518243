import tplStyleContainer from '../components/order_form_item/templates/order_form_style_container_template.html';
import { fontsList } from '../constants/fonts';

import { BaseLogger } from '../../../helpers/logger';

var isGoogleFont = function (fontName) {
  for (var i = 0; i < fontsList.length; i++) {
    if (fontsList[i].GoogleWebFont && fontsList[i].value.indexOf(fontName) !== -1) {
      return true;
    }
  }

  return false;
};

export default {
  stripeObject: null,
  getInstance: function (apiKey, fontName) {
    if (!this.stripeObject) {
      this.stripeObject = Stripe(apiKey, {
        betas: ['payment_intent_beta_3'],
      });
    }

    var fonts = [];

    if (fontName) {
      var fontToLoad = fontName.match(/'?([^,']+)'?/)[1].replace(' ', '+');

      if (isGoogleFont(fontToLoad)) {
        fonts.push({
          cssSrc: `https://fonts.bunny.net/css?family=${fontToLoad}`,
        });
      }
    }

    return this.stripeObject.elements({
      fonts: fonts,
    });
  },

  getStripeIntegration: function (apiKey) {
    if (!this.stripeObject) {
      this.stripeObject = Stripe(apiKey, {
        betas: ['payment_intent_beta_3'],
      });
      return this.stripeObject;
    } else {
      return this.stripeObject;
    }
  },

  initializeOrderFormItem: function (stripeElements, orderFormItemElement, styles) {
    const type = orderFormItemElement.getAttribute('data-item-type');

    if (!type) {
      BaseLogger.log(
        "Order form item needs 'data-item-type' to be specified. See https://stripe.com/docs/stripe-js/reference#element-types",
      );
    }

    let styleContainer = orderFormItemElement.querySelector('[data-editable-item="container"]');

    if (!styleContainer) {
      //Need it to simulate styles for order form item
      const editableElement = orderFormItemElement.querySelector('.editable');

      if (editableElement) {
        //In editor
        editableElement.innerHTML = tplStyleContainer;
      } else {
        //On show
        orderFormItemElement.innerHTML = tplStyleContainer;
      }
      //$(orderFormItemElement).find('.editable').append(tplStyleContainer);
      styleContainer = orderFormItemElement.querySelector('[data-editable-item="container"]');
    }

    BaseLogger.log('Initializing stripe element', type);

    const stripeElement = stripeElements.create(type, {
      style: styles,
    });

    stripeElement.mount(styleContainer);

    return stripeElement;
  },

  getStyles: function (orderFormItemElement) {
    const inlineStyles = orderFormItemElement.getAttribute('data-styles') || '';
    const styles = {};

    inlineStyles.split(';').forEach((item) => {
      if (item) {
        const itemStyle = item.split(':');
        styles[itemStyle[0]] = itemStyle[1];
      }
    });

    return styles;
  },

  getStripeStyles: function (orderFormItemElement) {
    const styles = this.getStyles(orderFormItemElement);

    styles['::placeholder'] = Object.assign({}, styles); //Copy all styles for placeholder

    return {
      base: styles,
    };
  },

  submitPayment: function (cardNumberField, clientSecret, callback) {
    this.stripeObject.handleCardPayment(clientSecret, cardNumberField, {}).then(callback);
  },

  submitPaymentWithConfirmation: function (clientSecret, data = {}, callback) {
    this.stripeObject.handleCardPayment(clientSecret, data).then(callback);
  },

  createPaymentMethod: function (paymentMethodType, element, data, callback) {
    this.stripeObject.createPaymentMethod(paymentMethodType, element, data).then(callback);
  },
};
