/* tslint:disable:no-console */
import { Env } from './env';

import { LoggerStorageKey } from '../enums/loggerStorageKey';

export class Logger {
  private readonly storageKey: string;
  private readonly isDevelop: boolean;

  constructor(storageKey: LoggerStorageKey, isDevelop: boolean = false) {
    this.storageKey = storageKey;
    this.isDevelop = isDevelop;
  }

  public log(...args: any[]): void {
    if (this.showLoggerMessage()) {
      console.log.apply(console, args);
    }
  }

  public warn(...args: any[]): void {
    if (this.showLoggerMessage()) {
      (console.warn || console.log).apply(console, args);
    }
  }

  public info(...args: any[]): void {
    if (this.showLoggerMessage()) {
      (console.info || console.log).apply(console, args);
    }
  }

  public error(...args: any[]): void {
    if (this.showLoggerMessage()) {
      (console.error || console.log).apply(console, args);
    }
  }

  public silentLog(identifier: string): any {
    return function decorator(): void {
      if (window[identifier]) {
        this.log.apply(this, arguments);
      }
    }.bind(this);
  }

  private showLoggerMessage(): boolean {
    return this.isDevelop || this.hasStorageKey();
  }

  private hasStorageKey(): boolean {
    return !!sessionStorage.getItem(this.storageKey);
  }
}

/**
 * Use BaseLogger in files shared between views
 */
export const BaseLogger = new Logger(LoggerStorageKey.BASE_LOGGER, Env.isDevelop());
