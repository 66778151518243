import { WebformFieldType } from '../../../enums/webformFieldType';

export default {
  getErrors: function (formElement) {
    var requiredFieldsElements = Array.prototype.slice.call(
      formElement.querySelectorAll('[data-item-required="true"], [data-item-name="email"]'),
    );
    var errors = {};

    for (var i = 0; i < requiredFieldsElements.length; i++) {
      var requiredFieldElement = requiredFieldsElements[i];
      var requiredFieldInputs = Array.prototype.slice.call(
        requiredFieldElement.querySelectorAll('input, select, textarea'),
      );
      var hasValue = false;
      const itemType = requiredFieldElement.getAttribute('data-item-type');
      var isCheckbox = itemType === WebformFieldType.CHECKBOX || itemType === WebformFieldType.GDPR_FIELD;
      var isRadio = itemType === WebformFieldType.RADIO;
      var valueProp = isCheckbox || isRadio ? 'checked' : 'value';
      var fieldName =
        requiredFieldElement.getAttribute('data-item-name') === 'consent'
          ? requiredFieldElement.getAttribute('data-item-name') +
            '_' +
            requiredFieldElement.getAttribute('data-consent-id')
          : requiredFieldElement.getAttribute('data-item-name');

      for (var j = 0; j < requiredFieldInputs.length; j++) {
        if (requiredFieldInputs[j][valueProp]) {
          hasValue = true;
        }
      }

      if (!hasValue) {
        errors[fieldName] = window.grLpsInitialData.l10n.ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField;
      }
    }

    return errors;
  },
};
