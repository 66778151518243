import $ from 'jquery';

import { StripeOrderFormElementStrategy } from '../strategies/order_form/stripe';
import { BlueSnapOrderFormElementStrategy } from '../strategies/order_form/blueSnap';
import { SquareOrderFormElementStrategy } from '../strategies/order_form/square';

import { LPSData } from '../models/config';

function OrderFormElement(orderFormElement, formObj) {
  this.orderFormElement = orderFormElement;
  this.formObj = formObj;
  this.formElement = formObj.form;
  this.strategy = null;

  if (LPSData.hasStripeIntegration()) {
    this.strategy = new StripeOrderFormElementStrategy(this);
  } else if (LPSData.hasBlueSnapIntegration()) {
    this.strategy = new BlueSnapOrderFormElementStrategy(this);
  } else if (LPSData.hasSquareIntegration()) {
    this.strategy = new SquareOrderFormElementStrategy(this);
  }

  if (this.strategy) {
    this.strategy.initializeItems();
    this.strategy.initializeOrderFormItemPaymentRequestButton();
  }
}

OrderFormElement.prototype.getItems = function () {
  return Array.prototype.slice.call(this.orderFormElement.querySelectorAll('[data-editable="orderFormItem"]'));
};

OrderFormElement.prototype.updateFields = function (data) {
  this.strategy.updateFields(data);
};

OrderFormElement.prototype.getPostalCode = function () {
  return this.strategy.getPostalCode();
};

OrderFormElement.prototype.getCardNonce = function () {
  const strategy = this.strategy;
  let interval;

  return new Promise((resolve, reject) => {
    if (!strategy.requestCardNonce()) {
      reject();
      return;
    }

    interval = setInterval(function () {
      let nonce = strategy.getNonce();
      let requestCardNonceErrors = strategy.getRequestCardNonceErrors();
      if (!!nonce) {
        clearInterval(interval);
        resolve(nonce);
      }
      if (requestCardNonceErrors) {
        clearInterval(interval);
        reject(requestCardNonceErrors);
      }
    }, 1000);
  });
};

OrderFormElement.prototype.hasErrors = function () {
  const orderFormItemsElements = this.getItems();

  return $(orderFormItemsElements).hasClass('error');
};

OrderFormElement.prototype.submitPayment = function (data, callback) {
  this.strategy.submitPayment(data, callback);
};

OrderFormElement.prototype.submitPaymentWithConfirmation = function (data, callback) {
  this.strategy.submitPaymentWithConfirmation(data, callback);
};

OrderFormElement.prototype.createPaymentMethod = function (data, callback) {
  this.strategy.createPaymentMethod(data, callback);
};

export default OrderFormElement;
