import { Component } from '../../../../enums/components';
import stripeIntegrationHelper from '../../../editor/helpers/stripeIntegration';
import { AutoFunnelModifier } from '../../modifiers/autofunnel';

import { LPSData } from '../../models/config';

import { StripePaymentRequestHelper } from '../../helpers/stripePaymentRequest';

import { StripeBillingDetails } from '../../../../enums/stripeBillingDetails';

import { OrderForm, IOrderFormObject } from './orderForm';

export class StripeOrderFormElementStrategy extends OrderForm {
  private readonly orderFormObject: IOrderFormObject;
  private readonly stripeElements: any;
  private readonly items: any;

  constructor(orderFormObject: IOrderFormObject) {
    super();
    this.items = {};
    this.orderFormObject = orderFormObject;
    this.stripeElements = stripeIntegrationHelper.getInstance(
      (window as any).grLpsInitialData.autoFunnel.paymentStripeAPIKey,
      orderFormObject.orderFormElement.getAttribute('data-font-name'),
    );
  }

  public initializeOrderFormItemPaymentRequestButton(): void {
    const orderFormObject = this.orderFormObject;
    const formObj = orderFormObject.formObj;
    const paymentRequest = stripeIntegrationHelper.stripeObject.paymentRequest({
      country: StripePaymentRequestHelper().country.toUpperCase(),
      currency: StripePaymentRequestHelper().currency,
      total: {
        label: StripePaymentRequestHelper().label,
        amount: StripePaymentRequestHelper().amount,
      },
      requestPayerName: StripePaymentRequestHelper().requestPayerName,
      requestPayerEmail: StripePaymentRequestHelper().requestPayerEmail,
    });
    const stripePaymentButtonElement = document.querySelector<HTMLButtonElement>(
      `[data-editable="${Component.STRIPE_PAYMENT_BUTTON}"]`,
    );

    if (!stripePaymentButtonElement) {
      return;
    }
    const oThis = this;
    stripePaymentButtonElement.addEventListener('click', (e) => {
      e.preventDefault();

      paymentRequest.update({
        total: {
          label: oThis.getLabel(formObj),
          amount: oThis.getTotalPrice(formObj),
        },
      });
      formObj
        .validate()
        .then(() => {
          paymentRequest.show();
        })
        .catch(() => '');
    });

    // Check the availability of the Payment Request API first.
    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        stripePaymentButtonElement.dataset.paymentMethod = result.applePay ? 'applePay' : 'other';
        AutoFunnelModifier.enableStripePaymentButton(stripePaymentButtonElement);
        paymentRequest.on('token', (ev) => {
          const serializedData = $(formObj.form).serializeArray();

          formObj.proceedStripePaymentRequest(serializedData, ev);
        });
        paymentRequest.on('cancel', () => {
          formObj._enableForm();
        });
      } else {
        AutoFunnelModifier.disableStripePaymentButton(stripePaymentButtonElement);
      }
    });
  }

  public initializeItems(): void {
    const orderFormItemsElements = this.orderFormObject.getItems();

    orderFormItemsElements.forEach((orderFormItemElement) => {
      const stripeElement = stripeIntegrationHelper.initializeOrderFormItem(
        this.stripeElements,
        orderFormItemElement,
        stripeIntegrationHelper.getStripeStyles(orderFormItemElement),
      );

      stripeElement.addEventListener('change', this.onStripeInputChange.bind(this, orderFormItemElement));

      this.items[orderFormItemElement.getAttribute('data-item-type')] = stripeElement;
    });
  }

  public updateFields(data: any): void {
    for (const key in this.items) {
      if (this.items.hasOwnProperty(key)) {
        this.items[key].update(data);
      }
    }
  }

  public submitPayment(data: any, callback: any): void {
    const cardNumberField = this.items.cardNumber; // Any field can be used to retrieve token

    stripeIntegrationHelper.submitPayment(cardNumberField, data.client_secret, callback);
  }

  public submitPaymentWithConfirmation(data: { clientSecret: string }, callback: any): void {
    const { clientSecret } = data;

    stripeIntegrationHelper.submitPaymentWithConfirmation(clientSecret, {}, callback);
  }

  public createPaymentMethod(billingDetails: StripeBillingDetails = {}, callback: any): void {
    const cardNumberField = this.items.cardNumber; // Any field can be used to retrieve token

    stripeIntegrationHelper.createPaymentMethod('card', cardNumberField, billingDetails, callback);
  }

  // noinspection JSMethodCanBeStatic
  private onStripeInputChange(orderFormItemElement: any, event: any): void {
    const $orderFormItemElement = $(orderFormItemElement);
    const hasError = !!event.error;

    $orderFormItemElement.toggleClass('error', hasError);

    if (hasError) {
      $orderFormItemElement.addClass('error').attr('data-error-msg', event.error.message);
    } else {
      $orderFormItemElement.removeClass('error').removeAttr('data-error-msg');
    }
  }

  // noinspection JSMethodCanBeStatic
  private getTotalPrice(formObj: any): number {
    if (!formObj || !formObj.productList) {
      return StripePaymentRequestHelper().amount;
    }
    // noinspection TypeScriptValidateJSTypes
    return formObj.productList.getTotalPrice();
  }

  private getLabel(formObj: any): string {
    if (!formObj || !formObj.productList) {
      return StripePaymentRequestHelper().label;
    }
    const { productList } = formObj;
    const shopId = productList.getShopId();
    const products = LPSData.getECommerceShopProducts(shopId);

    const selectedProducts = productList.getSelectedProducts();
    const selectedProductsIds = Object.keys(selectedProducts);

    const productsNames = products
      .filter((product) => selectedProductsIds.includes(product.id))
      .map((product) => {
        const { name } = product;
        return name ? name : '';
      });

    return productsNames.length > 0 ? productsNames.join(', ') : StripePaymentRequestHelper().label;
  }
}
