import $ from 'jquery';
import ViewportSizes from '../../editor/helpers/viewport_sizes';
import { ViewingMode } from '../../../enums/viewingMode';

export default {
  setBodyMinWidth() {
    const $body = $('body');
    const mobileWidth =
      parseInt($body.attr('data-workspace-phone-width'), 10) || ViewportSizes.getWorkspaceWidth(ViewingMode.PHONE);
    const desktopWidth =
      parseInt($body.attr('data-workspace-desktop-width'), 10) || ViewportSizes.getWorkspaceWidth(ViewingMode.DESKTOP);
    $body.css({
      minWidth: `${Math.min(mobileWidth, desktopWidth)}px`,
    });
  },
  modify() {
    $('body').addClass('is-published');
  },
};
