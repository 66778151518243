import * as $ from 'jquery';
import * as _ from 'underscore';

import { AutoFunnelHelper } from '../../../helpers/autofunnel';
import { ZIndexHelper } from '../../../helpers/z_index';

import { Component } from '../../../enums/components';
import { AutoFunnelStepType } from '../../../enums/autoFunnelStepType';
import * as templateContentConfirmPage from '../../editor/components/products_box/templates/content_confirm_page.html';

import { LPSData } from '../models/config';

export class AutoFunnelModifier {
  public static modify(): void {
    AutoFunnelModifier.updateProductsData();
    AutoFunnelModifier.manageOrderForm();
  }

  public static enableStripePaymentButton(button: HTMLButtonElement): void {
    if (!button) {
      return;
    }
    const isButtonHidden = window.getComputedStyle(button).display === 'none';
    const isCorrectButtonType = button.dataset.editable === Component.STRIPE_PAYMENT_BUTTON;
    if (isCorrectButtonType && isButtonHidden) {
      button.style.display = 'block';
    }
  }

  public static disableStripePaymentButton(button: HTMLButtonElement): void {
    if (!button) {
      return;
    }
    const isButtonHidden = window.getComputedStyle(button).display !== 'none';
    const isCorrectButtonType = button.dataset.editable === Component.STRIPE_PAYMENT_BUTTON;
    if (isCorrectButtonType && isButtonHidden) {
      button.style.display = 'none';
    }
  }

  private static shouldRenderProductPrice(price: number): boolean {
    return !(LPSData.isLeadMagnet() && price === 0);
  }

  private static shouldRenderQuantity(quantity?: number): boolean {
    return quantity > 1;
  }

  private static updateProductsData(): void {
    const $body = $('body');
    const isAutoFunnelConnected = LPSData.isAutoFunnelConnected();

    if (LPSData.getAutoFunnelStepType() === AutoFunnelStepType.CONFIRM) {
      const productBoxesContent = Array.from(
        $body.find('[data-editable="productsBox"] [data-component-part="products-box-content"]'),
      );
      const boughtProducts = LPSData.getAutoFunnelBoughtProducts();
      const compiledTemplate = _.template(templateContentConfirmPage as any);
      const l10n = LPSData.getL10n();

      productBoxesContent.forEach((elProductBoxContent) => {
        const $productBoxContent = $(elProductBoxContent);

        $productBoxContent.html('');

        //Load template
        boughtProducts.forEach((boughtProduct) => {
          $productBoxContent.append(
            compiledTemplate({
              product: boughtProduct,
              l10n,
              url: {
                defaultImage: LPSData.getUrlProductBoxDefaultImage(),
              },
              shouldRenderPrice: AutoFunnelModifier.shouldRenderProductPrice(boughtProduct.price),
              shouldRenderQuantity: AutoFunnelModifier.shouldRenderQuantity(boughtProduct.quantity),
            }),
          );
        });
      });
    } else {
      const productBoxes = Array.from($body.find('[data-editable="productsBox"]'));

      productBoxes.forEach((productBoxEl) => {
        const $productsBox = $(productBoxEl);
        let shopId = $productsBox.attr('data-shop-id');

        if (!shopId && isAutoFunnelConnected) {
          shopId = LPSData.getFunnelShopId();
        }

        const boxProducts = LPSData.getECommerceShopProducts(shopId);

        AutoFunnelHelper.updateProductsBoxData($productsBox, boxProducts, LPSData.getUserPanelLang());

        $productsBox.find('[data-component-part="product-box"]').each((index, productBox) => {
          const $product = $(productBox);
          const productId = $product.attr('data-product-id');
          const productExist = boxProducts.find((product) => product.id === productId);

          if (!productExist) {
            $product.remove();
          }
        });
      });
    }
    const productLists = Array.from($body.find('[data-editable="productList"]'));

    productLists.forEach((list) => {
      const $list = $(list);
      let shopId = $list.attr('data-shop-id');

      if (!shopId && isAutoFunnelConnected) {
        shopId = LPSData.getFunnelShopId();
      }

      const listProducts = LPSData.getECommerceShopProducts(shopId);
      AutoFunnelHelper.updateProductListData($list, listProducts, LPSData.getUserPanelLang());
    });
  }

  private static manageOrderForm(): void {
    const orderFormElement = document.body.querySelector<HTMLDivElement>(`[data-editable="${Component.ORDER_FORM}"]`);
    const oThis = this;

    if (!orderFormElement) {
      return;
    }

    orderFormElement.setAttribute('data-payment-processor', LPSData.getPaymentProcessor());

    const isOrderFormVisible = $(orderFormElement).is(':visible');

    if (LPSData.hasIntegrationWithoutCreditCardFields() && isOrderFormVisible) {
      const webformElements = Array.from(document.body.querySelectorAll(`[data-editable="${Component.WEBFORM_NEW}"]`));

      webformElements.forEach((webformElement: HTMLElement) => {
        let childComponents = Array.from(webformElement.querySelector('form').childNodes).filter(
          (node) => node.nodeType === 1,
        );
        let offsetY = 0;

        childComponents = ZIndexHelper.getAscendingSortedComponents(
          childComponents,
          (el) => el.getBoundingClientRect(),
          (el) => el.getAttribute('data-editable'),
        );

        childComponents.forEach((childElement: HTMLDivElement, index) => {
          const { offsetTop } = childElement;

          if (childElement.getAttribute('data-editable') === Component.ORDER_FORM) {
            const nextComponent = childComponents[index + 1] as HTMLDivElement;

            if (nextComponent) {
              offsetY = offsetTop - nextComponent.offsetTop;
            }

            childElement.style.display = 'none';
          } else if (oThis.isValidOrderFormWithoutCreditCardElement(childElement)) {
            childElement.style.display = 'block';
            childElement.style.top = `${offsetTop + offsetY}px`;
          }
        });

        webformElement.style.height = `${webformElement.offsetHeight + offsetY}px`;
      });
    } else if (!LPSData.hasIntegrationWithoutCreditCardFields() && !isOrderFormVisible) {
      const webformElements = Array.from(document.body.querySelectorAll(`[data-editable="${Component.WEBFORM_NEW}"]`));

      webformElements.forEach((webformElement: HTMLElement) => {
        let childComponents = Array.from(webformElement.querySelector('form').childNodes).filter(
          (node) => node.nodeType === 1,
        );
        let offsetY = 0;

        childComponents = ZIndexHelper.getDescendingSortedComponents(
          childComponents,
          (el) => el.getBoundingClientRect(),
          (el) => el.getAttribute('data-editable'),
        );

        childComponents.forEach((childElement: HTMLElement) => {
          const { offsetTop } = childElement;
          const componentType = childElement.getAttribute('data-editable');

          switch (componentType) {
            case Component.WEBFORM_NEW_BUTTON:
              orderFormElement.style.display = 'block';
              orderFormElement.style.top = `${offsetTop}px`;

              offsetY = orderFormElement.offsetHeight + 5; //5 to make some additional space

              childElement.style.top = `${offsetTop + offsetY}px`;
              break;
            case Component.ORDER_FORM:
              break;
            default:
              //childElement.style.top = offsetTop + offsetY + 'px';
              break;
          }
        });

        webformElement.style.height = `${webformElement.offsetHeight + offsetY}px`;
      });
    }
  }

  private static isValidOrderFormWithoutCreditCardElement(component: HTMLDivElement): boolean {
    return component.getAttribute('data-editable') !== Component.STRIPE_PAYMENT_BUTTON;
  }
}
