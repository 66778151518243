import { ViewName } from '../../enums/viewName';
import { BaseErrorMonitor } from './BaseErrorMonitor';

export interface ErrorMonitorInitialData {
  view: ViewName;
  landingId?: string;
  crypto?: string;
}

export interface IErrorMonitor {
  init(data: ErrorMonitorInitialData): void;

  addBreadcrumb<IBreadcrumb>(breadcrumb?: IBreadcrumb): void;

  setTag(tagName: string, tagValue: string): void;

  setUser(id: string, crypto?: string): void;

  captureException(exception: any, captureContext?: any): void;

  captureMessage(message: string, level?: unknown): void;
}

function createErrorMonitor(): IErrorMonitor {
  return new BaseErrorMonitor();
}

export const ErrorMonitor = createErrorMonitor();
