import * as $ from 'jquery';
import { LPSData } from '../../models/config';
import { BaseFormElement } from './baseFormElement';
import helperForm from '../../helpers/form';

export class OfferFormElement extends BaseFormElement {
  protected getFormRequestData(): any {
    const dataToSend = helperForm.getDataToSend(this.form);
    dataToSend.offerPage = 1;
    return dataToSend;
  }

  public validate(): Promise<void> {
    return super.validate()
      .then(() =>
        new Promise((resolve, reject) => {
          $.ajax({
            url: location.href,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ ...this.getFormRequestData(), ___validate___: true }),
          }).done((requestData) => {
            if (requestData.error) {
              this.errors = requestData.error;
              this.reportValidity();
              if (LPSData.hasAnyPaymentEnabled()) {
                this.enableForm();
              }
              reject();
            } else {
              resolve();
            }
          });
        }),
      );
  }
}
