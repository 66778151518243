import * as $ from 'jquery';
import { OfferFormElement } from './offerFormElement';

import { Navigate } from '../../../../actions/navigate';

import AutoFunnelHelper from '../../helpers/autofunnel';
import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';

import { LPSData } from '../../models/config';

export class PayUFormElement extends OfferFormElement {
  public proceed(): void {
    logger.log('Starting PayU process');
    const serializedData = $(this.form).serializeArray();
    const formData = this.getFormRequestData();
    const payUPaymentData = JSON.stringify({
      ...formData,
      products: this.productList.getSelectedProducts(),
      cartId: LPSData.getAutoFunnelCartId(),
    });
    AutoFunnelHelper.startProcessPayment(payUPaymentData, LPSData.getProcessPaymentUrl(), (paymentResponse) => {
      this.proceedWithSubscription(
        () => {
          if (paymentResponse.error) {
            notify.alert(paymentResponse.error);
            this.enableForm();
          } else if (paymentResponse.uuid) {
            AutoFunnelHelper.checkPaymentStatus(paymentResponse.uuid, this.checkPaymentStatusCallback.bind(this));
          } else {
            Navigate.toExternal(paymentResponse.url);
          }
        },
        null,
        serializedData,
      );
    });
  }
}
