export class StringHelper {
  public static ucFirst(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * Returns attribute name converted to camel case
   * (data-selected-page => selectedPage)
   */
  public static dashCaseToCamelCase(name: string, separator: string = '_'): string {
    const parts = name.split(separator);

    return parts.reduce((mem, item, index) => {
      return mem + (index > 0 ? StringHelper.ucFirst(item) : item);
    }, '');
  }

  public static camelCaseToDashCase(value: string): string {
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  public static trimToSpace(value: string, glue: string = ' '): string {
    return value.trim().replace(/(\s+)/gm, glue);
  }

  public static toDash(value: string): string {
    return value.replace(/[A-Z]/g, (match) => {
      return '-' + match.toLowerCase();
    });
  }

  public static toCamelCase(value: string): string {
    return String(value).replace(/-\D/g, (match) => {
      return match.charAt(1).toUpperCase();
    });
  }

  public static ucfirst(value: string): string {
    const f = value.charAt(0).toUpperCase();
    return f + value.substr(1);
  }

  public static endsWith(stringValue: string, searchString: string, pos?: number): boolean {
    let position = pos || stringValue.length;
    position = position - searchString.length;
    const lastIndex = stringValue.lastIndexOf(searchString);
    return lastIndex !== -1 && lastIndex === position;
  }

  public static startsWith(stringValue: string, searchString: string, pos?: number): boolean {
    const position = pos || 0;
    return stringValue.indexOf(searchString, position) === position;
  }

  public static repeat(stringValue: string, count: number | string): string {
    return new Array(0 <= count ? parseInt(count as string, 10) + 1 : -1).join(stringValue);
  }

  public static toInt(stringValue: string, base: number): number {
    return parseInt(stringValue, base || 10);
  }

  public static toFloat(stringValue: string): number {
    return parseFloat(stringValue);
  }

  public static stripScripts(stringValue: string, text: string): string {
    return stringValue.replace(
      /(<\s*script[^>]*>[\s\S]*?<\s*\/\s*script[^>]*>|<\s*(\/\s*)?script[^>]*>)/gim,
      text || '',
    );
  }

  public static htmlEncode(stringValue: string): string {
    const chars = {
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
    };
    return stringValue.replace(new RegExp(Object.keys(chars).join('|'), 'mg'), (char) => {
      return chars[char];
    });
  }
}
