var noCustoms = ['name', 'email', 'first_name', 'last_name', 'gr_conference_id'];

export default {
  getDataToSend: function (formElement) {
    var form = formElement,
      elements = Array.prototype.slice.call(form.elements),
      q = {
        gdpr_consents: [], //always send this array
      };

    elements.forEach(function (element) {
      var elementName = element.name;

      if (!elementName) {
        return;
      }

      const isCustom = element.getAttribute('data-is-custom') === 'true' || noCustoms.indexOf(elementName) === -1;

      if (isCustom) {
        if (!!elementName.indexOf('custom_')) {
          elementName = 'custom_' + elementName; //BE wymaga takiej nazwy
        }
      }

      if (/^(email|text|date|textarea|hidden|password|button|reset|submit)$/i.test(element.type)) {
        //For phone customs
        if (element.getAttribute('data-country-code') !== null) {
          var directional = $(element)
            .parents('[data-editable="webformNewItem"]')
            .find('[data-feature-part="directional"] [data-feature-part="directional-container"]')
            .html();
          var phoneNumber = element.value ? directional + (element.value || '').replace(/\(|\)|-|\s/g, '') : '';
          q[elementName] = phoneNumber;
          return;
        } else {
          q[elementName] = element.value && element.value.trim ? element.value.trim() : element.value;
          return;
        }
      }

      if (/^(checkbox|radio)$/i.test(element.type)) {
        if (elementName === 'custom_consent') {
          const consentId = element.value;

          q.gdpr_consents.push({
            consentId: consentId,
            checked: element.checked,
            versionId: (function () {
              const gdprFields = window.grLpsInitialData.GDPRFields;

              for (let i = 0; i < gdprFields.length; i++) {
                const gdprField = gdprFields[i];

                if (gdprField.consentId === consentId) {
                  return gdprField.versionId;
                }
              }

              return null; //should not happen
            })(),
          });
        } else {
          if (element.checked) {
            if (!q[elementName]) {
              q[elementName] = [];
            }

            q[elementName].push(element.value);
          }
        }

        return;
      }

      if (/^select-(one|multiple)$/i.test(element.type)) {
        (Array.prototype.slice.call(element.options) || []).forEach(function (opt) {
          if (opt.selected) {
            if (!q[elementName]) {
              q[elementName] = [];
            }

            q[elementName].push(opt.value);
          }
        });
      }
    });

    return q;
  },
  serialize: function (formElement) {
    var form = formElement,
      elements = Array.prototype.slice.call(form.elements),
      q = [];

    elements.forEach(function (element) {
      var elementName = element.name;

      if (!elementName) {
        return;
      }

      if (element.getAttribute('data-is-custom') === 'true' || noCustoms.indexOf(elementName) === -1) {
        if (!!elementName.indexOf('custom_')) {
          elementName = 'custom_' + elementName; //BE wymaga takiej nazwy
        }
      }

      if (/^(email|text|textarea|hidden|password|button|reset|submit)$/i.test(element.type)) {
        //For phone customs
        if (element.getAttribute('data-country-code') !== null) {
          var directional = $(element)
            .parents('[data-editable="webformNewItem"]')
            .find('[data-feature-part="directional"] [data-feature-part="directional-container"]')
            .html();
          var phoneNumber = element.value ? directional + (element.value || '').replace(/\(|\)|-|\s/g, '') : '';
          q.push(elementName + '=' + encodeURIComponent(phoneNumber));
          return;
        } else {
          q.push(elementName + '=' + encodeURIComponent(element.value));
          return;
        }
      }

      if (/^(checkbox|radio)$/i.test(element.type)) {
        if (element.checked) {
          q.push(elementName + '[]' + '=' + encodeURIComponent(element.value));
        }
        return;
      }

      if (/^select-(one|multiple)$/i.test(element.type)) {
        (Array.prototype.slice.call(element.options) || []).forEach(function (opt) {
          if (opt.selected) {
            q.push(elementName + '[]' + '=' + encodeURIComponent(opt.value));
          }
        });
      }
    });

    return q.join('&');
  },

  //Wersja z Preview
  /*serialize: function (formElement) {
        var form = formElement,
            elements = Array.prototype.slice.call(form.elements),
            q = [];

        elements.forEach(function (element) {
            if (!element.name) {
                return;
            }

            if (/^(text|textarea|hidden|password|button|reset|submit)$/i.test(element.type)) {
                q.push(element.name + "=" + encodeURIComponent(element.value));
                return;
            }

            if (/^(checkbox|radio)$/i.test(element.type)) {
                if (element.checked) {
                    q.push(element.name + "[]" + "=" + encodeURIComponent(element.value));
                }
                return;
            }

            if (/^select-(one|multiple)$/i.test(element.type)) {
                (Array.prototype.slice.call(element.options) || []).forEach(function (opt) {
                    if (opt.selected) {
                        q.push(element.name + "[]" + "=" + encodeURIComponent(element.value));
                    }
                });
            }
        });

        return q.join("&");
    }*/
};
