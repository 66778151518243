import helpersYoutube from './youtube';
import helpersSection from './section';

export default {
  initialize: () => {
    var publish = false;

    try {
      publish = parent && parent.APP && parent.APP.publish;
    } catch (error) {}

    var previewElement = document.getElementById('Preview');

    if (window.IFRUID || top !== self) {
      helpersYoutube.stopAutoPlay();
      helpersSection.setMinHeightWhenOneSection();
    }

    if (publish) {
      publish('outsideFn', {
        type: 'setPreviewOutside',
        width: previewElement.outerWidth,
        height: previewElement.outerHeight,
        sid: previewElement.getAttribute('data-id'),
        location: window.location.search.split('?')[1] || false,
        zoom: window.grLpsInitialData.zoom,
      });
    }

    var html, wrapper;

    if (!window.parent || !Function.prototype.delay || !window.parent.postMessage.delay || !previewElement) {
      return;
    }

    html = document.documentElement;
    wrapper = document.querySelector('[data-section="wrap"]');

    parent.postMessage.delay(100, parent, [
      {
        call: 'init',
        brand: 'LPS',
        IFRUID: window.IFRUID,
        display: {
          width: wrapper.offsetWidth, //Math.max(html.scrollWidth, html.offsetWidth, html.clientWidth),
          height:
            (previewElement ? previewElement.offsetHeight : 0) ||
            (html ? Math.max(html.scrollHeight, html.offsetHeight, html.clientHeight) : 0),
        },
      },
      '*',
    ]);
  },
};
