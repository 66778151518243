import blueSnapIntegrationHelper from '../../../editor/helpers/blueSnapIntegration';

import { logger } from '../../helpers/logger';
import { noop } from '../../utils/noop';

import { OrderForm, IOrderFormObject } from './orderForm';

export class BlueSnapOrderFormElementStrategy extends OrderForm {
  private readonly orderFormObject: IOrderFormObject;

  constructor(orderFormObject: IOrderFormObject) {
    super();
    this.orderFormObject = orderFormObject;
  }

  public initializeItems(): void {
    const orderFormItemsElements = this.orderFormObject.getItems();
    const token = (window as any).grLpsInitialData.autoFunnel.paymentProcessorToken;

    orderFormItemsElements.forEach((orderFormItemElement) => {
      blueSnapIntegrationHelper.initializeOrderFormItem(orderFormItemElement);
    });

    blueSnapIntegrationHelper.initializeFormOnLPCShow(this.orderFormObject.orderFormElement, token, {
      onFocus: noop,
      onBlur: noop,
      onError: (tagId, errorCode, errorDescription) => {
        // "001" --> "Please enter a valid credit card number";
        // "002" --> "Please enter the CVV/CVC of your card";
        // "003" --> "Please enter your credit card's expiration date";
        // "22013" --> "CC type is not supported by the merchant";
        // "14040" --> " Token is expired";
        // "14041" --> " Could not find token";
        /* "14042" --> " Token is not associated with a payment method,
                    please verify your client integration or contact BlueSnap support";
                */
        // "400" --> "Session expired please refresh page to continue";
        // "403", "404", "500" --> "Internal server error please try again later";

        logger.log(tagId, errorCode, errorDescription);

        const $orderFormItemElement = $('[data-bluesnap="' + tagId + '"]');
        $orderFormItemElement.addClass('error').attr('data-error-msg', errorDescription);
      },
      onType: noop,
      onValid: (tagId) => {
        const $orderFormItemElement = $('[data-bluesnap="' + tagId + '"]');
        $orderFormItemElement.removeClass('error').removeAttr('data-error-msg');
      },
    });
  }

  public submitPayment(data: any, callback: any): void {
    (window as any).bluesnap.submitCredentials(callback, data);
  }
}
