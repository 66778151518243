export enum WebformFieldType {
  SINGLE_SELECT = 'webform_single_select',
  TEXT = 'webform_text',
  TEXTAREA = 'webform_textarea',
  MULTI_SELECT = 'webform_multi_select',
  RADIO = 'webform_radio',
  CHECKBOX = 'webform_checkbox',
  LABEL = 'webform_label',
  LABEL_PLACEHOLDER = 'webform_label_placeholder',
  BUTTON = 'webform_button',
  GDPR_FIELD = 'webform_gdpr_field',
}
