import * as $ from 'jquery';
import { Nullable } from '../../../interfaces/common';

import { logger } from './logger';

/**
 * Find closest matching attribute in parent nodes
 */
export function getClosestParentDataAttr(element: HTMLElement, dataAttr: string): Nullable<string> {
  if (!element) {
    /**
     * Sometimes Root element is removed during recursive call of getClosestParentDataAttr.
     * e.g Closing the Directional Select deletes it before finishing the getClosestParentDataAttr function
     */
    return null;
  }

  const editableAttribute = element.getAttribute(dataAttr);
  if (element.nodeName === 'BODY') {
    return null;
  } else if (editableAttribute) {
    return editableAttribute;
  } else {
    return getClosestParentDataAttr(element.parentNode as HTMLElement, dataAttr);
  }
}

export function scrollToElementOrRedirect(e: MouseEvent, href: string): void {
  if (href && href.substring(0, 1) === '#' && href.length > 2) {
    const $scrollToElement = $(href); // document.getElementById((linkElement.getAttribute('href') || '').substr(1));

    e.preventDefault();

    $('html, body').animate(
      {
        scrollTop: $scrollToElement.offset().top,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      },
      1000,
    );
  } else {
    logger.log('Redirect');
  }
}

export function isElementVisible($element: JQuery): boolean {
  const element = $element.get(0);
  if (element) {
    const { top, bottom } = element.getBoundingClientRect();
    const viewportHeight = $(window).height();
    return (top >= 0 && bottom <= viewportHeight) || (top <= 0 && bottom >= viewportHeight);
  }
  return false;
}
