/* tslint:disable:no-invalid-this max-line-length */
import { TranslationKey } from '../enums/translationKey';

export const defaultTranslations: { [key in TranslationKey]: string } = {
  [TranslationKey.ModSqueezePageLPCCreatePageLoading]: 'Loading...',
  [TranslationKey.ModSqueezePageLPCCreatePageDashboardVariation]: 'Variant',
  [TranslationKey.ModSqueezePageLPCCreatePageDashboardVariantName]: 'Variant {{variantLetter}}',

  [TranslationKey.ModSqueezePageLPCCreatePageArenaWebformNoneDrop]: 'This button cannot be used inside the web form.',
  [TranslationKey.ModSqueezePageLPCCreatePageArenaSectionNoneDrop]: 'Adding elements to this section is not allowed.',
  [TranslationKey.ModSqueezePageLPCCreatePageArenaWebinarNoneDrop]: 'This component cannot be used inside webinar.',
  [TranslationKey.ModSqueezePageLPCCreatePageArenaProductBoxNoneDrop]: 'Adding elements to ProductBox is not allowed.',
  [TranslationKey.ModSqueezePageLPCCreatePageArenaAttachToThisBox]: 'Attach to this box',

  // History
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryListHeader]: 'History',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableAdd]: 'Add',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableRemove]: 'Remove',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableBringToTop]: 'Bring to top',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableBringToBottom]: 'Bring to bottom',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableContentChange]: 'Change content of',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditablePropertyChange]: 'Change properties of',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableDuplicate]: 'Duplicate',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableCopy]: 'Copy',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditablePaste]: 'Paste',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryLPSLoaded]: 'Editor Loaded',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableMovedPart1]: 'Move',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableMovedPart2]: 'components',
  [TranslationKey.ModSqueezePageLPCCreatePageHistorySectionMovedUp]: 'Section moved up',
  [TranslationKey.ModSqueezePageLPCCreatePageHistorySectionMovedDown]: 'Section moved down',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryConvertToMobile]: '{{variantName}} converted to mobile',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableRemoveComponent]: 'Remove {{numberOfComponents}} component',
  [TranslationKey.ModSqueezePageLPCCreatePageHistoryEditableRemoveComponents]:
    'Remove {{numberOfComponents}} components',

  [TranslationKey.ModSqueezePageLPCCreatePageEditableName]: 'Component name: ',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameArena]: 'Arena',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameBox]: 'Box',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameCircle]: 'Circle',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameBoxCookieNotification]: 'Cookie Notification Toolbar',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameButton]: 'Button',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameButtonCookieNotification]: 'Button Cookie Notification',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameHLine]: 'Horizontal line',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameImage]: 'Image',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNamePaypalbutton]: 'PayPal button',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameSection]: 'Section',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameSocial]: 'Social',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameFacebook]: 'Facebook',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameTweet]: 'Twitter',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNamePinterest]: 'Pinterest',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameGoogleplus]: 'Google Plus',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameLinkedin]: 'Linkedin',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameText]: 'Text',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameTextCookieNotification]: 'Text Cookie Notification',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameVideo]: 'Video',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameVLine]: 'Horizontal line',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameWebform]: 'Webform',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameWebinar]: 'Webinar',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameWebinarButton]: 'Webinar button',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameWebinarText]: 'Webinar text',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameCustomCode]: 'Custom code',
  [TranslationKey.ModSqueezePageLPCCreatePageEditableNameCountdownTimer]: 'Countdown Timer',

  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningTitleSingular]: 'Continue without mobile version?',
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningTitlePlural]: 'Continue without mobile versions?',
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningDescSingular]:
    "Looks like you haven't generated the mobile version for the landing page. You may continue, but we won't be able to serve the fully optimized mobile version.",
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningDescPlural]:
    "Looks like you haven't generated the mobile version for some of the landing page variants. You may continue, but we won't be able to serve the fully optimized mobile versions.",
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningAnswerOkSingular]: 'OK, generate the mobile version',
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningAnswerOkPlural]: 'OK, generate the mobile versions',
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningAnswerCancelSingular]: 'Proceed without the mobile version',
  [TranslationKey.ModSqueezePageLPCCreatePageNoMobileWarningAnswerCancelPlural]: 'Proceed without the mobile versions',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSubject]: 'Subject',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsTitle]: 'Title',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSection]: 'Section',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsTarget]: 'Target',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsOpenInNewWindow]: 'Open in new window',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsOpenInSameWindow]: 'Open in same window',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebAddress]: 'Web',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectPhoneOption]: 'Phone',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsPhoneLabel]: 'Phone',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsPhonePlaceholder]: '555-555-555',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectWebinarOption]: 'Webinar',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectWebinarNameOption]: 'Webinar',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebinarLabel]: 'Name',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebinarPlaceholder]: 'Webinar',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectSectionOption]: 'Section',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectJoinTheProgramOption]: 'Order form',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectProductLinkOption]: 'Product link',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectLeadMagnetProductLinkOption]: 'Lead magnet product link',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectOTOPageDismissOption]: 'Confirmation or next upsell page',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelectOTOPageAcceptOption]:
    'Process payment and show confirmation or next upsell page',

  [TranslationKey.ModSqueezePageLPCCreatePageToolDragDropAttachToThis]: 'Attach to this',
  [TranslationKey.ModSqueezePageLPCCreatePageToolDragDropAttachTo]: 'Attach to',

  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeAlertText]:
    "After you've made <strong>changes to the HTML code</strong> of your newsletter,<br />you might <strong>not be able to further edit</strong> it in the drag-and-drop editor.",
  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeAlertTextSure]: 'Are you sure you want to continue?',
  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeNoThanks]: 'No, thanks',
  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeYesContinue]: 'Yes, continue',
  [TranslationKey.ModSqueezePageLPCCreateLabelOK]: 'OK',

  [TranslationKey.ModSqueezePageLPCCreatePageDefaultCampaignDialogHeader]: "Something's changed",
  [TranslationKey.ModSqueezePageLPCCreatePageDefaultCampaignDialogDescription]:
    'This landing page is now inactive and part of your default campaign.<br />This happened because you deleted its original campaign.<br />You can assign this page to another campaign in the landing page settings.',

  [TranslationKey.ModSqueezePageLPCCreatePageMobileCompositionHeader]: 'Mobile view workspace',
  [TranslationKey.ModSqueezePageLPCCreatePageMobileCompositionDialogDescription]:
    "<p>We need to compose the mobile version of your page.<br/> And... it isn't the preview you used previously.</p> <p>You can now work with a mobile-specific variant of a landing page.</p>",
  [TranslationKey.ModSqueezePageLPCCreatePageMobileCompositionOK]: "OK, let's go mobile",
  [TranslationKey.ModSqueezePageLPCCreatePageMobileCompositionCancel]: 'Cancel',

  [TranslationKey.ModSqueezePageLPCCreatePageWebformWebformFields]: 'Webform fields',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField]: 'This field is required',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarWebinarFields]: 'Webinar fields',
  [TranslationKey.ModSqueezePageLPCCreatePageCountdownTimerEditButton]: 'Edit',

  [TranslationKey.ModSqueezePageLPCCreatePageProductListEditButton]: 'Edit',
  [TranslationKey.ModSqueezePageLPCCreatePageProductsBoxEditButton]: 'Edit',

  [TranslationKey.ModSqueezePageLPCCreatePageProductsBoxQuantity]: 'Quantity:',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageQuantity]: 'Quantity:',

  // Editable
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsTextPlaceholder]: 'Place your text here.',

  // Dashboard components
  [TranslationKey.ModSqueezePageLpcCreatePageHtmlEditor]: 'HTML',
  [TranslationKey.ModSqueezePageLpcCreatePageSettings]: '',
  [TranslationKey.ModSqueezePageLpcCreatePageAbTestLimit]: 'Available for paid accounts only',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateBtnSave]: 'Save',
  [TranslationKey.ModSqueezePageLpcCreatePageSaveAndExit]: 'Save & exit',
  [TranslationKey.ModSqueezePageLpcCreatePageNextStep]: 'Next step',
  [TranslationKey.ModSqueezePageLpcCreatePageShowHiddenComponents]: 'Show hidden',
  [TranslationKey.ModSqueezePageLpcCreatePageHideHiddenComponents]: 'Hide elements',
  [TranslationKey.ModSqueezePageLpcCreatePageSaveAsTemplate]: 'Save as template',
  [TranslationKey.ModSqueezePageLpcCreatePagePreview]: 'Preview',
  [TranslationKey.ModSqueezePageLpcCreatePageLayoutAssistant]: 'Mobile assistant',
  [TranslationKey.ModSqueezePageLpcCreatePageMobileChangesTooltip]: 'Changes have affected desktop view',
  [TranslationKey.ModSqueezePageLpcCreatePageDesktopChangesTooltip]: 'Changes have affected mobile view',

  [TranslationKey.ModSqueezePageLpcCreatePageDesktopPreview]: 'Dektop workspace',
  [TranslationKey.ModSqueezePageLpcCreatePagePhonePreview]: 'Mobile workspace',

  // Welcome Screen
  [TranslationKey.ModSqueezePageLpcWelcomeScreenHeader]: 'Ready to go responsive? We are!',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenText1]:
    "We've just launched a special workspace for customizing the mobile view of your landing page.",
  [TranslationKey.ModSqueezePageLpcWelcomeScreenText2]: "While you're in the mobile view workspace you can:",
  [TranslationKey.ModSqueezePageLpcWelcomeScreenText3]: 'Work out the best version matching the mobile viewport',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenText4]: 'Make sure all sections and items will visible',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenText5]: 'Scale text and images to fit small screens',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenText6]: 'Hide some parts of the page in the mobile view of the page',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenLinkToWiki]:
    '<a target="_blank" href="http://www.getresponse.com/faq/mobile-landing-page">See more details on the mobile workspace</a>',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenButtonOk]: 'Ok, got it',
  [TranslationKey.ModSqueezePageLpcWelcomeScreenCbxDontShowAgain]: "Don't show this again",

  [TranslationKey.ModSqueezePageLpcSaveAsTemplateHeader]: 'Save as template',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateInputPlaceholder]: 'Enter the name of a template',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateDescr]:
    'Your landing page will be saved as a template in your privacy catalog',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateOffer]:
    'Please note that you can only use this template to create a new Offer page in a funnel',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateLanding]:
    'Please note that you can only use this template to create a new Landing page in a funnel',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateConfirm]:
    'Please note that you can only use this template to create a new Confirm page in a funnel',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateOto]:
    'Please note that you can only use this template to create a new Oto page in a funnel',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateThankyou]:
    'Please note that you can only use this template to create a new Thankyou page in a funnel',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateBtnCancel]: 'Cancel',
  [TranslationKey.ModSqueezePageLpcSaveAsTemplateBtnSaveAndExit]: 'Save & exit',

  // Recreate mobile view Screen
  [TranslationKey.ModSqueezePageLPCCreatePageRefreshMobileHeader]: 'Refresh mobile',
  [TranslationKey.ModSqueezePageLPCCreatePageRefreshMobileParagraph1]:
    'This will re-generate the mobile page based on your current desktop page design.',
  [TranslationKey.ModSqueezePageLPCCreatePageRefreshMobileParagraph2]:
    'Any mobile specific changesyou have made will be lost.',
  [TranslationKey.ModSqueezePageLPCCreatePageRefreshMobileDontShowAgain]: 'Never show this message again.',
  [TranslationKey.ModSqueezePageLPCCreatePageRefreshMobileConfirmButton]: 'I understand, continue',
  [TranslationKey.ModSqueezePageLPCCreatePageRefreshMobileCancelButton]: 'Close',

  // Webinar modal
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalTitle]: 'Which webinar is the page for?',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalConfirm]: 'Add webinar',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalCancel]: 'Back to templates',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalButtonCancel]: 'Cancel',

  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinars]: "You've got no scheduled webinars yet.",
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsSubtitle]:
    'You can add only upcoming webinars here.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsConfirm]: 'Schedule webinar',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsCancel]: 'Cancel',

  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoWebinarsService]:
    "Webinars aren't included in your current pricing package.",
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceSubtitle]:
    'You can buy an add-on or upgrade your account.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceConfirm]: 'Upgrade account',
  [TranslationKey.ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceCancel]: 'Cancel',

  // WebformAppModal
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeTitle]: 'What kind of form would you like to use?',
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeItemDownloadBox]: 'Download Box',
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeItemOnExit]: 'Exit Pop-up',
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeItemFixedBar]: 'Fixed Bar',
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeItemImageBox]: 'Image Box',
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeItemListBuilderBox]: 'Sign-up Box',
  [TranslationKey.ModSqueezePageLPCWebformAppModalChooseTypeItemScrollBox]: 'Scroll Form',

  // Webfrom custom fields modal
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTitle]: 'Select custom field',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalConfirm]: 'Apply',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalCancel]: 'Cancel',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalRequiredLabel]: 'Required',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn1Title]: 'Name',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn2Title]: 'Required',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn3Title]: 'Name',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn4Title]: 'Required',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn5Title]: 'Name',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn6Title]: 'Required',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabCustomFields]: 'Custom fields',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabOrderFormFields]: 'Order form fields',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsents]: 'Consents',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsNoData]:
    "Looks like there's nothing to display.<br />What's next?",
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsLearnHowToDoIt]: 'Learn how to do it',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsLearnHowToDoItLink]:
    'https://www.getresponse.com/help/managing-your-account/gdpr-compliance/how-do-i-create-and-use-gdpr-fields.html',

  // AB test feature limitation modal
  [TranslationKey.ModSqueezePageLPCCreatePageDashboardAddVariant]: 'Add variant',
  [TranslationKey.ModSqueezePageLPCAbTestFeatureModalTitle]: 'Paid account required',
  [TranslationKey.ModSqueezePageLPCAbTestFeatureModalDescrIntro]:
    'Testing landing page variants is available in paid plans',
  [TranslationKey.ModSqueezePageLPCAbTestFeatureModalDescrList]:
    '<ul><li>Test pages to find out which variants work best for you</li><li>Bigger list, more contacts</li><li>More messages to reach a greater audience</li><li>Live support to talk to people when you need to</li></ul>',
  [TranslationKey.ModSqueezePageLPCAbTestFeatureModalButtonConfirm]: 'Upgrade to unlock testing',
  [TranslationKey.ModSqueezePageLPCAbTestFeatureModalButtonCancel]: 'Cancel',

  // Blockbar
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenSection]: 'Drop element section',
  [TranslationKey.ModSqueezePageLpcCreatePageNewFeatureBadge]: 'NEW',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSectionModuleInfo]:
    'We\'ve greatly improved how sections work. Now they work for real. <a href="#" target="_blank">See our FAQ to learn more</a>',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenWebinar]: 'Drop element webinar',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsNoActiveWebinars]:
    'No active webinars found. <a href="#" target="_blank">Go to webinars</a>',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsNoWebinarsService]:
    'No Webinars Service. <a href="#" target="_blank">Upgrade Account</a>',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenText]: 'Drop element text',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenImage]: 'Drop element image',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenButton]: 'Drop element button',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenWebform]: 'Drop element webform',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenWebformApp]: 'Drop element webform app',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenBox]: 'Drop element box',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenCountdown]: 'Drop element countdown',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenCircle]: 'Drop element circle',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenVideo]: 'Drop element video',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElementProductsBox]: 'Drag and drop - Product box',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSocialMediaList]: 'Social media list',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsShapesList]: 'Shapes list',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSocialMediaFacebook]: 'Facebook',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSocialMediaTweet]: 'Tweet',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSocialMediaPintIt]: 'Pint it',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSocialMediaGooglePlus]: 'Google+',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSocialMediaLinkedIn]: 'LinkedIn',
  [TranslationKey.ModSqueezePageLPCCreatePagePayPalList]: 'PayPal Buttons',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenHLine]: 'Drop element hline',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenVLine]: 'Drop element vline',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDropElemenCustomCode]: 'Drop element custom code',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebformsList]: 'Webforms',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebformsListWebformApp]: 'Pop-up forms',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebformsListWebformNew]: 'Static forms',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsInactiveElementOnThp]: 'Unavailable on thank you page',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsInactiveWebinarElementOnThp]:
    "Webinars can't be added to a thank-you page",
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsContactEnterpriseAccountOwner]:
    'To use webinar feature contact your Enterprise Account Owner.',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsContactEnterpriseAccountManager]:
    'To use Webinars, contact your Account Manager.',

  // Toolbar
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsButtonColor]: 'Button color',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBorderStyle]: 'Border style',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBorderNone]: 'none',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBorderSolid]: 'solid',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBorderDashed]: 'dashed',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBorderDotted]: 'dotted',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBorderSize]: 'Border size',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsCookieNotificationTop]: 'Top',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsCookieNotificationBottom]: 'Bottom',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsYouVideoURL]: 'Movie URL',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsUpload]: 'Upload',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsControlBar]: 'Control',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsShow]: 'show',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsHide]: 'hide',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAutoplay]: 'autoplay',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsLoop]: 'loop',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsShowTitleBar]: 'show title bar',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsShowRelatedVideos]: 'show related videos',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsCornersRadius]: 'Corners radius',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsShadowColor]: 'Shadow color',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDirection]: 'Direction',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDistance]: 'Distance',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBlur]: 'Blur',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSpread]: 'Spread',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBackgroundImage]: 'Background image',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsTiling]: 'Tiling',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsNoRepeat]: 'no&nbsp;repeat',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsHorizontal]: 'horizontal',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsVertical]: 'vertical',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBoth]: 'both',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsScale]: 'Scale',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsFitScreen]: 'Fit screen',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsPosition]: 'Position',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsChooseImage]: 'Choose image',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsBackgroundColor]: 'Background color',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsDefault]: 'Default',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSelect]: 'Select',
  [TranslationKey.ModSqueezePageLPCCreatePageChangeImageFailure]: 'The image has not been changed',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsColumnCountLabel]: 'Arrangement',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsColumnCount1Column]: 'One column',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsColumnCount2Column]: 'Two column',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsColumnCount3Column]: 'Three column',

  // Components
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebinarJoinNow]: 'Join now',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsWebinarNotFound]: 'Webinar not found',
  [TranslationKey.ModSqueezePageLPCCreatePageArenaEdit]: 'Edit',
  [TranslationKey.ModSqueezePageLPCCreatePageArenaReplace]: 'Replace',

  [TranslationKey.ModSqueezePageLPCCreatePageLeavePage]: 'Are you sure you want to leave the editor?',
  [TranslationKey.ModSqueezePageLPCCreatePageCookieNotificationTitleTextPlaceholder]: 'This website uses cookies.',
  [TranslationKey.ModSqueezePageLPCCreatePageCookieNotificationTextPlaceholder]:
    'We use cookies to make sure that our website works correctly and that you have the best experience possible. We also use cookies for some basic analytics, to help us improve the site.',
  [TranslationKey.ModSqueezePageLPCCreatePageCookieNotificationCloseButton]: 'Got it!',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsSignUpNow]: 'Sign up now!',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsPayPalBuyNow]: 'Buy now!',
  [TranslationKey.ModSqueezePageLPCCreatePageWebFormLabelName]: 'Your Name',
  [TranslationKey.ModSqueezePageLPCCreatePageWebFormLabelEmail]: 'Your Email',
  [TranslationKey.ModSqueezePageLPCCreatePageWebFormButton]: 'Sign up!',
  [TranslationKey.ModSqueezePageLPCCreatePageWebFormSelect]: 'Select...',

  [TranslationKey.ModSqueezePageLPCCreatePageWebinarTitle]: 'Webinar',

  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeNextStep]: 'Next step',
  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeClose]: 'Close',
  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeSaveAndPublish]: 'Save & publish',
  [TranslationKey.ModSqueezePageLPCCreatePageHtmlCodeSaveAndExit]: 'Save & exit',
  [TranslationKey.ModSqueezePageLpcCreatePageIEInfo]:
    'The new Landing Page Creator BETA version does not support your<br />current browser: Internet Explorer. Further template edition is not available.<br /><strong>For maximum performance please log in with <a href="http://www.google.com/chrome" target="_blank" title="Google Chrome">Google Chrome</a>.</strong>',
  [TranslationKey.ModSqueezePageLPCCreatePagePayPalNoIntegrationsMsg]:
    'To use BUY NOW buttons, please activate the integration.<br><br><a href="/integrations.html" target="_blank">Go to integrations.</a>',
  [TranslationKey.ModSqueezePageLPCCreatePagePayPalPleaseWait]: "Please wait while we're connecting with PayPal",
  [TranslationKey.ModSqueezePageLPCCreatePagePayPalSelectTheLink]: 'Select the link',
  [TranslationKey.ModSqueezePageLPCCreatePagePayPalError]: 'Error',
  [TranslationKey.ModSqueezePageLPCCreatePagePayPalNoButtons]: 'No buttons',
  [TranslationKey.ModSqueezePageLPCCreatePageWebFormLabelLastName]: 'Last name',
  [TranslationKey.ModSqueezePageLPCCreatePageWebFormLabelFirstName]: 'First name',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsType]: 'Type',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsLinkTo]: 'Link to',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsEGWWWGetresponseCom]: 'E.g www.getresponse.com',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsEmail]: 'E-mail',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsEGEmailGetresponseCom]: 'E.g info@getresponse.com',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsPayPalButtonUrlPlaceholder]: 'Paste link.',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsLinkNormal]: 'Normal',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsLinkHover]: 'Hover',
  [TranslationKey.ModSqueezePageLPCCreatePageColorPickerSolidColor]: 'Solid color',
  [TranslationKey.ModSqueezePageLPCCreatePageColorPickerGradient]: 'Gradient',
  [TranslationKey.ModSqueezePageLPCCreatePageColorPickerImage]: 'Image',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsPleaseFillInAValidYouTubeURL]: 'Please fill in a valid URL',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAvailableVideoProvidersInfo]:
    'You can embed videos from YouTube, Vimeo, Wistia, Vidyard, and Vine.<br /><a href="#" target="_blank">Tell me more</a>',
  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodePlaceHolderText]:
    'This is a placeholder.<br/>Publish the page or switch to <a data-editable-item="custom-code-preview-link" title="Preview">Preview</a><br/>to see how your code works',
  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodeDoubleClickText]: 'Double-click to edit.',
  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodeWindowTitle]: 'Edit custom code',
  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodeWindowTitleNoContent]: 'Add custom code',
  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodeWindowInfoText]:
    'Use this field to add HTML, CSS, or JavaScript to your landing page. When adding multiple code snippets, paste them in one under another.<br/>Be sure to wrap your JavaScript in &lt;script&gt; tags.',

  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodeWindowConfirm]: 'OK',
  [TranslationKey.ModSqueezePageLPCCreatePageCustomCodeWindowCancel]: 'Cancel',

  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppPlaceHolderText]: 'This is a webform app placeholder.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppDoubleClickText]: 'Double-click to edit.',

  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsDownloadBox]: 'Create Download Box.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsFixedBar]: 'Create Fixed Bar. ',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsImageBox]: 'Create Image Box. ',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsListBuilderBox]: 'Create Sign-up Box. ',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsNoExit]: 'Create On-Exit Box. ',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsOnExitSubtitle]:
    'Shows when the mouse is moved outside the site area.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsScrollBox]: 'Create Scroll Box. ',

  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditDownloadBox]: 'Edit Download Box.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditFixedBar]: 'Edit Fixed Bar.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditImageBox]: 'Edit Image Box.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditListBuilderBox]: 'Edit Sign-up Box.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditNoExit]: 'Edit On-Exit Box.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditOnExitSubtitle]:
    'Shows when the mouse is moved outside the site area.',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformAppSettingsEditScrollBox]: 'Edit Scroll Box.',

  // CountdownTimerModal
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalCounterTypeLabel]: 'Counter type',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalCounterTypeStandard]: 'Standard',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalCounterTypeEvergreen]: 'Evergreen',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalTimePickerDaysLabel]: 'Days',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalTimePickerHoursLabel]: 'Hours',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalTimePickerMinutesLabel]: 'Minutes',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalChooseActionLabel]: 'What happens after time expires?',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalChooseActionDoNothing]: 'Do nothing',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalChooseActionRedirect]: 'Redirect to another landing page',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalChooseActionRedirectExample]: 'http://example.com',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalChooseActionRedirectErrorEmpty]: 'Empty field',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalChooseActionRedirectErrorInvalid]: 'Invalid url',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalTitle]: 'Pick the time',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalDateTimePickerLabel]: 'Select date',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalDateTimePickerErrorHint]: 'Date in the past',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalDateTimePickerPlaceholder]: 'Choose date',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalSelectTimeZoneLabel]: 'Select time zone',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalSelectTimeZonePlaceholder]: 'Search...',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalButtonConfirm]: 'Save',
  [TranslationKey.ModSqueezePageLPCCountdownTimerModalButtonCancel]: 'Cancel',
  [TranslationKey.ModSqueezePageLPCCountdownTimerPlaceholderDays]: 'DAYS',
  [TranslationKey.ModSqueezePageLPCCountdownTimerPlaceholderHours]: 'HOURS',
  [TranslationKey.ModSqueezePageLPCCountdownTimerPlaceholderMinutes]: 'MINUTES',
  [TranslationKey.ModSqueezePageLPCCountdownTimerPlaceholderSeconds]: 'SECONDS',

  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageGoToURL]: 'Go to URL',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageDownload]: 'Download',
  [TranslationKey.ModSqueezePageLPCFunnelNoProducts]: 'No products',
  [TranslationKey.ModSqueezePageLPCFunnelDynamicProductsPlaceholder]: 'Bought products will be displayed here.',
  [TranslationKey.ModSqueezePageLPCFunnelDynamicProductsLeadMagnetPlaceholder]:
    "Here we'll show the file you want to share with your contacts",
  [TranslationKey.ModSqueezePageLPCFunnelOrderFormTabName]: 'Order form',

  [TranslationKey.ModSqueezePageLPCFunnelOrderFormNotSeenModalTitle]: 'Could you please check Order form template?',
  [TranslationKey.ModSqueezePageLPCFunnelOrderFormNotSeenModalDescription]:
    'Please check you order form template to be sure that everything is correct.',
  [TranslationKey.ModSqueezePageLPCFunnelOrderFormNotSeenModalButtonConfirm]: 'OK',
  [TranslationKey.ModSqueezePageLPCFunnelOrderFormNotSeenModalButtonCancel]: 'Go back',

  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaption]: 'Join the program',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionExternalUrl]: 'External link',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionInternalUrl]: 'Internal link',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionCopyCampaign]: 'Copy campaign',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionSendMessage]: 'Send message',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionInstruction]: 'Instruction',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionMoveCampaign]: 'Move campaign',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionWebinarAccess]: 'Webinar access',
  [TranslationKey.ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionPhysical]: 'Physical',

  [TranslationKey.ModSqueezePageLPCFunnelProductButtonCaption]: 'See Product',
  [TranslationKey.ModSqueezePageLPCCreatePageProductsBoxEditButtonArrangement]: 'Arrangement',

  [TranslationKey.ModSqueezePageLPCCreatePageFunnelOrderFormNoPostalCodeError]:
    'Square requires Postal Code field in order form',

  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontOption]: '⊕ Add new font',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontWebFont]: 'Web font',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontFallbackFont]: 'Fallback font',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontPreviewText]: 'The face of the moon was in shadow',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontCancel]: 'Cancel',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontApply]: 'Add font',
  [TranslationKey.ModSqueezePageLPCCreatePageToolBarsAddNewFontPanelDescription]: 'Description for add font panel',

  [TranslationKey.ModSqueezePageLpcCreatePageSaveErrorModalHeading]: "Sorry, we can't save your changes",
  [TranslationKey.ModSqueezePageLpcCreatePageSaveErrorModalContent]:
    "There's no list selected for new contacts from this landing page. You can pick a new list but all the changes will be lost.<br/><br/>Click <strong>Next step</strong> to select a new list in </strong>Subscription settings</strong>. The settings will open in a new tab. Select a new target list and save the settings. Then, reload the editor tab before you start editing.",
  [TranslationKey.ModSqueezePageLpcCreatePageSaveErrorModalButton]: 'Next step',

  [TranslationKey.ModSqueezePageLpcCreatePageInvalidSettingsModalHeading]: 'Select list before editing',
  [TranslationKey.ModSqueezePageLpcCreatePageInvalidSettingsModalContent]:
    "Sorry, we couldn't find the original target list for new contacts from this landing page.<br/><br/> Click <strong>Next step</strong> and select a new list in <strong>Subscription settings</strong>. Save your selection and return to the editor. Please be sure to pick a new list before you start editing. <strong>You won't be able to save the changes without it.</strong>",
  [TranslationKey.ModSqueezePageLpcCreatePageInvalidSettingsModalButton]: 'Next step',

  // Errors
  [TranslationKey.ModSqueezePageLpcCreatePageSaveAsTemplateError]: 'Cannot save template',
  [TranslationKey.ModSqueezePageLpcCreatePageSaveError]: "Sorry, we can't save your changes",

  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolModalHeading]: 'Landing page repair tool',
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolIdleModalContentNothingToDo]:
    "We haven't found any elements outside the workspace.",
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolIdleModalContent]:
    "We've found elements outside the workspace affecting the appearance of your page. You can use the repair tool to remove them. We recommend you <strong>run the tool on a duplicate first</strong> to check how removing the elements affects the desktop and mobile views.<br/><br/>Already done that? Run the tool to remove the hidden elements, and check the mobile view for changes.",
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolModalRunButton]: 'Run repair tool',
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolModalLinkManage]: 'Create duplicate',
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolModalFinishedContent]:
    'All done! The hidden elements have been removed. Please be sure to review the changes to the mobile version before saving.',
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolModalDoneButton]: 'Close',
  [TranslationKey.ModSqueezePageLpcCreatePageRepairToolModalCloseButton]: 'Close',
};
