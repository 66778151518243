import $ from 'jquery';
import _ from 'underscore';

function replaceGdprContent() {
  const gdprFields = window.grLpsInitialData.GDPRFields || []; //empty array just to prevent any kind of errors

  $('[data-editable="webformNewItem"][data-item-type="webform_gdpr_field"]').each((index, el) => {
    const $el = $(el);
    const $content = $el.find('[data-part="content"]');
    const consentId = $el.attr('data-consent-id');

    for (let i = 0; i < gdprFields.length; i++) {
      const GDPRField = gdprFields[i];

      if (GDPRField.consentId === consentId) {
        $content.html(_.unescape(GDPRField.content));
      }
    }
  });
}

function fixProblemWithDuplicateIdInContentFields() {
  document.querySelectorAll('[data-editable="webformNew"]').forEach((form) => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const scope = Math.random().toString(36).slice(2, 7);

    form.querySelectorAll('input[name=consent][id^=consent]').forEach((input) => {
      const originalId = input.getAttribute('id');

      const inputId = `${scope}_${originalId}`;
      input.setAttribute('id', inputId);
      input.setAttribute('data-ats-webform-field', inputId);

      const label = form.querySelector(`[for=${originalId}]`);
      if (label) {
        label.setAttribute('for', inputId);
      }
    });
  });
}

export default {
  replaceContent: () => {
    replaceGdprContent();
    fixProblemWithDuplicateIdInContentFields();
  },
};
