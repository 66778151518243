/* tslint:disable:no-invalid-this max-line-length */
import { TranslationKey } from '../enums/translationKey';
import { defaultTranslations } from '../constants/defaultTranslations';

export const l10n = {
  get(key: TranslationKey | string, variables?: any): string {
    const translations = window.APP.getItem('lpsInitialData').l10n;

    let translation = translations[key] || defaultTranslations[key];

    if (translation && variables) {
      translation = translation.replace(/{{(.*?)}}/g, (all, variableName) => {
        if (variables[variableName] === undefined) {
          return all;
        }

        return variables[variableName];
      });
    }

    return translation;
  },

  getComponentName(type: any): string {
    let name;

    if (type === 'hline') {
      name = 'HLine';
    } else if (type === 'vline') {
      name = 'VLine';
    } else if (type === 'webinarNew') {
      name = 'Webinar';
    } else if (type === 'paypal_button') {
      name = 'PayPalButton';
    } else if (type === 'multimedia:image') {
      name = 'Image';
    } else {
      name = type.ucfirst();
    }
    return this.get(TranslationKey.ModSqueezePageLPCCreatePageEditableName + name);
  },
};
