import { Country } from '../../../enums/country';

export default {
  _getCountry(countryCode, countryCodes) {
    const [defaulCountry] = countryCodes.filter((element) => element.cc === Country.DEFAULT);
    const [country] = countryCodes.filter((element) => element.cc === countryCode);

    return country || defaulCountry;
  },

  _getDirectional: function (countryCode, countryCodes) {
    const country = this._getCountry(countryCode, countryCodes);

    return country.code;
  },

  _getCountryCode: function (countryCode, countryCodes) {
    const country = this._getCountry(countryCode, countryCodes);

    return country.cc;
  },

  _updateCountryCode: function (directionalEl, countryCode, countryCodes) {
    //When form has
    const country = this._getCountryCode(countryCode, countryCodes);

    directionalEl.setAttribute('data-country-code', country);
    directionalEl.setAttribute('data-flag', country);
    directionalEl.querySelector('[data-feature-part="directional-container"]').innerHTML =
      '+' + this._getDirectional(countryCode, countryCodes);
  },

  _updateInputCountryCode: function (phoneInputEl, countryCode, countryCodes) {
    //When form has
    const country = this._getCountryCode(countryCode, countryCodes);

    phoneInputEl.setAttribute('data-country-code', country);
  },

  updateCountryCode: function (formElement) {
    //Update country code
    const directionalEl = Array.prototype.slice.call(
      formElement.querySelectorAll('[data-editable="webformNewItem"][data-item-what="phone"] .directional'),
    );
    const phoneInputEl = Array.prototype.slice.call(
      formElement.querySelectorAll('[data-editable="webformNewItem"][data-item-what="phone"] .phone-number'),
    );
    const countryCode = window.grLpsInitialData.country_code;
    const countryCodes = window.grLpsInitialData.country_phone_codes;

    //When form has
    directionalEl.forEach((element) => {
      const defaultCountryCode = element.getAttribute('data-default-country-code');
      this._updateCountryCode(element, defaultCountryCode || countryCode, countryCodes);
    });

    phoneInputEl.forEach((element) => {
      const defaultCountryCode = element.getAttribute('data-default-country-code');
      this._updateInputCountryCode(element, defaultCountryCode || countryCode, countryCodes);
    });
  },
};
