import helperUtils from './utils';

var PAGE_URL = document.location.href,
  PROTOCOL = document.location.protocol,
  REQ = {
    facebook: {
      id: 'facebook-jssdk',
      src: '//connect.facebook.net/en_US/all.js#xfbml=1',
    },
    tweet: {
      id: 'twitter-wjs',
      src: '//platform.twitter.com/widgets.js',
      exports: 'twttr',
    },
    pinterest: {
      id: 'pinterest',
      src: '//assets.pinterest.com/js/pinit_main.js',
    },
    linkedin: {
      id: 'linkedin',
      src: '//platform.linkedin.com/in.js',
    },
    googleplus: {
      id: 'googleplus',
      src: 'https://apis.google.com/js/plusone.js',
    },
  },
  INS = {
    facebook: `<div class="fb-like" data-href="${PAGE_URL}" data-width="450" data-action="like" data-width="90" data-share="false" data-layout="button_count" data-show-faces="false"></div>`,
    tweet: `<a href="https://twitter.com/share" class="twitter-share-button" data-url="${PAGE_URL}">Tweet</a>`,
    pinterest:
      `<a href="${PROTOCOL}//pinterest.com/pin/create/button/?url=${PAGE_URL}&amp;media=&amp;description=" data-pin-zero="true" data-pin-do="buttonPin" data-pin-config="beside">` +
      `<img border="0" src="//assets.pinterest.com/images/PinExt.png" title="Pin It">` +
      `</a>`,
    googleplus: `<div class="g-plus" data-action="share" data-annotation="bubble" data-href="${PAGE_URL}"></div>`,
    linkedin: `<script type="IN/Share" data-counter="right" data-showzero="true" data-url="${PAGE_URL}"></script>`,
    none: '',
  };

export default {
  initialize: function () {
    // social
    var socialEditableElements = Array.prototype.slice.call(document.querySelectorAll('[data-editable="social"]')),
      div = document.createElement('div'),
      req = [];

    for (var i = 0; i < socialEditableElements.length; i += 1) {
      var re = socialEditableElements[i].textContent.match(/\[\[([^:]+?)\]\]/),
        type = socialEditableElements[i].getAttribute('data-type', 2) || (re && re[1]) || 'none';

      if (type) {
        if (!req.includes(REQ[type])) {
          req.push(REQ[type]);
        }

        socialEditableElements[i].innerHTML = '';

        div.innerHTML = INS[type];

        if (div.children) {
          if (div.children.length > 0) {
            socialEditableElements[i].appendChild(div.children[0]);
          }
        }
      }
    }

    helperUtils.socialrequire(req);

    if (window.fbAsyncInit) {
      (function (links) {
        var ln = links.length,
          i;

        for (i = 0; i < ln; i += 1) {
          links[i].target = '_blank';
        }
      })(Array.prototype.slice.call(document.links));
    }
  },
};
