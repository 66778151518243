import { ISqPaymentFormInputStyle } from '../helpers/squareIntegration';

export const allowedSquareStyles: Array<keyof ISqPaymentFormInputStyle> = [
  'color',
  'fontSize',
  'fontWeight',
  'lineHeight',
];

export const allowedSquareBlockStyles = [
  'border',
  'borderColor',
  'borderTopColor',
  'borderRightColor',
  'borderBottomColor',
  'borderLeftColor',
  'boxShadow',
  'boxShadowColor',
  'borderRadius',
  'background',
];
