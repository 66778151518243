import { FormElement } from '../factories/formElement';

function Forms() {}

Forms.prototype = {
  register(element) {
    const oThis = this;
    const formSelectors = [
      'div[data-editable="webform"] > form',
      '[data-editable="webformNew"] form',
      '[data-editable="webinarNew"] form',
    ];
    const formElements = element ? [element] : document.querySelectorAll(formSelectors.join(', '));

    (Array.prototype.slice.call(formElements) || []).forEach((formElement) => {
      const form = FormElement.createFormElement(formElement);

      Array.prototype.push.call(oThis, form);
    });
  },

  namedItem(name) {
    return this[name];
  },
};

export default Forms;
