/* eslint-disable @typescript-eslint/no-explicit-any */
import { GrChatStatus } from '../enums/grChatStatus';
import { TrackingCodePlatformName } from '../enums/trackingCodePlatformName';
import { PaymentProcessor } from '../enums/paymentProcessors';
import { AutoFunnelType } from '../enums/autoFunnelType';
import { IProduct } from '../enums/product';

export enum TranslationKey {
  ModSqueezePageLPCCreatePageAutoFunnelBtnProcessingCaption = 'ModSqueezePageLPCCreatePageAutoFunnelBtnProcessingCaption',
  ModSqueezePageLPCCreatePageAutoFunnelProductsNotSelected = 'ModSqueezePageLPCCreatePageAutoFunnelProductsNotSelected',
  ModSqueezePageLPCCreatePageGrowlerErrorName = 'ModSqueezePageLPCCreatePageGrowlerErrorName',
  ModSqueezePageLPCCreatePageGrowlerInfoName = 'ModSqueezePageLPCCreatePageGrowlerInfoName',
  ModSqueezePageLPCCreatePageGrowlerSuccessName = 'ModSqueezePageLPCCreatePageGrowlerSuccessName',
  ModSqueezePageLPCCreatePageGrowlerWarningName = 'ModSqueezePageLPCCreatePageGrowlerWarningName',
  ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField = 'ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField',
  ModSqueezePageLPCFunnelConfirmationPageDownload = 'ModSqueezePageLPCFunnelConfirmationPageDownload',
  ModSqueezePageLPCFunnelConfirmationPageGoToURL = 'ModSqueezePageLPCFunnelConfirmationPageGoToURL',
  ModSqueezePageLPCFunnelConfirmationPageJoinTheWebinar = 'ModSqueezePageLPCFunnelConfirmationPageJoinTheWebinar',
  ModSqueezePageLPCFunnelConfirmationPageProductDeliveryTokenTitle = 'ModSqueezePageLPCFunnelConfirmationPageProductDeliveryTokenTitle',
  ModSqueezePageLPCFunnelOrderFormPaymentGrowlerNoPostalCodeError = 'ModSqueezePageLPCFunnelOrderFormPaymentGrowlerNoPostalCodeError',
  ModSqueezePageLPCCreatePageInvalidDateFormat = 'ModSqueezePageLPCCreatePageInvalidDateFormat',
  ModSqueezePageLPCCreatePageDatePlaceholder = 'ModSqueezePageLPCCreatePageDatePlaceholder',
  ModSqueezePageLPCFunnelConfirmationPageQuantity = 'ModSqueezePageLPCFunnelConfirmationPageQuantity',
}

export interface IShop {
  shopId: string;
  currency: string;
  productsVariants: IProduct[];
}

export interface IAutoFunnel {
  autoFunnelType: AutoFunnelType;
  autoFunnelPaymentStatusUrl: string;
  boughtProducts: [];
  cartId: any;
  currency: string;
  formFieldsValues: [];
  id: string;
  orderFormUrl: string;
  paymentProcessor: PaymentProcessor;
  paymentProcessorToken: string;
  paymentStripeAPIKey: string;
  paymentStripeCountryCode: string;
  processPaymentUrl: string;
  stepId: string;
  stepType: string;
  stripePaymentRequestButtonUrl: string;
  autoFunnelPaymentSquareLocationId: string;
  otoPageDismissUrl: string;
  otoPageAcceptUrl: string;
  enableOtoPageAction: boolean;
}

export interface IECommerceData {
  shops: IShop[];
}

export type ITrackingCode = {
  [key in TrackingCodePlatformName]?: string[];
};

export interface GRChat {
  status: GrChatStatus;
  urlParams: string;
}

export interface ILpsShowInitialData {
  GDPRFields: [];
  autoFunnel: IAutoFunnel;
  eCommerceData: IECommerceData;
  country_code: string;
  country_phone_codes: [];
  google_adwords_conversion_label: string;
  google_adwords_id: string;
  grBadge: {
    imageUrl: string;
    redirectUrl: string;
  };
  isCustomCodeAllowed: boolean;
  l10n: { [key in TranslationKey]: string };
  thankyoutext: string;
  thankyouurl: string;
  tracking_codes: ITrackingCode;
  url: string;
  url_product_box_default_image: string;
  url_to_old_mobile_styles: string;
  cdn: string;
  userId: string;
  user_panel_lang: string;
  variantId: string;
  crypto: string;
  webformApps: [];
  webinars: {
    list: any;
    settings: {
      read: boolean;
      write: boolean;
    };
  };
  grChat: GRChat;
  landingPageEncodedId: string;
  adsPixelId: string;
  visitToken: string;
  isFreemium: boolean;
  badge: {
    hasBadge: boolean;
    grBadgeUrl: string;
  };
}
