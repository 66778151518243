import { ButtonType } from '../../../enums/buttonTypes';
import { LinkType } from '../../../enums/linkType';

export function isJoinTheProgramLink(target: HTMLAnchorElement): boolean {
  const linkType: LinkType = target.getAttribute('data-link-type') as LinkType;
  const buttonType: ButtonType = (target.parentNode as Element).getAttribute('data-button-type') as ButtonType;
  const isJoinTheProgramButton: boolean = buttonType === ButtonType.JOIN_THE_PROGRAM;

  return linkType === LinkType.JOIN_THE_PROGRAM || (isJoinTheProgramButton && !linkType);
}
