import { CurrencyHelper } from './currency';
import { IProductData } from '../enums/product';

export class AutoFunnelHelper {
  public static updateProductsBoxData($productsBox: JQuery, products: any[], userPanelLang: string): void {
    products.forEach((product) => {
      const $product = $productsBox.find(`[data-product-id="${product.id}"]`);

      const $productName = $product.find('[data-component-part="product-name"] .font-styles');
      const $productDescription = $product.find('[data-component-part="product-description"] .font-styles');
      const $productPrice = $product.find('[data-component-part="product-price"] .font-styles');
      const $productEventStartDate = $product.find('[data-component-part="product-event-start-date"] .font-styles');

      const $productNameInlineStyles = $productName.find('span');
      const $productDescriptionInlineStyles = $productDescription.find('span');
      const $productPriceInlineStyles = $productPrice.find('span');
      const $productEventStartDateStyles = $productEventStartDate.find('span');

      const $productImageElement = $product.find('[data-component-part="product-logo-image-wrapper"] img');
      const $productNameElement = $productNameInlineStyles.length ? $productNameInlineStyles : $productName;
      const $productDescriptionElement = $productDescriptionInlineStyles.length
        ? $productDescriptionInlineStyles
        : $productDescription;
      const $productPriceElement = $productPriceInlineStyles.length ? $productPriceInlineStyles : $productPrice;
      const $productEventStartDateElement = $productEventStartDateStyles.length
        ? $productEventStartDateStyles
        : $productEventStartDate;

      $productImageElement.attr('src', product.image);
      $productNameElement.html(product.name);
      $productDescriptionElement.html(product.description);
      $productPriceElement.html(CurrencyHelper.format(product.price, userPanelLang, product.currency));
      $productEventStartDateElement.html(product.eventStartDate);
    });
  }

  public static updateProductListData($productsList: JQuery, products: any[], userPanelLang: string): void {
    //Update products data
    products.forEach((product) => {
      const $product = $productsList.find(`[data-product-id="${product.id}"]`);
      $product.find('[data-style-part="name"]').html(product.name);
      $product
        .find('[data-style-part="price"]')
        .html(CurrencyHelper.format(product.price, userPanelLang, product.currency));
    });

    //Remove not existing products
    $productsList
      .find('[data-component-part="product-list-content-container"]')
      .children()
      .each((index, el) => {
        const $product = $(el);
        const productId = $product.attr('data-product-id');
        const productExist = products.find((product) => product.id === productId);

        if (!productExist) {
          $product.remove();
        }
      });
  }

  public static getProductsData(products: any[], currency: string, userPanelLang: string): IProductData[] {
    return products.map((product) => ({
      deliveryType: product.delivery_type,
      eventStartDate: product.event_start_date,
      id: product.id,
      name: product.name,
      description: product.description,
      price: product.price,
      priceWithCurrency: CurrencyHelper.format(product.price, userPanelLang, currency),
      currency,
      image: product.image,
      url: product.url,
      isDigital: product.isDigital,
      quantity: product.quantity,
    }));
  }

  public static getConvertedValue(type: string, value: any): string {
    switch (type) {
      case 'fontWeight':
      case 'fontStyle':
      case 'textDecoration':
        return value === 0 ? 'normal' : value;
      case 'fontSize':
        return `${value}px`;
      case 'color':
        return value.color;
      default:
        return value;
    }
  }
}
