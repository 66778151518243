export interface IOrderFormObject {
  formElement: HTMLElement;
  formObj: any;
  orderFormElement: HTMLElement;
  strategy: any;
  getItems: () => HTMLElement[];
  hasErrors: () => boolean;
  submitPayment: (data: any, callback: any) => void;
  submitPaymentWithConfirmation: (data: any, callback: any) => void;
  createPaymentMethod: (data: any, callback: any) => void;
  updateFields: (data: any) => void;
}

export interface ICardNonceResponseErrors {
  type: string;
  message: string;
  field: string;
}

export interface IOrderForm {
  initializeOrderFormItemPaymentRequestButton(): void;
  initializeItems(): void;
  submitPayment(data: any, callback: any): void;
  submitPaymentWithConfirmation(data: any, callback: any): void;
  createPaymentMethod(data: any, callback: any): void;
  updateFields(data?: any): void;
  getPostalCode(): string;
  requestCardNonce(): boolean;
  getNonce(): string;
  getRequestCardNonceErrors(): ICardNonceResponseErrors[];
  destroy(): void;
}

export class OrderForm implements IOrderForm {
  public initializeOrderFormItemPaymentRequestButton(): void {
    // do nothing
  }

  public initializeItems(): void {
    // do nothing
  }

  public submitPayment(data: any, callback: any): void {
    // do nothing
  }

  public submitPaymentWithConfirmation(data: any, callback: any): void {
    // do nothing
  }

  public createPaymentMethod(data: any, callback: any): void {
    // do nothing
  }

  public requestCardNonce(): boolean {
    return false;
  }

  public getNonce(): string {
    return '';
  }

  public getRequestCardNonceErrors(): ICardNonceResponseErrors[] {
    return null;
  }

  public updateFields(data: any): void {
    // do nothing
  }

  public getPostalCode(): string {
    return '';
  }

  public destroy(): void {
    // do nothing
  }
}
