export interface IFont {
  text: string;
  value: string;
  GoogleWebFont?: boolean;
  subset?: string;
  data?: string;
}

export const fontsList: IFont[] = [
  {
    text: 'Arial',
    value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
  },
  {
    text: 'Courier',
    value: "'Courier New', Courier, monospace",
  },
  {
    text: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    text: 'Helvetica',
    value: "Helvetica, 'Helvetica Neue', Arial, sans-serif",
  },
  {
    text: 'Tahoma',
    value: 'Tahoma, sans-serif',
  },
  {
    text: 'Times',
    value: "'Times New Roman', Times, serif",
  },
  {
    text: 'Trebuchet',
    value: "Trebuchet, 'Trebuchet MS', Arial, Helvetica, sans-serif",
  },
  {
    text: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Grand Hotel',
    data: '400,400italic,700,700italic',
    value: "'Grand Hotel', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin',
    text: 'Monoton',
    data: '400,400italic,700,700italic',
    value: "'Monoton', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Hanalei',
    data: '400,400italic,700,700italic',
    value: "'Hanalei', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Lobster',
    data: '400,400italic,700,700italic',
    value: "'Lobster', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Changa One',
    data: '400,400italic,700,700italic',
    value: "'Changa One', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Special Elite',
    data: '400,400italic,700,700italic',
    value: "'Special Elite', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Chewy',
    data: '400,400italic,700,700italic',
    value: "'Chewy', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Luckiest Guy',
    data: '400,400italic,700,700italic',
    value: "'Luckiest Guy', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Fredoka One',
    data: '400,400italic,700,700italic',
    value: "'Fredoka One', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Fugaz One',
    data: '400,400italic,700,700italic',
    value: "'Fugaz One', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Sansita One',
    data: '400,400italic,700,700italic',
    value: "'Sansita One', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Pacifico',
    data: '400,400italic,700,700italic',
    value: "'Pacifico', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Merienda One',
    data: '400,400italic,700,700italic',
    value: "'Merienda One', cursive",
  },
  {
    GoogleWebFont: true,
    subset: 'latin,latin-ext',
    text: 'Raleway',
    data: '400,400i,700,700i',
    value: "'Raleway', sans-serif",
  },
];

// Fonty do listy fontów (Select) doładowywane są w pliku prototypes/fonts.js
// Wyglada w ogole ze mogłby być generowany z powyższej listy
