export default {
  initialize: function () {
    /*
            gdy jest ustawiony work-space-Desktop-Width w body landinga należy ustawiec
            w elemencie Preview styl width inaczej landingi szersze niż 1024px są przycinane
        */
    const workspaceDesktopWidth = document.body.dataset.workspaceDesktopWidth;
    const previewEl = document.getElementById('Preview');

    if (this.isWebscreener() && workspaceDesktopWidth) {
      previewEl.style.width = `${workspaceDesktopWidth}px`;
    }
  },
  isWebscreener: function () {
    return window.location.search.includes('W3bScr44ner');
  },
};
