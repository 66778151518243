import cookieHelper from '../../editor/helpers/cookie';

export default {
  initialize: () => {
    var cookieNotificationBox = document.querySelector('[data-editable="boxCookieNotification"]');

    if (!cookieNotificationBox) {
      return;
    }

    if (cookieHelper.get('gr_cookie_notification_clicked')) {
      cookieNotificationBox.parentNode.removeChild(cookieNotificationBox);
      return;
    }

    cookieNotificationBox.style.display = 'block';

    document.querySelector('[data-editable="buttonCookieNotification"] a').addEventListener(
      'click',
      function (e) {
        var href = this.getAttribute('href');

        if (!href || '#' === href) {
          e.preventDefault();
          e.stopPropagation();
        }

        cookieHelper.create('gr_cookie_notification_clicked', true, 999999);

        cookieNotificationBox.parentNode.removeChild(cookieNotificationBox);
      },
      false,
    );
  },
};
