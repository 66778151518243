export default {
  initialize: () => {
    //field blocker is necessary for editor, but not for user on showpage
    //Fix dla FF na dblclick dla elementu input
    var fieldBlockers = Array.prototype.slice.call(
      document.body.querySelectorAll(
        ['[data-name="ff-dbl-click"]', '.field-blocker', '.ff-dbl-click-field'].join(', '),
      ),
    );

    for (var i = 0; i < fieldBlockers.length; i++) {
      fieldBlockers[i].parentNode.removeChild(fieldBlockers[i]);
    }
  },
};
