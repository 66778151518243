import * as $ from 'jquery';
import { OfferFormElement } from './offerFormElement';

import AutoFunnelHelper from '../../helpers/autofunnel';
import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';

import { LPSData } from '../../models/config';

import { ILpsShowInitialData } from '../../../../interfaces/lpsShow';

export class BlueSnapFormElement extends OfferFormElement {
  private cachedUuid: string = null; // Store Uuid to don't create new transactions every time

  public proceed(): void {
    logger.log('Starting BlueSnap process');

    const serializedData = $(this.form).serializeArray();
    const formData = this.getFormRequestData();
    const blueSnapPaymentData = JSON.stringify({
      ...formData,
      products: this.productList.getSelectedProducts(),
      cartId: LPSData.getAutoFunnelCartId(),
      uuid: this.cachedUuid,
    });

    AutoFunnelHelper.prepareProcessPayment(blueSnapPaymentData, (prepareResponse) => {
      // Store Uuid to don't create new transactions every time
      if (prepareResponse.uuid) {
        this.cachedUuid = prepareResponse.uuid;
      }

      if (prepareResponse.error) {
        notify.alert(prepareResponse.error);
        this.enableForm();
      } else if (prepareResponse.client_secret === 'not_required') {
        this.proceedWithSubscription(
          () => {
            // Jesli cena wynosi 0
            AutoFunnelHelper.checkPaymentStatus(prepareResponse.uuid, this.checkPaymentStatusCallback.bind(this));
          },
          null,
          serializedData,
        );
      } else {
        this.orderForm.submitPayment(prepareResponse, (data) => {
          // Clear previous errors
          $('[data-bluesnap]').removeClass('error').removeAttr('data-error-msg');
          if (data.error) {
            data.error.forEach((error) => {
              $(`[data-bluesnap="${error.tagId}"]`).addClass('error').attr('data-error-msg', error.errorDescription);
            });

            this.enableForm();
            this.scrollToError();
          } else if (data.cardData !== null) {
            const blueSnapPaymentDataWithToken = JSON.stringify({
              ...formData,
              products: this.productList.getSelectedProducts(),
              cartId: LPSData.getAutoFunnelCartId(),
              token: (window.grLpsInitialData as ILpsShowInitialData).autoFunnel.paymentProcessorToken,
            });

            AutoFunnelHelper.startProcessPayment(
              blueSnapPaymentDataWithToken,
              LPSData.getProcessPaymentUrl(),
              (paymentResponse) => {
                this.proceedWithSubscription(
                  () => {
                    if (paymentResponse.error) {
                      notify.alert(paymentResponse.error);
                      this.enableForm();
                    } else if (paymentResponse.uuid) {
                      AutoFunnelHelper.checkPaymentStatus(
                        paymentResponse.uuid,
                        this.checkPaymentStatusCallback.bind(this),
                      );
                    } else {
                      this.enableForm();
                    }
                  },
                  null,
                  serializedData,
                );
              },
            );
          }
        });
      }
    });
  }
}
