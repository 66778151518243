export class OtoPageStrategy {
  public proceedPayment(otoPageAcceptUrl: string, productId: string): void {
    // do nothing
  }

  protected getOTOPaymentData(productId: string): string {
    return JSON.stringify({
      products: {
        [productId]: 1,
      },
    });
  }
}
