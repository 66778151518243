import * as $ from 'jquery';

export class Statistics {
  public static visitPage(token: string): void {
    if (token) {
      Statistics.sendEvent(token);
    }
  }

  private static sendEvent(token: string): void {
    $.ajax({
      url: '',
      method: 'PATCH',
      data: JSON.stringify({
        token: token,
      }),
    });
  }
}
