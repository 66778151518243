/* tslint:disable:max-line-length */
export enum TranslationKey {
  ModSqueezePageLPCCreatePageLoading = 'ModSqueezePageLPCCreatePageLoading',
  ModSqueezePageLPCCreatePageDashboardVariation = 'ModSqueezePageLPCCreatePageDashboardVariation',
  ModSqueezePageLPCCreatePageDashboardVariantName = 'ModSqueezePageLPCCreatePageDashboardVariantName',

  ModSqueezePageLPCCreatePageArenaWebformNoneDrop = 'ModSqueezePageLPCCreatePageArenaWebformNoneDrop',
  ModSqueezePageLPCCreatePageArenaSectionNoneDrop = 'ModSqueezePageLPCCreatePageArenaSectionNoneDrop',
  ModSqueezePageLPCCreatePageArenaWebinarNoneDrop = 'ModSqueezePageLPCCreatePageArenaWebinarNoneDrop',
  ModSqueezePageLPCCreatePageArenaProductBoxNoneDrop = 'ModSqueezePageLPCCreatePageArenaProductBoxNoneDrop',
  ModSqueezePageLPCCreatePageArenaAttachToThisBox = 'ModSqueezePageLPCCreatePageArenaAttachToThisBox',

  // History
  ModSqueezePageLPCCreatePageHistoryListHeader = 'ModSqueezePageLPCCreatePageHistoryListHeader',
  ModSqueezePageLPCCreatePageHistoryEditableAdd = 'ModSqueezePageLPCCreatePageHistoryEditableAdd',
  ModSqueezePageLPCCreatePageHistoryEditableRemove = 'ModSqueezePageLPCCreatePageHistoryEditableRemove',
  ModSqueezePageLPCCreatePageHistoryEditableBringToTop = 'ModSqueezePageLPCCreatePageHistoryEditableBringToTop',
  ModSqueezePageLPCCreatePageHistoryEditableBringToBottom = 'ModSqueezePageLPCCreatePageHistoryEditableBringToBottom',
  ModSqueezePageLPCCreatePageHistoryEditableContentChange = 'ModSqueezePageLPCCreatePageHistoryEditableContentChange',
  ModSqueezePageLPCCreatePageHistoryEditablePropertyChange = 'ModSqueezePageLPCCreatePageHistoryEditablePropertyChange',
  ModSqueezePageLPCCreatePageHistoryEditableDuplicate = 'ModSqueezePageLPCCreatePageHistoryEditableDuplicate',
  ModSqueezePageLPCCreatePageHistoryEditableCopy = 'ModSqueezePageLPCCreatePageHistoryEditableCopy',
  ModSqueezePageLPCCreatePageHistoryEditablePaste = 'ModSqueezePageLPCCreatePageHistoryEditablePaste',
  ModSqueezePageLPCCreatePageHistoryLPSLoaded = 'ModSqueezePageLPCCreatePageHistoryLPSLoaded',
  ModSqueezePageLPCCreatePageHistoryEditableMovedPart1 = 'ModSqueezePageLPCCreatePageHistoryEditableMovedPart1',
  ModSqueezePageLPCCreatePageHistoryEditableMovedPart2 = 'ModSqueezePageLPCCreatePageHistoryEditableMovedPart2',
  ModSqueezePageLPCCreatePageHistorySectionMovedUp = 'ModSqueezePageLPCCreatePageHistorySectionMovedUp',
  ModSqueezePageLPCCreatePageHistorySectionMovedDown = 'ModSqueezePageLPCCreatePageHistorySectionMovedDown',
  ModSqueezePageLPCCreatePageHistoryConvertToMobile = 'ModSqueezePageLPCCreatePageHistoryConvertToMobile',
  ModSqueezePageLPCCreatePageHistoryEditableRemoveComponent = 'ModSqueezePageLPCCreatePageHistoryEditableRemoveComponent',
  ModSqueezePageLPCCreatePageHistoryEditableRemoveComponents = 'ModSqueezePageLPCCreatePageHistoryEditableRemoveComponents',

  ModSqueezePageLPCCreatePageEditableName = 'ModSqueezePageLPCCreatePageEditableName',
  ModSqueezePageLPCCreatePageEditableNameArena = 'ModSqueezePageLPCCreatePageEditableNameArena',
  ModSqueezePageLPCCreatePageEditableNameBox = 'ModSqueezePageLPCCreatePageEditableNameBox',
  ModSqueezePageLPCCreatePageEditableNameCircle = 'ModSqueezePageLPCCreatePageEditableNameCircle',
  ModSqueezePageLPCCreatePageEditableNameBoxCookieNotification = 'ModSqueezePageLPCCreatePageEditableNameBoxCookieNotification',
  ModSqueezePageLPCCreatePageEditableNameButton = 'ModSqueezePageLPCCreatePageEditableNameButton',
  ModSqueezePageLPCCreatePageEditableNameButtonCookieNotification = 'ModSqueezePageLPCCreatePageEditableNameButtonCookieNotification',
  ModSqueezePageLPCCreatePageEditableNameHLine = 'ModSqueezePageLPCCreatePageEditableNameHLine',
  ModSqueezePageLPCCreatePageEditableNameImage = 'ModSqueezePageLPCCreatePageEditableNameImage',
  ModSqueezePageLPCCreatePageEditableNamePaypalbutton = 'ModSqueezePageLPCCreatePageEditableNamePaypalbutton',
  ModSqueezePageLPCCreatePageEditableNameSection = 'ModSqueezePageLPCCreatePageEditableNameSection',
  ModSqueezePageLPCCreatePageEditableNameSocial = 'ModSqueezePageLPCCreatePageEditableNameSocial',
  ModSqueezePageLPCCreatePageEditableNameFacebook = 'ModSqueezePageLPCCreatePageEditableNameFacebook',
  ModSqueezePageLPCCreatePageEditableNameTweet = 'ModSqueezePageLPCCreatePageEditableNameTweet',
  ModSqueezePageLPCCreatePageEditableNamePinterest = 'ModSqueezePageLPCCreatePageEditableNamePinterest',
  ModSqueezePageLPCCreatePageEditableNameGoogleplus = 'ModSqueezePageLPCCreatePageEditableNameGoogleplus',
  ModSqueezePageLPCCreatePageEditableNameLinkedin = 'ModSqueezePageLPCCreatePageEditableNameLinkedin',
  ModSqueezePageLPCCreatePageEditableNameText = 'ModSqueezePageLPCCreatePageEditableNameText',
  ModSqueezePageLPCCreatePageEditableNameTextCookieNotification = 'ModSqueezePageLPCCreatePageEditableNameTextCookieNotification',
  ModSqueezePageLPCCreatePageEditableNameVideo = 'ModSqueezePageLPCCreatePageEditableNameVideo',
  ModSqueezePageLPCCreatePageEditableNameVLine = 'ModSqueezePageLPCCreatePageEditableNameVLine',
  ModSqueezePageLPCCreatePageEditableNameWebform = 'ModSqueezePageLPCCreatePageEditableNameWebform',
  ModSqueezePageLPCCreatePageEditableNameWebinar = 'ModSqueezePageLPCCreatePageEditableNameWebinar',
  ModSqueezePageLPCCreatePageEditableNameWebinarButton = 'ModSqueezePageLPCCreatePageEditableNameWebinarButton',
  ModSqueezePageLPCCreatePageEditableNameWebinarText = 'ModSqueezePageLPCCreatePageEditableNameWebinarText',
  ModSqueezePageLPCCreatePageEditableNameCustomCode = 'ModSqueezePageLPCCreatePageEditableNameCustomCode',
  ModSqueezePageLPCCreatePageEditableNameCountdownTimer = 'ModSqueezePageLPCCreatePageEditableNameCountdownTimer',

  ModSqueezePageLPCCreatePageNoMobileWarningTitleSingular = 'ModSqueezePageLPCCreatePageNoMobileWarningTitleSingular',
  ModSqueezePageLPCCreatePageNoMobileWarningTitlePlural = 'ModSqueezePageLPCCreatePageNoMobileWarningTitlePlural',
  ModSqueezePageLPCCreatePageNoMobileWarningDescSingular = 'ModSqueezePageLPCCreatePageNoMobileWarningDescSingular',
  ModSqueezePageLPCCreatePageNoMobileWarningDescPlural = 'ModSqueezePageLPCCreatePageNoMobileWarningDescPlural',
  ModSqueezePageLPCCreatePageNoMobileWarningAnswerOkSingular = 'ModSqueezePageLPCCreatePageNoMobileWarningAnswerOkSingular',
  ModSqueezePageLPCCreatePageNoMobileWarningAnswerOkPlural = 'ModSqueezePageLPCCreatePageNoMobileWarningAnswerOkPlural',
  ModSqueezePageLPCCreatePageNoMobileWarningAnswerCancelSingular = 'ModSqueezePageLPCCreatePageNoMobileWarningAnswerCancelSingular',
  ModSqueezePageLPCCreatePageNoMobileWarningAnswerCancelPlural = 'ModSqueezePageLPCCreatePageNoMobileWarningAnswerCancelPlural',

  ModSqueezePageLPCCreatePageToolBarsSubject = 'ModSqueezePageLPCCreatePageToolBarsSubject',
  ModSqueezePageLPCCreatePageToolBarsTitle = 'ModSqueezePageLPCCreatePageToolBarsTitle',
  ModSqueezePageLPCCreatePageToolBarsSection = 'ModSqueezePageLPCCreatePageToolBarsSection',
  ModSqueezePageLPCCreatePageToolBarsTarget = 'ModSqueezePageLPCCreatePageToolBarsTarget',

  ModSqueezePageLPCCreatePageToolBarsOpenInNewWindow = 'ModSqueezePageLPCCreatePageToolBarsOpenInNewWindow',
  ModSqueezePageLPCCreatePageToolBarsOpenInSameWindow = 'ModSqueezePageLPCCreatePageToolBarsOpenInSameWindow',

  ModSqueezePageLPCCreatePageToolBarsWebAddress = 'ModSqueezePageLPCCreatePageToolBarsWebAddress',
  ModSqueezePageLPCCreatePageToolBarsSelectPhoneOption = 'ModSqueezePageLPCCreatePageToolBarsSelectPhoneOption',
  ModSqueezePageLPCCreatePageToolBarsPhoneLabel = 'ModSqueezePageLPCCreatePageToolBarsPhoneLabel',
  ModSqueezePageLPCCreatePageToolBarsPhonePlaceholder = 'ModSqueezePageLPCCreatePageToolBarsPhonePlaceholder',

  ModSqueezePageLPCCreatePageToolBarsSelectWebinarOption = 'ModSqueezePageLPCCreatePageToolBarsSelectWebinarOption',
  ModSqueezePageLPCCreatePageToolBarsSelectWebinarNameOption = 'ModSqueezePageLPCCreatePageToolBarsSelectWebinarNameOption',
  ModSqueezePageLPCCreatePageToolBarsWebinarLabel = 'ModSqueezePageLPCCreatePageToolBarsWebinarLabel',
  ModSqueezePageLPCCreatePageToolBarsWebinarPlaceholder = 'ModSqueezePageLPCCreatePageToolBarsWebinarPlaceholder',

  ModSqueezePageLPCCreatePageToolBarsSelectSectionOption = 'ModSqueezePageLPCCreatePageToolBarsSelectSectionOption',
  ModSqueezePageLPCCreatePageToolBarsSelectJoinTheProgramOption = 'ModSqueezePageLPCCreatePageToolBarsSelectJoinTheProgramOption',
  ModSqueezePageLPCCreatePageToolBarsSelectProductLinkOption = 'ModSqueezePageLPCCreatePageToolBarsSelectProductLinkOption',
  ModSqueezePageLPCCreatePageToolBarsSelectLeadMagnetProductLinkOption = 'ModSqueezePageLPCCreatePageToolBarsSelectLeadMagnetProductLinkOption',
  ModSqueezePageLPCCreatePageToolBarsSelectOTOPageDismissOption = 'ModSqueezePageLPCCreatePageToolBarsSelectOTOPageDismissOption',
  ModSqueezePageLPCCreatePageToolBarsSelectOTOPageAcceptOption = 'ModSqueezePageLPCCreatePageToolBarsSelectOTOPageAcceptOption',

  ModSqueezePageLPCCreatePageToolDragDropAttachToThis = 'ModSqueezePageLPCCreatePageToolDragDropAttachToThis',
  ModSqueezePageLPCCreatePageToolDragDropAttachTo = 'ModSqueezePageLPCCreatePageToolDragDropAttachTo',

  ModSqueezePageLPCCreatePageHtmlCodeAlertText = 'ModSqueezePageLPCCreatePageHtmlCodeAlertText',
  ModSqueezePageLPCCreatePageHtmlCodeAlertTextSure = 'ModSqueezePageLPCCreatePageHtmlCodeAlertTextSure',
  ModSqueezePageLPCCreatePageHtmlCodeNoThanks = 'ModSqueezePageLPCCreatePageHtmlCodeNoThanks',
  ModSqueezePageLPCCreatePageHtmlCodeYesContinue = 'ModSqueezePageLPCCreatePageHtmlCodeYesContinue',
  ModSqueezePageLPCCreateLabelOK = 'ModSqueezePageLPCCreateLabelOK',

  ModSqueezePageLPCCreatePageDefaultCampaignDialogHeader = 'ModSqueezePageLPCCreatePageDefaultCampaignDialogHeader',
  ModSqueezePageLPCCreatePageDefaultCampaignDialogDescription = 'ModSqueezePageLPCCreatePageDefaultCampaignDialogDescription',

  ModSqueezePageLPCCreatePageMobileCompositionHeader = 'ModSqueezePageLPCCreatePageMobileCompositionHeader',
  ModSqueezePageLPCCreatePageMobileCompositionDialogDescription = 'ModSqueezePageLPCCreatePageMobileCompositionDialogDescription',
  ModSqueezePageLPCCreatePageMobileCompositionOK = 'ModSqueezePageLPCCreatePageMobileCompositionOK',
  ModSqueezePageLPCCreatePageMobileCompositionCancel = 'ModSqueezePageLPCCreatePageMobileCompositionCancel',

  ModSqueezePageLPCCreatePageWebformWebformFields = 'ModSqueezePageLPCCreatePageWebformWebformFields',
  ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField = 'ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField',
  ModSqueezePageLPCCreatePageWebinarWebinarFields = 'ModSqueezePageLPCCreatePageWebinarWebinarFields',
  ModSqueezePageLPCCreatePageCountdownTimerEditButton = 'ModSqueezePageLPCCreatePageCountdownTimerEditButton',

  ModSqueezePageLPCCreatePageProductListEditButton = 'ModSqueezePageLPCCreatePageProductListEditButton',
  ModSqueezePageLPCCreatePageProductsBoxEditButton = 'ModSqueezePageLPCCreatePageProductsBoxEditButton',

  ModSqueezePageLPCCreatePageProductsBoxQuantity = 'ModSqueezePageLPCCreatePageProductsBoxQuantity',
  ModSqueezePageLPCFunnelConfirmationPageQuantity = 'ModSqueezePageLPCFunnelConfirmationPageQuantity',

  // Editable
  ModSqueezePageLPCCreatePageToolBarsTextPlaceholder = 'ModSqueezePageLPCCreatePageToolBarsTextPlaceholder',

  // Dashboard components
  ModSqueezePageLpcCreatePageHtmlEditor = 'ModSqueezePageLpcCreatePageHtmlEditor',
  ModSqueezePageLpcCreatePageSettings = 'ModSqueezePageLpcCreatePageSettings',
  ModSqueezePageLpcCreatePageAbTestLimit = 'ModSqueezePageLpcCreatePageAbTestLimit',
  ModSqueezePageLpcSaveAsTemplateBtnSave = 'ModSqueezePageLpcSaveAsTemplateBtnSave',
  ModSqueezePageLpcCreatePageSaveAndExit = 'ModSqueezePageLpcCreatePageSaveAndExit',
  ModSqueezePageLpcCreatePageNextStep = 'ModSqueezePageLpcCreatePageNextStep',
  ModSqueezePageLpcCreatePageShowHiddenComponents = 'ModSqueezePageLpcCreatePageShowHiddenComponents',
  ModSqueezePageLpcCreatePageHideHiddenComponents = 'ModSqueezePageLpcCreatePageHideHiddenComponents',
  ModSqueezePageLpcCreatePageSaveAsTemplate = 'ModSqueezePageLpcCreatePageSaveAsTemplate',
  ModSqueezePageLpcCreatePagePreview = 'ModSqueezePageLpcCreatePagePreview',
  ModSqueezePageLpcCreatePageLayoutAssistant = 'ModSqueezePageLpcCreatePageLayoutAssistant',
  ModSqueezePageLpcCreatePageMobileChangesTooltip = 'ModSqueezePageLpcCreatePageMobileChangesTooltip',
  ModSqueezePageLpcCreatePageDesktopChangesTooltip = 'ModSqueezePageLpcCreatePageDesktopChangesTooltip',

  ModSqueezePageLpcCreatePageDesktopPreview = 'ModSqueezePageLpcCreatePageDesktopPreview',
  ModSqueezePageLpcCreatePagePhonePreview = 'ModSqueezePageLpcCreatePagePhonePreview',

  // Welcome Screen
  ModSqueezePageLpcWelcomeScreenHeader = 'ModSqueezePageLpcWelcomeScreenHeader',
  ModSqueezePageLpcWelcomeScreenText1 = 'ModSqueezePageLpcWelcomeScreenText1',
  ModSqueezePageLpcWelcomeScreenText2 = 'ModSqueezePageLpcWelcomeScreenText2',
  ModSqueezePageLpcWelcomeScreenText3 = 'ModSqueezePageLpcWelcomeScreenText3',
  ModSqueezePageLpcWelcomeScreenText4 = 'ModSqueezePageLpcWelcomeScreenText4',
  ModSqueezePageLpcWelcomeScreenText5 = 'ModSqueezePageLpcWelcomeScreenText5',
  ModSqueezePageLpcWelcomeScreenText6 = 'ModSqueezePageLpcWelcomeScreenText6',
  ModSqueezePageLpcWelcomeScreenLinkToWiki = 'ModSqueezePageLpcWelcomeScreenLinkToWiki',
  ModSqueezePageLpcWelcomeScreenButtonOk = 'ModSqueezePageLpcWelcomeScreenButtonOk',
  ModSqueezePageLpcWelcomeScreenCbxDontShowAgain = 'ModSqueezePageLpcWelcomeScreenCbxDontShowAgain',

  ModSqueezePageLpcSaveAsTemplateHeader = 'ModSqueezePageLpcSaveAsTemplateHeader',
  ModSqueezePageLpcSaveAsTemplateInputPlaceholder = 'ModSqueezePageLpcSaveAsTemplateInputPlaceholder',
  ModSqueezePageLpcSaveAsTemplateDescr = 'ModSqueezePageLpcSaveAsTemplateDescr',
  ModSqueezePageLpcSaveAsTemplateOffer = 'ModSqueezePageLpcSaveAsTemplateOffer',
  ModSqueezePageLpcSaveAsTemplateLanding = 'ModSqueezePageLpcSaveAsTemplateLanding',
  ModSqueezePageLpcSaveAsTemplateConfirm = 'ModSqueezePageLpcSaveAsTemplateConfirm',
  ModSqueezePageLpcSaveAsTemplateOto = 'ModSqueezePageLpcSaveAsTemplateOto',
  ModSqueezePageLpcSaveAsTemplateThankyou = 'ModSqueezePageLpcSaveAsTemplateThankyou',
  ModSqueezePageLpcSaveAsTemplateBtnCancel = 'ModSqueezePageLpcSaveAsTemplateBtnCancel',
  ModSqueezePageLpcSaveAsTemplateBtnSaveAndExit = 'ModSqueezePageLpcSaveAsTemplateBtnSaveAndExit',

  // Recreate mobile view Screen
  ModSqueezePageLPCCreatePageRefreshMobileHeader = 'ModSqueezePageLPCCreatePageRefreshMobileHeader',
  ModSqueezePageLPCCreatePageRefreshMobileParagraph1 = 'ModSqueezePageLPCCreatePageRefreshMobileParagraph1',
  ModSqueezePageLPCCreatePageRefreshMobileParagraph2 = 'ModSqueezePageLPCCreatePageRefreshMobileParagraph2',
  ModSqueezePageLPCCreatePageRefreshMobileDontShowAgain = 'ModSqueezePageLPCCreatePageRefreshMobileDontShowAgain',
  ModSqueezePageLPCCreatePageRefreshMobileConfirmButton = 'ModSqueezePageLPCCreatePageRefreshMobileConfirmButton',
  ModSqueezePageLPCCreatePageRefreshMobileCancelButton = 'ModSqueezePageLPCCreatePageRefreshMobileCancelButton',

  // Webinar modal
  ModSqueezePageLPCCreatePageWebinarModalTitle = 'ModSqueezePageLPCCreatePageWebinarModalTitle',
  ModSqueezePageLPCCreatePageWebinarModalConfirm = 'ModSqueezePageLPCCreatePageWebinarModalConfirm',
  ModSqueezePageLPCCreatePageWebinarModalCancel = 'ModSqueezePageLPCCreatePageWebinarModalCancel',
  ModSqueezePageLPCCreatePageWebinarModalButtonCancel = 'ModSqueezePageLPCCreatePageWebinarModalButtonCancel',

  ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinars = 'ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinars',
  ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsSubtitle = 'ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsSubtitle',
  ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsConfirm = 'ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsConfirm',
  ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsCancel = 'ModSqueezePageLPCCreatePageWebinarModalNoActiveWebinarsCancel',

  ModSqueezePageLPCCreatePageWebinarModalNoWebinarsService = 'ModSqueezePageLPCCreatePageWebinarModalNoWebinarsService',
  ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceSubtitle = 'ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceSubtitle',
  ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceConfirm = 'ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceConfirm',
  ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceCancel = 'ModSqueezePageLPCCreatePageWebinarModalNoWebinarsServiceCancel',

  // WebformAppModal
  ModSqueezePageLPCWebformAppModalChooseTypeTitle = 'ModSqueezePageLPCWebformAppModalChooseTypeTitle',
  ModSqueezePageLPCWebformAppModalChooseTypeItemDownloadBox = 'ModSqueezePageLPCWebformAppModalChooseTypeItemDownloadBox',
  ModSqueezePageLPCWebformAppModalChooseTypeItemOnExit = 'ModSqueezePageLPCWebformAppModalChooseTypeItemOnExit',
  ModSqueezePageLPCWebformAppModalChooseTypeItemFixedBar = 'ModSqueezePageLPCWebformAppModalChooseTypeItemFixedBar',
  ModSqueezePageLPCWebformAppModalChooseTypeItemImageBox = 'ModSqueezePageLPCWebformAppModalChooseTypeItemImageBox',
  ModSqueezePageLPCWebformAppModalChooseTypeItemListBuilderBox = 'ModSqueezePageLPCWebformAppModalChooseTypeItemListBuilderBox',
  ModSqueezePageLPCWebformAppModalChooseTypeItemScrollBox = 'ModSqueezePageLPCWebformAppModalChooseTypeItemScrollBox',

  // Webfrom custom fields modal
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTitle = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTitle',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalConfirm = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalConfirm',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalCancel = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalCancel',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalRequiredLabel = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalRequiredLabel',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn1Title = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn1Title',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn2Title = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn2Title',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn3Title = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn3Title',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn4Title = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn4Title',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn5Title = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn5Title',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn6Title = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalColumn6Title',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabCustomFields = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabCustomFields',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabOrderFormFields = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabOrderFormFields',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsents = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsents',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsNoData = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsNoData',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsLearnHowToDoIt = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsLearnHowToDoIt',
  ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsLearnHowToDoItLink = 'ModSqueezePageLPCCreatePageWebformCustomFieldsModalTabConsentsLearnHowToDoItLink',

  // AB test feature limitation modal
  ModSqueezePageLPCCreatePageDashboardAddVariant = 'ModSqueezePageLPCCreatePageDashboardAddVariant',
  ModSqueezePageLPCAbTestFeatureModalTitle = 'ModSqueezePageLPCAbTestFeatureModalTitle',
  ModSqueezePageLPCAbTestFeatureModalDescrIntro = 'ModSqueezePageLPCAbTestFeatureModalDescrIntro',
  ModSqueezePageLPCAbTestFeatureModalDescrList = 'ModSqueezePageLPCAbTestFeatureModalDescrList',
  ModSqueezePageLPCAbTestFeatureModalButtonConfirm = 'ModSqueezePageLPCAbTestFeatureModalButtonConfirm',
  ModSqueezePageLPCAbTestFeatureModalButtonCancel = 'ModSqueezePageLPCAbTestFeatureModalButtonCancel',

  // Blockbar
  ModSqueezePageLPCCreatePageToolBarsDropElemenSection = 'ModSqueezePageLPCCreatePageToolBarsDropElemenSection',
  ModSqueezePageLpcCreatePageNewFeatureBadge = 'ModSqueezePageLpcCreatePageNewFeatureBadge',
  ModSqueezePageLPCCreatePageToolBarsSectionModuleInfo = 'ModSqueezePageLPCCreatePageToolBarsSectionModuleInfo',
  ModSqueezePageLPCCreatePageToolBarsDropElemenWebinar = 'ModSqueezePageLPCCreatePageToolBarsDropElemenWebinar',
  ModSqueezePageLPCCreatePageToolBarsNoActiveWebinars = 'ModSqueezePageLPCCreatePageToolBarsNoActiveWebinars',
  ModSqueezePageLPCCreatePageToolBarsNoWebinarsService = 'ModSqueezePageLPCCreatePageToolBarsNoWebinarsService',
  ModSqueezePageLPCCreatePageToolBarsDropElemenText = 'ModSqueezePageLPCCreatePageToolBarsDropElemenText',
  ModSqueezePageLPCCreatePageToolBarsDropElemenImage = 'ModSqueezePageLPCCreatePageToolBarsDropElemenImage',
  ModSqueezePageLPCCreatePageToolBarsDropElemenButton = 'ModSqueezePageLPCCreatePageToolBarsDropElemenButton',
  ModSqueezePageLPCCreatePageToolBarsDropElemenWebform = 'ModSqueezePageLPCCreatePageToolBarsDropElemenWebform',
  ModSqueezePageLPCCreatePageToolBarsDropElemenWebformApp = 'ModSqueezePageLPCCreatePageToolBarsDropElemenWebformApp',
  ModSqueezePageLPCCreatePageToolBarsDropElemenBox = 'ModSqueezePageLPCCreatePageToolBarsDropElemenBox',
  ModSqueezePageLPCCreatePageToolBarsDropElemenCountdown = 'ModSqueezePageLPCCreatePageToolBarsDropElemenCountdown',
  ModSqueezePageLPCCreatePageToolBarsDropElemenCircle = 'ModSqueezePageLPCCreatePageToolBarsDropElemenCircle',
  ModSqueezePageLPCCreatePageToolBarsDropElemenVideo = 'ModSqueezePageLPCCreatePageToolBarsDropElemenVideo',
  ModSqueezePageLPCCreatePageToolBarsDropElementProductsBox = 'ModSqueezePageLPCCreatePageToolBarsDropElementProductsBox',
  ModSqueezePageLPCCreatePageToolBarsSocialMediaList = 'ModSqueezePageLPCCreatePageToolBarsSocialMediaList',
  ModSqueezePageLPCCreatePageToolBarsShapesList = 'ModSqueezePageLPCCreatePageToolBarsShapesList',
  ModSqueezePageLPCCreatePageToolBarsSocialMediaFacebook = 'ModSqueezePageLPCCreatePageToolBarsSocialMediaFacebook',
  ModSqueezePageLPCCreatePageToolBarsSocialMediaTweet = 'ModSqueezePageLPCCreatePageToolBarsSocialMediaTweet',
  ModSqueezePageLPCCreatePageToolBarsSocialMediaPintIt = 'ModSqueezePageLPCCreatePageToolBarsSocialMediaPintIt',
  ModSqueezePageLPCCreatePageToolBarsSocialMediaGooglePlus = 'ModSqueezePageLPCCreatePageToolBarsSocialMediaGooglePlus',
  ModSqueezePageLPCCreatePageToolBarsSocialMediaLinkedIn = 'ModSqueezePageLPCCreatePageToolBarsSocialMediaLinkedIn',
  ModSqueezePageLPCCreatePagePayPalList = 'ModSqueezePageLPCCreatePagePayPalList',
  ModSqueezePageLPCCreatePageToolBarsDropElemenHLine = 'ModSqueezePageLPCCreatePageToolBarsDropElemenHLine',
  ModSqueezePageLPCCreatePageToolBarsDropElemenVLine = 'ModSqueezePageLPCCreatePageToolBarsDropElemenVLine',
  ModSqueezePageLPCCreatePageToolBarsDropElemenCustomCode = 'ModSqueezePageLPCCreatePageToolBarsDropElemenCustomCode',
  ModSqueezePageLPCCreatePageToolBarsWebformsList = 'ModSqueezePageLPCCreatePageToolBarsWebformsList',
  ModSqueezePageLPCCreatePageToolBarsWebformsListWebformApp = 'ModSqueezePageLPCCreatePageToolBarsWebformsListWebformApp',
  ModSqueezePageLPCCreatePageToolBarsWebformsListWebformNew = 'ModSqueezePageLPCCreatePageToolBarsWebformsListWebformNew',
  ModSqueezePageLPCCreatePageToolBarsInactiveElementOnThp = 'ModSqueezePageLPCCreatePageToolBarsInactiveElementOnThp',
  ModSqueezePageLPCCreatePageToolBarsInactiveWebinarElementOnThp = 'ModSqueezePageLPCCreatePageToolBarsInactiveWebinarElementOnThp',
  ModSqueezePageLPCCreatePageToolBarsContactEnterpriseAccountOwner = 'ModSqueezePageLPCCreatePageToolBarsContactEnterpriseAccountOwner',
  ModSqueezePageLPCCreatePageToolBarsContactEnterpriseAccountManager = 'ModSqueezePageLPCCreatePageToolBarsContactEnterpriseAccountManager',

  // Toolbar
  ModSqueezePageLPCCreatePageToolBarsButtonColor = 'ModSqueezePageLPCCreatePageToolBarsButtonColor',
  ModSqueezePageLPCCreatePageToolBarsBorderStyle = 'ModSqueezePageLPCCreatePageToolBarsBorderStyle',
  ModSqueezePageLPCCreatePageToolBarsBorderNone = 'ModSqueezePageLPCCreatePageToolBarsBorderNone',
  ModSqueezePageLPCCreatePageToolBarsBorderSolid = 'ModSqueezePageLPCCreatePageToolBarsBorderSolid',
  ModSqueezePageLPCCreatePageToolBarsBorderDashed = 'ModSqueezePageLPCCreatePageToolBarsBorderDashed',
  ModSqueezePageLPCCreatePageToolBarsBorderDotted = 'ModSqueezePageLPCCreatePageToolBarsBorderDotted',
  ModSqueezePageLPCCreatePageToolBarsBorderSize = 'ModSqueezePageLPCCreatePageToolBarsBorderSize',
  ModSqueezePageLPCCreatePageToolBarsCookieNotificationTop = 'ModSqueezePageLPCCreatePageToolBarsCookieNotificationTop',
  ModSqueezePageLPCCreatePageToolBarsCookieNotificationBottom = 'ModSqueezePageLPCCreatePageToolBarsCookieNotificationBottom',
  ModSqueezePageLPCCreatePageToolBarsYouVideoURL = 'ModSqueezePageLPCCreatePageToolBarsYouVideoURL',
  ModSqueezePageLPCCreatePageToolBarsUpload = 'ModSqueezePageLPCCreatePageToolBarsUpload',
  ModSqueezePageLPCCreatePageToolBarsControlBar = 'ModSqueezePageLPCCreatePageToolBarsControlBar',
  ModSqueezePageLPCCreatePageToolBarsShow = 'ModSqueezePageLPCCreatePageToolBarsShow',
  ModSqueezePageLPCCreatePageToolBarsHide = 'ModSqueezePageLPCCreatePageToolBarsHide',
  ModSqueezePageLPCCreatePageToolBarsAutoplay = 'ModSqueezePageLPCCreatePageToolBarsAutoplay',
  ModSqueezePageLPCCreatePageToolBarsLoop = 'ModSqueezePageLPCCreatePageToolBarsLoop',
  ModSqueezePageLPCCreatePageToolBarsShowTitleBar = 'ModSqueezePageLPCCreatePageToolBarsShowTitleBar',
  ModSqueezePageLPCCreatePageToolBarsShowRelatedVideos = 'ModSqueezePageLPCCreatePageToolBarsShowRelatedVideos',
  ModSqueezePageLPCCreatePageToolBarsCornersRadius = 'ModSqueezePageLPCCreatePageToolBarsCornersRadius',
  ModSqueezePageLPCCreatePageToolBarsShadowColor = 'ModSqueezePageLPCCreatePageToolBarsShadowColor',
  ModSqueezePageLPCCreatePageToolBarsDirection = 'ModSqueezePageLPCCreatePageToolBarsDirection',
  ModSqueezePageLPCCreatePageToolBarsDistance = 'ModSqueezePageLPCCreatePageToolBarsDistance',
  ModSqueezePageLPCCreatePageToolBarsBlur = 'ModSqueezePageLPCCreatePageToolBarsBlur',
  ModSqueezePageLPCCreatePageToolBarsSpread = 'ModSqueezePageLPCCreatePageToolBarsSpread',

  ModSqueezePageLPCCreatePageToolBarsBackgroundImage = 'ModSqueezePageLPCCreatePageToolBarsBackgroundImage',
  ModSqueezePageLPCCreatePageToolBarsTiling = 'ModSqueezePageLPCCreatePageToolBarsTiling',
  ModSqueezePageLPCCreatePageToolBarsNoRepeat = 'ModSqueezePageLPCCreatePageToolBarsNoRepeat',
  ModSqueezePageLPCCreatePageToolBarsHorizontal = 'ModSqueezePageLPCCreatePageToolBarsHorizontal',
  ModSqueezePageLPCCreatePageToolBarsVertical = 'ModSqueezePageLPCCreatePageToolBarsVertical',
  ModSqueezePageLPCCreatePageToolBarsBoth = 'ModSqueezePageLPCCreatePageToolBarsBoth',
  ModSqueezePageLPCCreatePageToolBarsScale = 'ModSqueezePageLPCCreatePageToolBarsScale',
  ModSqueezePageLPCCreatePageToolBarsFitScreen = 'ModSqueezePageLPCCreatePageToolBarsFitScreen',
  ModSqueezePageLPCCreatePageToolBarsPosition = 'ModSqueezePageLPCCreatePageToolBarsPosition',
  ModSqueezePageLPCCreatePageToolBarsChooseImage = 'ModSqueezePageLPCCreatePageToolBarsChooseImage',
  ModSqueezePageLPCCreatePageToolBarsBackgroundColor = 'ModSqueezePageLPCCreatePageToolBarsBackgroundColor',
  ModSqueezePageLPCCreatePageToolBarsDefault = 'ModSqueezePageLPCCreatePageToolBarsDefault',
  ModSqueezePageLPCCreatePageToolBarsSelect = 'ModSqueezePageLPCCreatePageToolBarsSelect',
  ModSqueezePageLPCCreatePageChangeImageFailure = 'ModSqueezePageLPCCreatePageChangeImageFailure',

  ModSqueezePageLPCCreatePageToolBarsColumnCountLabel = 'ModSqueezePageLPCCreatePageToolBarsColumnCountLabel',
  ModSqueezePageLPCCreatePageToolBarsColumnCount1Column = 'ModSqueezePageLPCCreatePageToolBarsColumnCount1Column',
  ModSqueezePageLPCCreatePageToolBarsColumnCount2Column = 'ModSqueezePageLPCCreatePageToolBarsColumnCount2Column',
  ModSqueezePageLPCCreatePageToolBarsColumnCount3Column = 'ModSqueezePageLPCCreatePageToolBarsColumnCount3Column',

  // Components
  ModSqueezePageLPCCreatePageToolBarsWebinarJoinNow = 'ModSqueezePageLPCCreatePageToolBarsWebinarJoinNow',
  ModSqueezePageLPCCreatePageToolBarsWebinarNotFound = 'ModSqueezePageLPCCreatePageToolBarsWebinarNotFound',
  ModSqueezePageLPCCreatePageArenaEdit = 'ModSqueezePageLPCCreatePageArenaEdit',
  ModSqueezePageLPCCreatePageArenaReplace = 'ModSqueezePageLPCCreatePageArenaReplace',

  ModSqueezePageLPCCreatePageLeavePage = 'ModSqueezePageLPCCreatePageLeavePage',
  ModSqueezePageLPCCreatePageCookieNotificationTitleTextPlaceholder = 'ModSqueezePageLPCCreatePageCookieNotificationTitleTextPlaceholder',
  ModSqueezePageLPCCreatePageCookieNotificationTextPlaceholder = 'ModSqueezePageLPCCreatePageCookieNotificationTextPlaceholder',
  ModSqueezePageLPCCreatePageCookieNotificationCloseButton = 'ModSqueezePageLPCCreatePageCookieNotificationCloseButton',

  ModSqueezePageLPCCreatePageToolBarsSignUpNow = 'ModSqueezePageLPCCreatePageToolBarsSignUpNow',
  ModSqueezePageLPCCreatePageToolBarsPayPalBuyNow = 'ModSqueezePageLPCCreatePageToolBarsPayPalBuyNow',
  ModSqueezePageLPCCreatePageWebFormLabelName = 'ModSqueezePageLPCCreatePageWebFormLabelName',
  ModSqueezePageLPCCreatePageWebFormLabelEmail = 'ModSqueezePageLPCCreatePageWebFormLabelEmail',
  ModSqueezePageLPCCreatePageWebFormButton = 'ModSqueezePageLPCCreatePageWebFormButton',
  ModSqueezePageLPCCreatePageWebFormSelect = 'ModSqueezePageLPCCreatePageWebFormSelect',

  ModSqueezePageLPCCreatePageWebinarTitle = 'ModSqueezePageLPCCreatePageWebinarTitle',

  ModSqueezePageLPCCreatePageHtmlCodeNextStep = 'ModSqueezePageLPCCreatePageHtmlCodeNextStep',
  ModSqueezePageLPCCreatePageHtmlCodeClose = 'ModSqueezePageLPCCreatePageHtmlCodeClose',
  ModSqueezePageLPCCreatePageHtmlCodeSaveAndPublish = 'ModSqueezePageLPCCreatePageHtmlCodeSaveAndPublish',
  ModSqueezePageLPCCreatePageHtmlCodeSaveAndExit = 'ModSqueezePageLPCCreatePageHtmlCodeSaveAndExit',
  ModSqueezePageLpcCreatePageIEInfo = 'ModSqueezePageLpcCreatePageIEInfo',
  ModSqueezePageLPCCreatePagePayPalNoIntegrationsMsg = 'ModSqueezePageLPCCreatePagePayPalNoIntegrationsMsg',
  ModSqueezePageLPCCreatePagePayPalPleaseWait = 'ModSqueezePageLPCCreatePagePayPalPleaseWait',
  ModSqueezePageLPCCreatePagePayPalSelectTheLink = 'ModSqueezePageLPCCreatePagePayPalSelectTheLink',
  ModSqueezePageLPCCreatePagePayPalError = 'ModSqueezePageLPCCreatePagePayPalError',
  ModSqueezePageLPCCreatePagePayPalNoButtons = 'ModSqueezePageLPCCreatePagePayPalNoButtons',
  ModSqueezePageLPCCreatePageWebFormLabelLastName = 'ModSqueezePageLPCCreatePageWebFormLabelLastName',
  ModSqueezePageLPCCreatePageWebFormLabelFirstName = 'ModSqueezePageLPCCreatePageWebFormLabelFirstName',
  ModSqueezePageLPCCreatePageToolBarsType = 'ModSqueezePageLPCCreatePageToolBarsType',
  ModSqueezePageLPCCreatePageToolBarsLinkTo = 'ModSqueezePageLPCCreatePageToolBarsLinkTo',
  ModSqueezePageLPCCreatePageToolBarsEGWWWGetresponseCom = 'ModSqueezePageLPCCreatePageToolBarsEGWWWGetresponseCom',
  ModSqueezePageLPCCreatePageToolBarsEmail = 'ModSqueezePageLPCCreatePageToolBarsEmail',
  ModSqueezePageLPCCreatePageToolBarsEGEmailGetresponseCom = 'ModSqueezePageLPCCreatePageToolBarsEGEmailGetresponseCom',
  ModSqueezePageLPCCreatePageToolBarsPayPalButtonUrlPlaceholder = 'ModSqueezePageLPCCreatePageToolBarsPayPalButtonUrlPlaceholder',
  ModSqueezePageLPCCreatePageToolBarsLinkNormal = 'ModSqueezePageLPCCreatePageToolBarsLinkNormal',
  ModSqueezePageLPCCreatePageToolBarsLinkHover = 'ModSqueezePageLPCCreatePageToolBarsLinkHover',
  ModSqueezePageLPCCreatePageColorPickerSolidColor = 'ModSqueezePageLPCCreatePageColorPickerSolidColor',
  ModSqueezePageLPCCreatePageColorPickerGradient = 'ModSqueezePageLPCCreatePageColorPickerGradient',
  ModSqueezePageLPCCreatePageColorPickerImage = 'ModSqueezePageLPCCreatePageColorPickerImage',
  ModSqueezePageLPCCreatePageToolBarsPleaseFillInAValidYouTubeURL = 'ModSqueezePageLPCCreatePageToolBarsPleaseFillInAValidYouTubeURL',
  ModSqueezePageLPCCreatePageToolBarsAvailableVideoProvidersInfo = 'ModSqueezePageLPCCreatePageToolBarsAvailableVideoProvidersInfo',
  ModSqueezePageLPCCreatePageCustomCodePlaceHolderText = 'ModSqueezePageLPCCreatePageCustomCodePlaceHolderText',
  ModSqueezePageLPCCreatePageCustomCodeDoubleClickText = 'ModSqueezePageLPCCreatePageCustomCodeDoubleClickText',
  ModSqueezePageLPCCreatePageCustomCodeWindowTitle = 'ModSqueezePageLPCCreatePageCustomCodeWindowTitle',
  ModSqueezePageLPCCreatePageCustomCodeWindowTitleNoContent = 'ModSqueezePageLPCCreatePageCustomCodeWindowTitleNoContent',
  ModSqueezePageLPCCreatePageCustomCodeWindowInfoText = 'ModSqueezePageLPCCreatePageCustomCodeWindowInfoText',

  ModSqueezePageLPCCreatePageCustomCodeWindowConfirm = 'ModSqueezePageLPCCreatePageCustomCodeWindowConfirm',
  ModSqueezePageLPCCreatePageCustomCodeWindowCancel = 'ModSqueezePageLPCCreatePageCustomCodeWindowCancel',

  ModSqueezePageLPCCreatePageWebformAppPlaceHolderText = 'ModSqueezePageLPCCreatePageWebformAppPlaceHolderText',
  ModSqueezePageLPCCreatePageWebformAppDoubleClickText = 'ModSqueezePageLPCCreatePageWebformAppDoubleClickText',

  ModSqueezePageLPCCreatePageWebformAppSettingsDownloadBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsDownloadBox',
  ModSqueezePageLPCCreatePageWebformAppSettingsFixedBar = 'ModSqueezePageLPCCreatePageWebformAppSettingsFixedBar',
  ModSqueezePageLPCCreatePageWebformAppSettingsImageBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsImageBox',
  ModSqueezePageLPCCreatePageWebformAppSettingsListBuilderBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsListBuilderBox',
  ModSqueezePageLPCCreatePageWebformAppSettingsNoExit = 'ModSqueezePageLPCCreatePageWebformAppSettingsNoExit',
  ModSqueezePageLPCCreatePageWebformAppSettingsOnExitSubtitle = 'ModSqueezePageLPCCreatePageWebformAppSettingsOnExitSubtitle',
  ModSqueezePageLPCCreatePageWebformAppSettingsScrollBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsScrollBox',

  ModSqueezePageLPCCreatePageWebformAppSettingsEditDownloadBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditDownloadBox',
  ModSqueezePageLPCCreatePageWebformAppSettingsEditFixedBar = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditFixedBar',
  ModSqueezePageLPCCreatePageWebformAppSettingsEditImageBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditImageBox',
  ModSqueezePageLPCCreatePageWebformAppSettingsEditListBuilderBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditListBuilderBox',
  ModSqueezePageLPCCreatePageWebformAppSettingsEditNoExit = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditNoExit',
  ModSqueezePageLPCCreatePageWebformAppSettingsEditOnExitSubtitle = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditOnExitSubtitle',
  ModSqueezePageLPCCreatePageWebformAppSettingsEditScrollBox = 'ModSqueezePageLPCCreatePageWebformAppSettingsEditScrollBox',

  // CountdownTimerModal
  ModSqueezePageLPCCountdownTimerModalTitle = 'ModSqueezePageLPCCountdownTimerModalTitle',
  ModSqueezePageLPCCountdownTimerModalCounterTypeLabel = 'ModSqueezePageLPCCountdownTimerModalCounterTypeLabel',
  ModSqueezePageLPCCountdownTimerModalCounterTypeStandard = 'ModSqueezePageLPCCountdownTimerModalCounterTypeStandard',
  ModSqueezePageLPCCountdownTimerModalCounterTypeEvergreen = 'ModSqueezePageLPCCountdownTimerModalCounterTypeEvergreen',
  ModSqueezePageLPCCountdownTimerModalTimePickerDaysLabel = 'ModSqueezePageLPCCountdownTimerModalTimePickerDaysLabel',
  ModSqueezePageLPCCountdownTimerModalTimePickerHoursLabel = 'ModSqueezePageLPCCountdownTimerModalTimePickerHoursLabel',
  ModSqueezePageLPCCountdownTimerModalTimePickerMinutesLabel = 'ModSqueezePageLPCCountdownTimerModalTimePickerMinutesLabel',
  ModSqueezePageLPCCountdownTimerModalChooseActionLabel = 'ModSqueezePageLPCCountdownTimerModalChooseActionLabel',
  ModSqueezePageLPCCountdownTimerModalChooseActionDoNothing = 'ModSqueezePageLPCCountdownTimerModalChooseActionDoNothing',
  ModSqueezePageLPCCountdownTimerModalChooseActionRedirect = 'ModSqueezePageLPCCountdownTimerModalChooseActionRedirect',
  ModSqueezePageLPCCountdownTimerModalChooseActionRedirectExample = 'ModSqueezePageLPCCountdownTimerModalChooseActionRedirectExample',
  ModSqueezePageLPCCountdownTimerModalChooseActionRedirectErrorEmpty = 'ModSqueezePageLPCCountdownTimerModalChooseActionRedirectErrorEmpty',
  ModSqueezePageLPCCountdownTimerModalChooseActionRedirectErrorInvalid = 'ModSqueezePageLPCCountdownTimerModalChooseActionRedirectErrorInvalid',
  ModSqueezePageLPCCountdownTimerModalDateTimePickerLabel = 'ModSqueezePageLPCCountdownTimerModalDateTimePickerLabel',
  ModSqueezePageLPCCountdownTimerModalDateTimePickerErrorHint = 'ModSqueezePageLPCCountdownTimerModalDateTimePickerErrorHint',
  ModSqueezePageLPCCountdownTimerModalDateTimePickerPlaceholder = 'ModSqueezePageLPCCountdownTimerModalDateTimePickerPlaceholder',
  ModSqueezePageLPCCountdownTimerModalSelectTimeZoneLabel = 'ModSqueezePageLPCCountdownTimerModalSelectTimeZoneLabel',
  ModSqueezePageLPCCountdownTimerModalSelectTimeZonePlaceholder = 'ModSqueezePageLPCCountdownTimerModalSelectTimeZonePlaceholder',
  ModSqueezePageLPCCountdownTimerModalButtonConfirm = 'ModSqueezePageLPCCountdownTimerModalButtonConfirm',
  ModSqueezePageLPCCountdownTimerModalButtonCancel = 'ModSqueezePageLPCCountdownTimerModalButtonCancel',
  ModSqueezePageLPCCountdownTimerPlaceholderDays = 'ModSqueezePageLPCCountdownTimerPlaceholderDays',
  ModSqueezePageLPCCountdownTimerPlaceholderHours = 'ModSqueezePageLPCCountdownTimerPlaceholderHours',
  ModSqueezePageLPCCountdownTimerPlaceholderMinutes = 'ModSqueezePageLPCCountdownTimerPlaceholderMinutes',
  ModSqueezePageLPCCountdownTimerPlaceholderSeconds = 'ModSqueezePageLPCCountdownTimerPlaceholderSeconds',

  ModSqueezePageLPCFunnelConfirmationPageGoToURL = 'ModSqueezePageLPCFunnelConfirmationPageGoToURL',
  ModSqueezePageLPCFunnelConfirmationPageDownload = 'ModSqueezePageLPCFunnelConfirmationPageDownload',
  ModSqueezePageLPCFunnelNoProducts = 'ModSqueezePageLPCFunnelNoProducts',
  ModSqueezePageLPCFunnelDynamicProductsPlaceholder = 'ModSqueezePageLPCFunnelDynamicProductsPlaceholder',
  ModSqueezePageLPCFunnelDynamicProductsLeadMagnetPlaceholder = 'ModSqueezePageLPCFunnelDynamicProductsLeadMagnetPlaceholder',
  ModSqueezePageLPCFunnelOrderFormTabName = 'ModSqueezePageLPCFunnelOrderFormTabName',

  ModSqueezePageLPCFunnelOrderFormNotSeenModalTitle = 'ModSqueezePageLPCFunnelOrderFormNotSeenModalTitle',
  ModSqueezePageLPCFunnelOrderFormNotSeenModalDescription = 'ModSqueezePageLPCFunnelOrderFormNotSeenModalDescription',
  ModSqueezePageLPCFunnelOrderFormNotSeenModalButtonConfirm = 'ModSqueezePageLPCFunnelOrderFormNotSeenModalButtonConfirm',
  ModSqueezePageLPCFunnelOrderFormNotSeenModalButtonCancel = 'ModSqueezePageLPCFunnelOrderFormNotSeenModalButtonCancel',

  ModSqueezePageLPCFunnelJoinTheProgramButtonCaption = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaption',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionExternalUrl = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionExternalUrl',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionInternalUrl = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionInternalUrl',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionCopyCampaign = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionCopyCampaign',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionSendMessage = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionSendMessage',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionInstruction = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionInstruction',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionMoveCampaign = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionMoveCampaign',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionWebinarAccess = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionWebinarAccess',
  ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionPhysical = 'ModSqueezePageLPCFunnelJoinTheProgramButtonCaptionPhysical',

  ModSqueezePageLPCFunnelProductButtonCaption = 'ModSqueezePageLPCFunnelProductButtonCaption',
  ModSqueezePageLPCCreatePageProductsBoxEditButtonArrangement = 'ModSqueezePageLPCCreatePageProductsBoxEditButtonArrangement',

  ModSqueezePageLPCCreatePageFunnelOrderFormNoPostalCodeError = 'ModSqueezePageLPCCreatePageFunnelOrderFormNoPostalCodeError',

  ModSqueezePageLPCCreatePageToolBarsAddNewFontOption = 'ModSqueezePageLPCCreatePageToolBarsAddNewFont',
  ModSqueezePageLPCCreatePageToolBarsAddNewFontWebFont = 'ModSqueezePageLPCCreatePageToolBarsAddNewFontWebFont',
  ModSqueezePageLPCCreatePageToolBarsAddNewFontFallbackFont = 'ModSqueezePageLPCCreatePageToolBarsAddNewFontFallbackFont',
  ModSqueezePageLPCCreatePageToolBarsAddNewFontPreviewText = 'ModSqueezePageLPCCreatePageToolBarsAddNewFontPreviewText',
  ModSqueezePageLPCCreatePageToolBarsAddNewFontCancel = 'ModSqueezePageLPCCreatePageToolBarsAddNewFontCancel',
  ModSqueezePageLPCCreatePageToolBarsAddNewFontApply = 'ModSqueezePageLPCCreatePageToolBarsAddNewFontApply',
  ModSqueezePageLPCCreatePageToolBarsAddNewFontPanelDescription = 'ModSqueezePageLPCCreatePageToolBarsAddNewFontPanelDescription',

  ModSqueezePageLpcCreatePageSaveErrorModalHeading = 'ModSqueezePageLpcCreatePageSaveErrorModalHeading',
  ModSqueezePageLpcCreatePageSaveErrorModalContent = 'ModSqueezePageLpcCreatePageSaveErrorModalContent',
  ModSqueezePageLpcCreatePageSaveErrorModalButton = 'ModSqueezePageLpcCreatePageSaveErrorModalButton',

  ModSqueezePageLpcCreatePageInvalidSettingsModalHeading = 'ModSqueezePageLpcCreatePageInvalidSettingsModalHeading',
  ModSqueezePageLpcCreatePageInvalidSettingsModalContent = 'ModSqueezePageLpcCreatePageInvalidSettingsModalContent',
  ModSqueezePageLpcCreatePageInvalidSettingsModalButton = 'ModSqueezePageLpcCreatePageInvalidSettingsModalButton',

  // Errors
  ModSqueezePageLpcCreatePageSaveAsTemplateError = 'ModSqueezePageLpcCreatePageSaveAsTemplateError',
  ModSqueezePageLpcCreatePageSaveError = 'ModSqueezePageLpcCreatePageSaveError',

  ModSqueezePageLpcCreatePageRepairToolModalHeading = 'ModSqueezePageLpcCreatePageRepairToolModalHeading',
  ModSqueezePageLpcCreatePageRepairToolIdleModalContentNothingToDo = 'ModSqueezePageLpcCreatePageRepairToolIdleModalContentNothingToDo',
  ModSqueezePageLpcCreatePageRepairToolIdleModalContent = 'ModSqueezePageLpcCreatePageRepairToolIdleModalContent',
  ModSqueezePageLpcCreatePageRepairToolModalFinishedContent = 'ModSqueezePageLpcCreatePageRepairToolModalFinishedContent',
  ModSqueezePageLpcCreatePageRepairToolModalRunButton = 'ModSqueezePageLpcCreatePageRepairToolModalRunButton',
  ModSqueezePageLpcCreatePageRepairToolModalLinkManage = 'ModSqueezePageLpcCreatePageRepairToolModalLinkManage',
  ModSqueezePageLpcCreatePageRepairToolModalDoneButton = 'ModSqueezePageLpcCreatePageRepairToolModalDoneButton',
  ModSqueezePageLpcCreatePageRepairToolModalCloseButton = 'ModSqueezePageLpcCreatePageRepairToolModalCloseButton',
}
