import { IErrorMonitor } from './ErrorMonitor';

export class BaseErrorMonitor implements IErrorMonitor {
  public init(): void {
    // do nothing
  }

  public addBreadcrumb(): void {
    // do nothing
  }

  public setUser(): void {
    // do nothing
  }

  public setTag(): void {
    // do nothing
  }

  public captureException(): void {
    // do nothing
  }

  public captureMessage(): void {
    // do nothing
  }
}
