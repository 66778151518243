import * as $ from 'jquery';
import { DatetimeHelper } from '../../../helpers/datetime';
import { NumberHelper } from '../../../helpers/number';
import { logger } from '../helpers/logger';
import { CounterTypes } from '../../../enums/counterTypes';
import { CounterActions } from '../../editor/enums/counterActions';
import { catchTheMoment } from '@gr/translations';
import { Navigate } from '../../../actions/navigate';
import { linksModifier } from './links';
import { LPSData } from '../models/config';

interface ITimerProps {
  $el: JQuery<HTMLDivElement>;
  componentId: string;
  date: string;
  timeZoneId: string;
  counterType: CounterTypes;
  secondsToEnd: number;
  action: CounterActions;
  redirectUrl: string;
}

function enableTimer({
  $el,
  componentId,
  date,
  timeZoneId,
  counterType,
  secondsToEnd,
  redirectUrl,
  action,
}: ITimerProps): void {
  // eslint-disable-next-line prefer-const
  let intervalId;
  let parsedDate = DatetimeHelper.getDate(date, timeZoneId);

  switch (counterType) {
    case CounterTypes.EVERGREEN:
      const savedCounterData = sessionStorage.getItem(`counter-${componentId}`);
      if (savedCounterData) {
        parsedDate = catchTheMoment(savedCounterData);
        if (!parsedDate.isValid() || DatetimeHelper.isInThePast(parsedDate)) {
          parsedDate = null;
        }
      } else {
        parsedDate = null;
      }
      const restartCounter = !parsedDate?.isValid();
      if (restartCounter) {
        parsedDate = DatetimeHelper.getDataSecondsFromNow(secondsToEnd);
      }
      sessionStorage.setItem(`counter-${componentId}`, parsedDate!.format());
      break;
    case CounterTypes.STANDARD:
    default:
      parsedDate = DatetimeHelper.getDate(date, timeZoneId);
      break;
  }

  const $days = $el.find('[data-editable-item="days-value"]');
  const $hours = $el.find('[data-editable-item="hours-value"]');
  const $minutes = $el.find('[data-editable-item="minutes-value"]');
  const $seconds = $el.find('[data-editable-item="seconds-value"]');

  function timerIntervalCallback(): void {
    const now = DatetimeHelper.now();
    const remainingTimeData = DatetimeHelper.getRemainingTime(parsedDate, now);

    if (remainingTimeData.isInThePast) {
      clearInterval(intervalId);
      sessionStorage.removeItem(`counter-${componentId}`);
      if (counterType === CounterTypes.EVERGREEN) {
        if (LPSData.isOTOPageStep()) {
          linksModifier.skipOTOPage();
        } else if (action === CounterActions.REDIRECT && redirectUrl) {
          const url = decodeURIComponent(redirectUrl);
          Navigate.toExternal(url);
        }
      }
    }

    // Do it even the time ran out, to display at least 00:00:00
    $seconds.html(NumberHelper.leadingZero(Math.max(0, remainingTimeData.seconds)) as string).show();
    $minutes.html(NumberHelper.leadingZero(Math.max(0, remainingTimeData.minutes)) as string).show();
    $hours.html(NumberHelper.leadingZero(Math.max(0, remainingTimeData.hours)) as string).show();
    $days.html(NumberHelper.leadingZero(Math.max(0, remainingTimeData.days)) as string).show();
  }

  timerIntervalCallback();

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  intervalId = setInterval(timerIntervalCallback, 1000);
}

export default {
  enableScripts(): void {
    logger.log('Looking for countdown timers...');
    const $countdownTimers = $<HTMLDivElement>('[data-editable="countdownTimer"]', document.body);

    logger.log('Initialize countdown timers...');

    $countdownTimers.each((index, el) => {
      const defaultSecondsToEnd = 120;
      const $el: JQuery<HTMLDivElement> = $<HTMLDivElement>(el);
      const componentId = `${document.body.id}-${$el.attr('id')}`;
      const date = $el.attr('data-end-date') || '';
      const timeZoneId = $el.attr('data-time-zone-id') || '384';
      const counterType: CounterTypes = ($el.attr('data-counter-type') as CounterTypes) || CounterTypes.STANDARD;
      const secondsToEnd: number = parseInt($el.attr('data-end-seconds'), 10) || defaultSecondsToEnd;
      const action: CounterActions = ($el.attr('data-counter-action') as CounterActions) || CounterActions.DO_NOTHING;
      const redirectUrl: string = $el.attr('data-redirect-url') || '';
      if ((counterType === CounterTypes.STANDARD && date && timeZoneId) || counterType === CounterTypes.EVERGREEN) {
        logger.log('Initializing countdown timer', date, timeZoneId);
        enableTimer({ $el, componentId, date, timeZoneId, counterType, action, redirectUrl, secondsToEnd });
      }
    });
  },
};
