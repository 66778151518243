function Handles(field) {
  this.field = field;

  this.init();
}

Handles.blur = function (e) {
  this.notify('blur', e);

  return true;
};

Handles.focus = function (e) {
  this.unsetErrorMessage();
  this.notify('focus', e);

  return true;
};

Handles.prototype = {
  init: function () {
    var element = this.field.element;

    element.addEventListener('blur', Handles.blur.bind(this.field), false);
    element.addEventListener('focus', Handles.focus.bind(this.field), false);
  },
};

export default Handles;
