import './helpers/fontsize';
import './constants/publicPath';
import customPhoneHelper from './helpers/custom_phone';
import { FontsHelper } from './helpers/fonts';
import placeholderHelper from './helpers/placeholder';
import socialHelper from '../editor/helpers/social';
import boxCookieNotificationHelper from './helpers/box_cookie_notification';
import webinarsHelper from './helpers/webinars';
import tabIndex from './helpers/tab_index';
import { ErrorMonitor } from '../../monitors/errorMonitor/ErrorMonitor';
import { LPSData } from './models/config';
import { ViewName } from '../../enums/viewName';

const crypto = LPSData.getCrypto();
ErrorMonitor.init({
  view: ViewName.Show,
  crypto,
  landingId: LPSData.getLandingPageEncodedId(),
});
ErrorMonitor.setUser(LPSData.getUserId(), crypto);

document.addEventListener(
  'DOMContentLoaded',
  () => {
    const div = document.getElementsByClassName('.backdrop')[0];

    if (div) {
      div.parentNode.removeChild(div);
    }

    FontsHelper.loadFonts();
    placeholderHelper.initialize();

    //Nie mam pojecia co to jest
    if (window.clear) {
      window.clear();
    }

    socialHelper.initialize();
    boxCookieNotificationHelper.initialize();
    webinarsHelper.initialize();

    if (LPSData.getCountryPhoneCodes()) {
      //nie ma na  ShowThankYou page
      customPhoneHelper.initialize(LPSData.getCountryPhoneCodes());
    }

    tabIndex.initialize();
  },
  false,
);
