/**
 * Be careful with changing these values.
 * It is related to DOM element attributes.
 * e.g If you change WEBINAR value then old webinar links (already placed in templates) will be not recognized as webinar link
 */
export enum LinkType {
  WEB = 'web',
  PHONE = 'phone',
  EMAIL = 'email',
  SECTION = 'section',
  WEBINAR = 'webinar',
  JOIN_THE_PROGRAM = 'jointheprogram',
  PRODUCT_LINK = 'productlink',
  OTO_PAGE_DISMISS = 'otopagedismiss',
  OTO_PAGE_ACCEPT = 'otopageaccept',
  LEAD_MAGNET_PRODUCT_LINK = 'leadmagnetproduct',
}
