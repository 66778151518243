export enum ORDER_FORM_ITEM_TYPE {
  CARD_NUMBER = 'cardNumber',
  CARD_CVC = 'cardCvc',
  CARD_EXPIRY = 'cardExpiry',
  POSTAL_CODE = 'postalCode',
}

export enum SQUARE_ORDER_FORM_ITEM_TYPE {
  CARD_NUMBER = 'cardNumber',
  CARD_CVC = 'cvv',
  CARD_EXPIRY = 'expirationDate',
  POSTAL_CODE = 'postalCode',
}
