function stopAutoPlay() {
  var allVideo = document.querySelectorAll('[data-editable="video"] iframe'),
    ln = allVideo.length,
    src,
    i;

  for (i = 0; i < ln; i += 1) {
    src = allVideo[i].src.replace(/autoplay=1/i, 'autoplay=0');
    src = src.replace('autoPlay', '');
    allVideo[i].src = src;
  }
}

export default {
  stopAutoPlay: stopAutoPlay,
};
