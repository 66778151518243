/* eslint-disable @typescript-eslint/no-explicit-any */
import * as $ from 'jquery';

import FieldElement from '../../prototypes/field_element';
import OrderFormElement from '../../prototypes/order_form_element';
import ProductListElement from '../../prototypes/product_list_element';
import { TrackingHelper } from '../../helpers/tracking';

import { FormPostSubmitAction } from '../../enums/formPostSubmitAction';

import countryCodeHelper from '../../helpers/country_code';
import AutoFunnelHelper from '../../helpers/autofunnel';
import helperForm from '../../helpers/form';
import helperRequiredFields from '../../helpers/required_fields';
import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';
import { isElementVisible } from '../../helpers/dom';

import { noop } from '../../utils/noop';

import { Navigate } from '../../../../actions/navigate';
import { LPSData } from '../../models/config';

import { AutoFunnelStepType } from '../../../../enums/autoFunnelStepType';
import { FieldValidityType } from '../../enums/fieldValidityType';

import { ILpsShowInitialData } from '../../../../interfaces/lpsShow';

import { UrlHelper } from '../../../../helpers/url';

const inputs = ['[data-editable="webformNewItem"] input', '[data-editable="productList"] input'];

const spaceFromBrowserViewportTop = 15;

export class BaseFormElement {
  private static setZIndex(el: HTMLDivElement, zIndex: number): void {
    const $element = $(el);
    const webformItem = $element.closest('[data-editable]');

    if (webformItem[0]) {
      const radix = 10;
      webformItem[0].style.zIndex = zIndex.toString(radix);
    }
  }

  private static sortByRectTop(a: HTMLElement, b: HTMLElement): number {
    const rectA = a.getBoundingClientRect();
    const rectB = b.getBoundingClientRect();

    if (rectA.top < rectB.top) {
      return -1;
    }
    if (rectA.top > rectB.top) {
      return 1;
    }
    return 0;
  }

  private inputOnChangeTimer: ReturnType<typeof setTimeout> | null = null;
  protected errors: any;
  private submitButtonCaptionStore: string;
  protected readonly orderForm: OrderFormElement;
  protected readonly productList: ProductListElement;
  protected readonly form: HTMLFormElement;

  constructor(form: HTMLFormElement) {
    this.form = form;
    this.orderForm = this.registerOrderForm(form);
    this.productList = this.registerProductList(form);

    this.init();
  }

  public init(): void {
    const oThis = this;
    let elements = Array.prototype.slice.call(this.form.elements);
    elements = elements.sort(BaseFormElement.sortByRectTop);
    const l = elements.length;

    elements.forEach((element, index) => {
      const field = new FieldElement(element) as any;
      let fieldName = field.name === 'consent' ? field.id : field.name;
      const isStripeElement = !fieldName && element.className === '__PrivateStripeElement-input';

      if (isStripeElement) {
        fieldName = `${$(element).parents('[data-editable="orderFormItem"]').attr('data-item-type')}Stripe`;
      }

      if (fieldName) {
        element.setAttribute('data-ats-webform-field', fieldName);
      }

      BaseFormElement.setZIndex(element, l - index);

      //Exclude stripe elements - wszystko przez to ze brane sa wszystkie elementy forma... i nie sa jawnie oznaczone
      if (isStripeElement) {
        return;
      }

      oThis[fieldName] = field;

      Array.prototype.push.call(oThis, field);
    });

    this.form.addEventListener(
      'submit',
      (e) => {
        oThis
          .validate()
          .then(() => {
            oThis.proceed();
          })
          .catch((error) => {
            logger.error(error || 'Form submit error');
          });
        e.preventDefault();
        e.stopPropagation();
        return false;
      },
      false,
    );

    const onInputChange = this.onInputChange.bind(this);

    $(this.form).on('keyup', inputs.join(', '), onInputChange).on('change', inputs.join(', '), onInputChange);

    countryCodeHelper.updateCountryCode(this.form);

    this.initializePaymentStatusCheck();

    if (this.productList) {
      this.productList.updateTotalPrice();
    }

    window.addEventListener('load', () => {
      if (LPSData.isOfferPage()) {
        this.sendAbandonedCartData();
      }
    });
  }

  public registerOrderForm(formElement: HTMLFormElement): OrderFormElement {
    if (!LPSData.hasStripeIntegration() && !LPSData.hasBlueSnapIntegration() && !LPSData.hasSquareIntegration()) {
      return null;
    }

    const orderFormElement = formElement.querySelector('[data-editable="orderForm"]');

    if (orderFormElement) {
      return new OrderFormElement(orderFormElement, this);
    } else {
      return null;
    }
  }

  public registerProductList(formElement: HTMLFormElement): ProductListElement {
    const productListElement = formElement.querySelector('[data-editable="productList"]');

    if (productListElement) {
      return new ProductListElement(this.form, productListElement);
    } else {
      return null;
    }
  }

  public onInputChange(): void {
    if (LPSData.isOfferPage()) {
      clearTimeout(this.inputOnChangeTimer);
      const onChangeTimeout = 300;

      this.inputOnChangeTimer = setTimeout(() => {
        this.sendAbandonedCartData();
      }, onChangeTimeout);
    }
  }

  public sendAbandonedCartData(serializedData?: any[]): void {
    const data = serializedData || $(this.form).serializeArray();

    data.push({
      name: 'autoFunnelId',
      value: (window.grLpsInitialData as ILpsShowInitialData).autoFunnel.id,
    });

    data.push({
      name: 'autoFunnelStepId',
      value: (window.grLpsInitialData as ILpsShowInitialData).autoFunnel.stepId,
    });

    data.push({
      name: 'autoFunnelStepType',
      value: (window.grLpsInitialData as ILpsShowInitialData).autoFunnel.stepType,
    });

    if ('GrTracking' in window && typeof window.GrTracking === 'function') {
      window.GrTracking('setAutoFunnelData', data);
    }
  }

  public validate(): Promise<void> {
    const oThis = this;
    delete this.errors;

    return new Promise((resolve, reject) => {
      if (LPSData.hasAnyPaymentEnabled()) {
        oThis.removePaymentError();
        oThis.disableForm();
      }

      const errors = helperRequiredFields.getErrors(this.form);

      //Check required fields
      if (Object.keys(errors).length) {
        oThis.errors = errors;
        oThis.reportValidity();

        if (LPSData.hasAnyPaymentEnabled()) {
          oThis.enableForm();
        }

        logger.log('Test: Required fields are not filled out.');
        reject();
        return;
      }

      if (LPSData.hasAnyPaymentEnabled()) {
        if (LPSData.hasStripeIntegrationAndCorrectElements(oThis.form)) {
          if (oThis.orderForm?.hasErrors()) {
            oThis.scrollToError();
            oThis.enableForm();

            logger.log('Test: Credit card fields contains errors.');
            reject();
            return;
          }
        }

        //Check weather products are selected
        if (LPSData.hasProductsList(oThis.form)) {
          const hasSelectedProducts = oThis.productList.hasSelectedProducts();
          oThis.productList.toggleProductsError(hasSelectedProducts);

          if (!hasSelectedProducts) {
            oThis.enableForm();
            oThis.scrollToError();

            logger.log('Test: No products are selected.');
            reject();
            return;
          }
        }
      }

      resolve();
    });
  }

  public proceed(): void {
    const serializedData = $(this.form).serializeArray();

    this.proceedWithSubscription(
      (response, formData) => {
        this.success(response.table, formData);
      },
      null,
      serializedData,
    );
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  public proceedStripePaymentRequest(serializedData: any[], paymentRequestEvent: any): void {
    logger.warn('Not Implemented');
  }

  public proceedWithSubscription(
    successCallback: (response: any, data: any) => void = noop,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    errorCallback: () => void = noop,
    serializedData: any[] = null,
  ): void {
    //If all previous validations have passed, validate fields on the server
    const dataToSend = this.getFormRequestData();
    const oThis = this;

    $.ajax({
      url: location.href,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(dataToSend),
    }).done((response) => {
      if (response.error) {
        oThis.errors = response.error;
        oThis.reportValidity();

        if (LPSData.hasAnyPaymentEnabled()) {
          oThis.enableForm();
        }
      } else {
        if (LPSData.hasAutoFunnel()) {
          oThis.sendAbandonedCartData(serializedData);
        }

        successCallback(response, dataToSend);
      }
    });
  }

  protected getFormRequestData(): any {
    return helperForm.getDataToSend(this.form);
  }

  protected checkPaymentStatusCallback(response: any): void {
    if (response.isSuccess) {
      Navigate.toExternal(response.confirmationUrl);
    } else if (response.isError) {
      notify.alert(response.statusNote);
      this.enableForm();
    }
  }

  protected enableForm(): void {
    const submitButtonEditable = this.form.querySelector('[data-editable="webformNewButton"]');
    const submitButton = submitButtonEditable.querySelector('button');

    AutoFunnelHelper.enableAllFields(this.form, this.orderForm);
    submitButtonEditable.classList.remove('processing');
    submitButtonEditable.removeAttribute('data-ats-button-state');

    submitButton.value = this.submitButtonCaptionStore;
    submitButton.innerHTML = this.submitButtonCaptionStore;
  }

  public success(response: any, dataToSend: any): void {
    if (!response) {
      //bool
      return;
    }

    const { email } = dataToSend;

    // gaSetUserId jest funkcja zalaczona przez kod integrations
    // Jesli user przyjdzie bezposrednio z linka nie mamy zadnych informacji o nim, dlatego musumy wyslac je recznie po wyslaniu forma
    if (
      document.cookie.replace(/(?:(?:^|.*;\s*)visitorId\s*=\s*([^;]*).*$)|^.*$/, '$1') === 'undefined' &&
      email &&
      (window as any).gaSetUserId
    ) {
      (window as any).gaSetUserId(email);
    }

    const { postSubmitAction, url: redirectUrl } = response;

    if (postSubmitAction === FormPostSubmitAction.REDIRECT && redirectUrl) {
      Navigate.toExternal(redirectUrl);
      return;
    }

    // Normal LPS Flow
    if ((window.grLpsInitialData as ILpsShowInitialData).thankyouurl === 'stay') {
      this.createTYButton();

      TrackingHelper.sendTrackingEvent();
      TrackingHelper.sendAdWordsEvent();
    } else {
      document.cookie = `gaThankYouEmail=${email}`;
      TrackingHelper.sendTrackingEvent();
      TrackingHelper.sendAdWordsEvent(() => {
        Navigate.toExternal((window.grLpsInitialData as ILpsShowInitialData).thankyouurl);
      });
    }
  }

  public createTYButton(): void {
    const isNewWebform = !!$(this.form).parents('[data-editable="webformNew"]').length;
    const isNewWebinar = !!$(this.form).parents('[data-editable="webinarNew"]').length;

    const tYButtonTimeout = 2500;
    if (isNewWebform || isNewWebinar) {
      const buttonEditable = this.form.querySelector('[data-editable="webformNewButton"]');
      const button = buttonEditable.querySelector<HTMLButtonElement>('[type="submit"]');
      const fontColor = window.getComputedStyle(buttonEditable, '').color;
      const register = {
        value: button.value,
        innerHTML: button.innerHTML,
      };
      // eslint-disable-next-line max-len
      const checkmark = `<svg height="75%" style="margin:auto auto;position:absolute;top:0;right:0;bottom:0;left:0;" viewBox="0 0 50 50" ><path fill="${fontColor}" d="M0.1,24.4l4.3-4c5,2.4,8.2,4.3,13.8,8.3c10.6-12,17.6-18.1,30.6-26.2l1.4,3.2 c-10.7,9.4-18.6,19.8-29.9,40.1C13.3,37.6,8.6,32.4,0.1,24.4z"/></svg>`;

      button.disabled = true;
      button.value = (window.grLpsInitialData as ILpsShowInitialData).thankyoutext;
      button.innerHTML = checkmark;

      setTimeout(() => {
        button.form.reset();
        button.value = register.value;
        button.innerHTML = register.innerHTML;

        Array.prototype.slice.call(button.form.elements).forEach((element) => {
          element.focus();
          element.blur();
        });

        button.disabled = false;
      }, tYButtonTimeout);
    } else {
      //Stary kod dla starych WF
      (function legacyCreateTYButton(): void {
        const buttonContainer =
          this.form.querySelector('[data-type="submit"]') ||
          (function getButton(form: HTMLFormElement): any {
            const items = form.getElementsByClassName('wf-item');
            return items[items.length - 1];
          })(this.form);
        const button = buttonContainer.querySelector('[type="submit"]');
        const register = {
          value: button.value,
          innerHTML: button.children[0].innerHTML,
          wfItemPos: buttonContainer.children[0].style.cssText,
          wrapper: buttonContainer.children[0].children[0].style.cssText,
        };
        const fontColor = window.getComputedStyle(button.children[0], '').color;
        // eslint-disable-next-line max-len
        const checkmark = `<svg height="75%" style="margin:auto auto;position:absolute;top:0;right:0;bottom:0;left:0;" viewBox="0 0 50 50" ><path fill="${fontColor}" d="M0.1,24.4l4.3-4c5,2.4,8.2,4.3,13.8,8.3c10.6-12,17.6-18.1,30.6-26.2l1.4,3.2 c-10.7,9.4-18.6,19.8-29.9,40.1C13.3,37.6,8.6,32.4,0.1,24.4z"/></svg>`;

        button.disabled = true;

        button.value = (window.grLpsInitialData as ILpsShowInitialData).thankyoutext;
        button.children[0].innerHTML = checkmark;

        setTimeout(() => {
          button.form.reset();
          button.value = register.value;
          button.children[0].innerHTML = register.innerHTML;

          try {
            buttonContainer.children[0].style.cssText = register.wfItemPos;
            buttonContainer.children[0].children[0].style.cssText = register.wrapper;
          } catch (ex) {
            if (buttonContainer.children[0].style.setAttribute) {
              buttonContainer.children[0].style.setAttribute('cssText', register.wfItemPos);
              buttonContainer.children[0].children[0].style.setAttribute('cssText', register.wrapper);
            }
          }

          Array.prototype.slice.call(button.form.elements).forEach((element) => {
            element.focus();
            element.blur();
          });

          button.disabled = false;
        }, tYButtonTimeout);
      }.call(this));
    }
  }

  public reportValidity(): boolean {
    const oThis = this;
    const fieldsName = Object.keys(this.errors || {});

    fieldsName.forEach((name) => {
      const validityType = name === 'gr_conference_id' ? FieldValidityType.Notify : FieldValidityType.Inline;
      if (oThis[name]) {
        oThis[name].reportValidity(oThis.errors[name], validityType);
      } else {
        // New WF - how could I know that 'custom_' is necessary :/
        const strippedName = name.replace('custom_', '');

        if (oThis[strippedName]) {
          oThis[strippedName].reportValidity(oThis.errors[name], validityType);
        }
      }
    });

    this.scrollToError();

    return !!fieldsName.length;
  }

  public scrollToError(): void {
    let $errorElements = $('body').find('[data-error-msg].error');

    //Errors are not always set on the editables, but on its children, so find editable
    if (!$errorElements.is('[data-editable]')) {
      $errorElements = $errorElements.parent('[data-editable]');
    }

    const offset = $errorElements.offset();

    if (offset && !isElementVisible($errorElements)) {
      window.scrollTo(0, offset.top - spaceFromBrowserViewportTop);
    }
  }

  private disableForm(): void {
    const newCaption = (window.grLpsInitialData as ILpsShowInitialData).l10n
      .ModSqueezePageLPCCreatePageAutoFunnelBtnProcessingCaption;
    const submitButtonEditable = this.form.querySelector('[data-editable="webformNewButton"]');
    const submitButton = submitButtonEditable.querySelector('button');

    AutoFunnelHelper.disableAllFields(this.form, this.orderForm);
    submitButtonEditable.classList.add('processing');
    submitButtonEditable.setAttribute('data-ats-button-state', 'processing');
    this.submitButtonCaptionStore = submitButton.innerHTML;

    submitButton.value = newCaption;
    submitButton.innerHTML = newCaption;
  }

  private removePaymentError(): void {
    const errorContainer = this.form.querySelector('[data-error-container="payment"]');

    if (errorContainer) {
      errorContainer.parentNode.removeChild(errorContainer);
    }
  }

  private initializePaymentStatusCheck(): void {
    const uuid = UrlHelper.findGetParameter('uuid');

    if (uuid && LPSData.getAutoFunnelStepType() === AutoFunnelStepType.OFFER_PAGES) {
      AutoFunnelHelper.checkPaymentStatus(uuid, this.checkPaymentStatusCallback.bind(this));
    }
  }
}
