/* eslint-disable @typescript-eslint/no-magic-numbers */
import * as $ from 'jquery';
import { ScreenOrientation } from '../enums/ScreenOrientation';

/**
 * Phones:
 * 1) Galaxy Note 3, Android 5.0, default browser
 * 2) Galaxy Note 3, Android 5.0, Chrome browser
 * 3) Nexus 9, Android 7.0, Chrome 54.0 browser
 * 4) iPhone 4S, iOS 7.1.2, Safari 7.0
 */
let orientation: ScreenOrientation;
let deviceWidth: number;

const startDimensions: Record<ScreenOrientation, { width: number; height: number }> = {
  [ScreenOrientation.Landscape]: {
    height: 0,
    width: 0,
  },
  [ScreenOrientation.Portrait]: {
    height: 0,
    width: 0,
  },
};

function updateViewport(): void {
  const lpsWidth =
    deviceWidth <= 600
      ? parseInt(document.body.getAttribute('data-workspace-phone-width'), 10) || 400
      : parseInt(document.body.getAttribute('data-workspace-desktop-width'), 10) || 980;
  const calculatedScale = deviceWidth / lpsWidth;
  let viewportContent = `initial-scale=${calculatedScale}, width=device-width`;

  if (deviceWidth >= lpsWidth) {
    viewportContent = 'initial-scale=1.0, width=device-width';
  }

  const viewport = document.querySelector<HTMLMetaElement>('meta[name="viewport"]');
  viewport.content = viewportContent;
}

$(function onReady() {
  const screenOrientation =
    (screen as any).webkitOrientation /* Phone (1) */ || screen?.orientation?.type; /* Phone (2),(3),(4) */

  orientation =
    (screenOrientation || '').indexOf(ScreenOrientation.Portrait) === -1
      ? ScreenOrientation.Landscape
      : ScreenOrientation.Portrait;

  /**
   * Phone (1) does not change screen width/height depends on its orientation - which is incorrect
   * Only dimensions 'on load' are correct, so lets save them, and use in the future
   */
  startDimensions[orientation] = {
    width: window.screen.width,
    height: window.screen.height,
  };

  // noinspection JSSuspiciousNameCombination
  startDimensions[
    orientation === ScreenOrientation.Portrait ? ScreenOrientation.Landscape : ScreenOrientation.Portrait
  ] = {
    width: window.screen.height,
    height: window.screen.width,
  };

  deviceWidth = window.screen.width;

  updateViewport();
});

window.addEventListener('orientationchange', () => {
  /**
   * Toggle orientation because phone (1) provides correct orientation only when page is loaded
   */
  orientation = orientation === ScreenOrientation.Portrait ? ScreenOrientation.Landscape : ScreenOrientation.Portrait;

  deviceWidth = startDimensions[orientation].width;

  updateViewport();
});
