import { WebFont } from '../../../helpers/WebFont/WebFont';

export class FontsHelper {
  public static loadFonts(): void {
    const fonts = [];
    const optElement = document.getElementById('OPT');

    if (optElement) {
      const formElement = optElement.getElementsByTagName('form')[0];
      const selectElement = formElement.GoogleWebFont;

      if (selectElement) {
        const { options } = selectElement;
        const ln = options.length;

        for (let i = 0; i < ln; i += 1) {
          // Urle nie moga zawierac spacji w sobie, google zwraca blad w requescie i font nie jest zaciagniety
          // Moze to byc spowodowane tym ze graficy zle je zdefiniowali
          let fontDefinition = options[i].text.replace(/\s/gm, '');

          // Ludzie ktorzy tworzyli templaty nie trzymali sie zasad z guideline. Kopiowali to co google-font im dawal
          // nie zwracajac uwagi na to ze 'subset' powinien byc na samym koncu.
          // Czyli family='wszystkie definicje czionek'&subset='wszystkie ich odmiany'
          fontDefinition = fontDefinition.replace('&subset=', ':');
          fontDefinition = fontDefinition.replace(/(["'])/g, '').trim();
          const fontParts = fontDefinition.split(':');
          const [family] = fontParts;
          let [, data, subset] = fontParts;
          data = data?.trim() || '';
          subset = subset?.trim() || '';
          if (family) {
            if (data !== '' && subset !== '') {
              // dobrze okreslona czcionka - zastosowany trim
              fontDefinition = `${family}:${data}:${subset}`;
            } else if (data !== '' || subset !== '') {
              // po czesci dobrze okreslona czcionka - stary blad templatki
              fontDefinition = `${family}:400,400i,700,700i:cyrillic,cyrillic-ext,latin-ext,vietnamese`;
            } else {
              // zle okreslona - do pominiecia
              fontDefinition = '';
            }
          }

          // dodaj fonty do pobrania tylko jesli mają okreslone wielkosci
          // jesli ich nie maja to zakladam, ze przez przypadek ktos dodal czcionke nie webfontowa
          if (fontDefinition !== '' && fonts.indexOf(fontDefinition) === -1) {
            fonts.push(fontDefinition);
          }
        }
      }
    }

    if (fonts.length > 0) {
      // noinspection JSIgnoredPromiseFromCall
      WebFont.load({
        google: {
          api: 'https://fonts.bunny.net/css',
          families: fonts,
        },
      });
    }
  }
}
