import * as $ from 'jquery';
import { OfferFormElement } from './offerFormElement';

import AutoFunnelHelper from '../../helpers/autofunnel';
import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';

import { LPSData } from '../../models/config';

import { StripePaymentIntentStatus } from '../../enums/stripePaymentIntentStatus';

import { ILpsShowInitialData } from '../../../../interfaces/lpsShow';

export class StripeFormElement extends OfferFormElement {
  private cachedUuid: string = null; // Store Uuid to don't create new transactions every time

  public proceed(): void {
    logger.log('Starting Stripe process');
    const serializedData = $(this.form).serializeArray();

    this.orderForm.createPaymentMethod({}, (createPaymentResponse) => {
      const { paymentMethod, error } = createPaymentResponse;

      if (error) {
        notify.alert(error.message);
        this.enableForm();
        return;
      }
      const formData = this.getFormRequestData();
      const stripePaymentData = JSON.stringify({
        ...formData,
        products: this.productList.getSelectedProducts(),
        cartId: LPSData.getAutoFunnelCartId(),
        uuid: this.cachedUuid,
        payment_method: paymentMethod ? paymentMethod.id : null,
      });
      AutoFunnelHelper.startProcessPayment(stripePaymentData, LPSData.getProcessPaymentUrl(), (response) => {
        this.proceedWithSubscription(
          () => {
            // Store Uuid to don't create new transactions every time
            if (response.uuid) {
              this.cachedUuid = response.uuid;
            }

            if (response.error) {
              notify.alert(response.error);
              this.enableForm();
            } else if (response.status === StripePaymentIntentStatus.REQUIRES_CONFIRMATION) {
              this.orderForm.submitPaymentWithConfirmation(
                { clientSecret: response.client_secret },
                (paymentResponse) => {
                  if (paymentResponse.error) {
                    notify.alert(paymentResponse.error.message);
                    this.enableForm();
                    this.scrollToError();
                  } else if (paymentResponse.paymentIntent) {
                    if (paymentResponse.paymentIntent.status === StripePaymentIntentStatus.SUCCEEDED) {
                      AutoFunnelHelper.checkPaymentStatus(response.uuid, this.checkPaymentStatusCallback.bind(this));
                    }
                  }
                },
              );
            } else {
              AutoFunnelHelper.checkPaymentStatus(response.uuid, this.checkPaymentStatusCallback.bind(this));
            }
          },
          null,
          serializedData,
        );
      });
    });
  }

  // noinspection JSUnusedGlobalSymbols
  public proceedStripePaymentRequest(serializedData: any[], paymentRequestEvent: any): void {
    logger.log('Starting Stripe Payment Request process');
    const selectedProducts = this.productList.getSelectedProducts();
    const formData = this.getFormRequestData();
    const data = {
      ...formData,
      card: paymentRequestEvent.token.card,
      email: formData.email,
      products: selectedProducts,
      token: paymentRequestEvent.token.id,
      stripeTokenType: 'external',
    };
    const oThis = this;

    $.ajax({
      url: (window.grLpsInitialData as ILpsShowInitialData).autoFunnel.stripePaymentRequestButtonUrl,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
    }).done((response) => {
      this.proceedWithSubscription(
        () => {
          if (response.error) {
            notify.alert(response.error);
            oThis.enableForm();

            paymentRequestEvent.complete('fail');
          } else {
            (AutoFunnelHelper.checkPaymentStatus(response.uuid, oThis.checkPaymentStatusCallback) as any).bind(this);
            paymentRequestEvent.complete('success');
          }
        },
        null,
        serializedData,
      );
    });
  }
}
