import { OrderForm, IOrderFormObject, ICardNonceResponseErrors } from './orderForm';

import { SquareIntegration } from '../../../editor/helpers/squareIntegration';
import { LPSData } from '../../models/config';

export class SquareOrderFormElementStrategy extends OrderForm {
  private readonly orderFormObject: IOrderFormObject;
  private readonly squareIntegrationInstance: SquareIntegration;

  constructor(orderFormObject: IOrderFormObject) {
    super();
    this.orderFormObject = orderFormObject;
    this.squareIntegrationInstance = new SquareIntegration();
  }

  public initializeItems(): void {
    const orderFormItemsElements = this.orderFormObject.getItems();
    const orderFormElement = this.orderFormObject.orderFormElement;
    const formElement = this.orderFormObject.formElement;

    orderFormItemsElements.forEach((item: HTMLElement) => {
      this.squareIntegrationInstance.initializeOrderFormItem(item);
    });
    this.squareIntegrationInstance.initializePostalCodeField(formElement);

    this.squareIntegrationInstance.initializeFormOnLPCShow(
      orderFormElement,
      LPSData.getPaymentProcessorToken(),
      LPSData.getPaymentSquareLocationId(),
    );
  }

  public requestCardNonce(): boolean {
    try {
      this.squareIntegrationInstance.paymentForm.requestCardNonce();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getNonce(): string {
    return this.squareIntegrationInstance.nonce;
  }

  public getRequestCardNonceErrors(): ICardNonceResponseErrors[] {
    return this.squareIntegrationInstance.requestCardNonceErrors;
  }

  public getPostalCode(): string {
    return this.squareIntegrationInstance.postalCode;
  }

  public setFieldErrorMessage(fieldName: string, message: string): void {
    this.squareIntegrationInstance.setFieldErrorMessage(fieldName, message);
  }

  public unSetFieldErrorMessage(fieldName: string): void {
    this.squareIntegrationInstance.unSetFieldErrorMessage(fieldName);
  }
}
