export enum TrackingCodePlatformName {
  GOOGLE_ANALYTICS = 'google_analytics',
  GOOGLE_TAG_MANAGER = 'google_tag_manager',
  BING_ADS = 'bing_ads',
  FACEBOOK_PIXEL = 'facebook_pixel',
  KISSMETRICS = 'kiss_metrics',
  MIXPANEL = 'mixpanel',
  VISUAL_WEBSITE_OPTIMIZER = 'vwo',
  YANDEX_METRICA = 'yandex_metrica',
}
