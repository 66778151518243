import './bootstrap_lpc_js';
import './helpers/polyfills';

import Forms from './prototypes/forms';
import { OtoPage } from './prototypes/otoPage';

import './helpers/mobile';

import customCodeModifier from './modifiers/custom_code';
import gdprFieldsModifier from './modifiers/gdpr_fields';
import bodyModifier from './modifiers/body';
import { AutoFunnelModifier } from './modifiers/autofunnel';
import webformAppsModifier from './modifiers/webform_apps';
import countdownTimerModifier from './modifiers/countdown_timer';
import { linksModifier } from './modifiers/links';
import grBadge from './modifiers/gr_badge';
import { Badge } from './modifiers/badge';
import { FormItem } from '../../modifiers/formItem';

import { LPSData } from './models/config';

import oldMobileHelper from './helpers/old_mobile';
import thumbnailsHelper from './helpers/thumbnails';
import firefoxHelper from './helpers/firefox';
import hoverHelper from './helpers/hover';
import customIframeHelper from './helpers/custom_iframe';
import { logger } from './helpers/logger';

import { l10n } from './helpers/l10n';
import { ModuleLoader } from '../../modules/ModuleLoader';
import { Statistics } from './helpers/statistics';

AutoFunnelModifier.modify(); // update as early as possible

localStorage.removeItem('webform.preview');

const forms = new Forms();

forms.register();

if (LPSData.isOTOPageStep()) {
  OtoPage.register();
}

document.addEventListener(
  'DOMContentLoaded',
  () => {
    thumbnailsHelper.initialize();
    customCodeModifier.enableScripts();
    bodyModifier.setBodyMinWidth();
    linksModifier.initialize();
  },
  false,
);

window.addEventListener(
  'load',
  async () => {
    try {
      await oldMobileHelper.initialize();
    } catch (e) {}
    try {
      Badge.init();
      await grBadge.initialize();
    } catch (e) {
      logger.error(e);
    }
    if (LPSData.isGrChatEnabled()) {
      ModuleLoader.loadGrChat({
        grChatUrlParams: LPSData.getGrChatUrlParams(),
        isMAX: LPSData.isMAX(),
        logger,
        isFreemium: LPSData.isFreemium(),
      });
    }

    bodyModifier.modify();
    gdprFieldsModifier.replaceContent();
    customIframeHelper.initialize();
    webformAppsModifier.enableScripts();
    countdownTimerModifier.enableScripts();
    firefoxHelper.initialize();
    hoverHelper.initialize();
    FormItem.initialize(l10n());
    Statistics.visitPage(LPSData.getVisitToken());
  },
  false,
);

logger.log('Initializing Lpc-Show');
