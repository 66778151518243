export function squareTemplate(wrapperId: string, wrapperStyles: string = ''): string {
  /**
   * In general we need this is only for styling postalCode wrapper
   * @var {string} wrapperInlineStyles
   */
  const wrapperInlineStyles = !!wrapperStyles ? 'style="' + wrapperStyles + '"' : null;
  return `<div class="input-square-wrapper input-style-container" ${wrapperInlineStyles}>
    <div id="square-id-${wrapperId}" class="input-square-container"></div>
</div>`;
}
