import { squareTemplate } from '../components/order_form_item/templates/order_form_square_item_template';

import { allowedSquareStyles } from '../constants/allowedSquareStyles';
import { Component } from '../../../enums/components';
import { ORDER_FORM_ITEM_TYPE, SQUARE_ORDER_FORM_ITEM_TYPE } from '../enums/order_form_item_types';
import { CustomField } from '../../../enums/custom_field';
import { SquareInputEventType } from '../enums/squareInputEventType';
import { SquareCardBrand } from '../enums/cardBrand';
import { SquareDigitalWalletType } from '../enums/digitalWalletType';

import { BaseLogger } from '../../../helpers/logger';

import { ICardNonceResponseErrors } from '../../show/strategies/order_form/orderForm';

interface ISqPaymentForm {
  build: () => void;
  recalculateSize: () => void;
  destroy: () => void;
  done: (paymentDetailsUpdate: any) => void;
  setPostalCode: (postalCode: string) => void;
  focus: (field: string) => void;
  requestCardNonce: () => void;
}

type ISqPaymentFormPlaceholder =
  | {
      elementId: string;
      placeholder: string;
    }
  | boolean;

export interface ISqPaymentFormInputStyle {
  mediaMinWidth?: string;
  mediaMaxWidth?: string;
  backgroundColor?: string;
  boxShadow?: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  padding?: string;
  placeholderColor?: string;
  placeholderFontWeight?: string;
  _webkitFontSmoothing?: string;
  _mozOsxFontSmoothing?: string;
}

interface ISqPaymentFormInputEventState {
  hasErrorClass: false;
  hasFocusClass: true;
  isCompletelyValid: false;
  isEmpty: false;
  isPotentiallyValid: true;
}

interface ISqPaymentFormInputEvent {
  cardBrand: string;
  currentState: ISqPaymentFormInputEventState;
  previousState: ISqPaymentFormInputEventState;
  elementId: string;
  eventType: SquareInputEventType;
  field: SQUARE_ORDER_FORM_ITEM_TYPE;
  postalCodeValue: string;
}

interface ISqPaymentFormShippingOption {
  id: string;
  label: string;
  amount: string;
}

export interface ICardNonceResponseCardData {
  card_brand: SquareCardBrand;
  last_4: string;
  exp_month: number;
  exp_year: number;
  billing_postal_code: string;
  digital_wallet_type: SquareDigitalWalletType;
}

type ICardNonceResponseReceived = (
  errors: ICardNonceResponseErrors[],
  nonce: string,
  cardData: {},
  billingContact: any,
  shippingContact: any,
  shippingOption: ISqPaymentFormShippingOption,
) => void;

interface ICreatePaymentRequest {
  requestShippingAddress: boolean;
  requestBillingInfo: boolean;
  currencyCode: string;
  countryCode: string;
  total: {
    label: string;
    amount: string;
    pending: boolean;
  };
  lineItems: Array<{
    label: string;
    amount: string;
    pending: boolean;
  }>;
}

interface ISqPaymentFormCallbacks {
  cardNonceResponseReceived: ICardNonceResponseReceived;
  createPaymentRequest?: () => ICreatePaymentRequest;
  inputEventReceived?: (inputEvent: ISqPaymentFormInputEvent) => void;
  methodsSupported?: (methods: any) => void;
  paymentFormLoaded?: () => void;
  unsupportedBrowserDetected?: () => void;
  shippingContactChanged?: (shippingContact: any, done: any) => void;
  shippingOptionChanged?: (shippingOption: any, done: any) => void;
}

interface ISqPaymentFormConfig {
  applePay?: ISqPaymentFormPlaceholder;
  cardNumber?: ISqPaymentFormPlaceholder;
  cvv?: ISqPaymentFormPlaceholder;
  expirationDate?: ISqPaymentFormPlaceholder;
  googlePay?: ISqPaymentFormPlaceholder;
  masterpass?: ISqPaymentFormPlaceholder;
  postalCode?: ISqPaymentFormPlaceholder;
  applicationId: string;
  locationId?: string;
  inputClass?: string;
  autoBuild: boolean;
  inputStyles: ISqPaymentFormInputStyle[];
  callbacks: ISqPaymentFormCallbacks;
}

interface ISquareField {
  name: string;
  element: HTMLElement;
}

export class SquareIntegration {
  private fields: ISquareField[];
  private readonly squareFieldNameMap: { [key in SQUARE_ORDER_FORM_ITEM_TYPE]: ORDER_FORM_ITEM_TYPE };
  public paymentForm: ISqPaymentForm;
  public postalCode: string;
  public nonce: string;
  public requestCardNonceErrors: ICardNonceResponseErrors[];

  constructor() {
    this.fields = [];
    this.squareFieldNameMap = {
      [SQUARE_ORDER_FORM_ITEM_TYPE.CARD_CVC]: ORDER_FORM_ITEM_TYPE.CARD_CVC,
      [SQUARE_ORDER_FORM_ITEM_TYPE.CARD_EXPIRY]: ORDER_FORM_ITEM_TYPE.CARD_EXPIRY,
      [SQUARE_ORDER_FORM_ITEM_TYPE.CARD_NUMBER]: ORDER_FORM_ITEM_TYPE.CARD_NUMBER,
      [SQUARE_ORDER_FORM_ITEM_TYPE.POSTAL_CODE]: ORDER_FORM_ITEM_TYPE.POSTAL_CODE,
    };
    this.postalCode = '';
    this.nonce = '';
    this.requestCardNonceErrors = null;
  }

  public setFieldErrorMessage(fieldName: string, message: string): void {
    const field: ISquareField = this.fields.find((squareField) => {
      return squareField.name === fieldName || squareField.name === this.squareFieldNameMap[fieldName];
    });

    if (field) {
      const fieldElement = field.element;
      fieldElement.dataset.errorMsg = message;
      fieldElement.classList.add('error', 'make-error-msg-visible');
    }
  }

  public unSetFieldErrorMessage(fieldName: string): void {
    const field: ISquareField = this.fields.find((squareField) => {
      return squareField.name === fieldName || squareField.name === this.squareFieldNameMap[fieldName];
    });

    if (field) {
      const fieldElement = field.element;
      fieldElement.removeAttribute('data-error-msg');
      fieldElement.classList.remove('error', 'make-error-msg-visible');
    }
  }

  public getStyles(orderFormElement: HTMLElement): ISqPaymentFormInputStyle {
    const orderFormItem = orderFormElement.querySelector(`[data-editable="${Component.ORDER_FORM_ITEM}"]`);

    const inlineStyles = orderFormItem.getAttribute('data-styles') || '';
    const styles: ISqPaymentFormInputStyle = {};

    if (orderFormItem) {
      const inputStyleContainer = orderFormItem.querySelector('.input-style-container');
      /**
       * I see no other option to adjust square field height
       */
      const orderFormItemStyles = window.getComputedStyle(orderFormItem);
      const { height, lineHeight } = orderFormItemStyles;
      const orderFormItemHeight = parseInt(height, 10);
      const orderFormItemLineHeight = parseInt(lineHeight, 10);
      /**
       * -1 to prevent covering borders
       */
      styles.padding = `${(orderFormItemHeight - orderFormItemLineHeight) / 2 - 1}px 5px`;

      if (inputStyleContainer) {
        const inputContainerStyles = window.getComputedStyle(inputStyleContainer);
        const { backgroundColor } = inputContainerStyles;

        styles.backgroundColor = backgroundColor;
      }
    }

    inlineStyles.split(';').forEach((item) => {
      if (item) {
        const itemStyle = item.split(':');

        if (allowedSquareStyles.includes(itemStyle[0] as keyof ISqPaymentFormInputStyle)) {
          styles[itemStyle[0]] = itemStyle[1];
        }
      }
    });

    if (styles.fontWeight) {
      styles.placeholderFontWeight = styles.fontWeight;
    }

    if (styles.color) {
      styles.placeholderColor = styles.color;
    }

    return styles;
  }

  public initializeOrderFormItem(orderFormItemElement: HTMLElement): void {
    const itemType = orderFormItemElement.getAttribute('data-item-type');
    const itemId = orderFormItemElement.id;
    const template = squareTemplate(itemId);

    if (!itemType) {
      // tslint:disable-next-line:no-console
      BaseLogger.log("Order form item needs 'data-item-type' to be specified.");
      return;
    }

    orderFormItemElement.innerHTML = template;
    this.fields.push({
      name: itemType,
      element: orderFormItemElement,
    });
  }

  public initializeFormOnLPCShow(orderFormElement: HTMLElement, applicationId: string, locationId: string): void {
    const oThis = this;
    this.paymentForm = new (window as any).SqPaymentForm(this.getSettings(orderFormElement, applicationId, locationId));

    if ((window as any).SqPaymentForm.isSupportedBrowser()) {
      document.addEventListener('DOMContentLoaded', () => {
        oThis.paymentForm.build();
        // oThis.paymentForm.recalculateSize();
      });
    }
  }

  public initializePostalCodeField(webformElement: HTMLElement): void {
    const webformItems: HTMLElement[] = Array.from(
      webformElement.querySelectorAll(`[data-editable="${Component.WEBFORM_NEW_ITEM}"]`),
    );
    const postalCodeItem = webformItems.find((element: HTMLElement) => {
      const elementType = element.getAttribute('data-item-name');
      return elementType === CustomField.POSTAL_CODE;
    });

    if (postalCodeItem) {
      const postalCodeField: HTMLDivElement = postalCodeItem.querySelector('[data-editable-item="field"]');
      const postalCodeInput: HTMLInputElement = postalCodeField.querySelector('input');

      const borderStyles: string = this.getInitPostalCodeFieldStyles(postalCodeInput);

      postalCodeField.innerHTML = squareTemplate(postalCodeInput.id, borderStyles);
      this.fields.push({
        name: SQUARE_ORDER_FORM_ITEM_TYPE.POSTAL_CODE,
        element: postalCodeItem,
      });
    }
  }

  private getInitPostalCodeFieldStyles(postalCodeInput: HTMLInputElement): string {
    const postalCodeInputStyles = window.getComputedStyle(postalCodeInput);
    const { borderTop, borderBottom, borderLeft, borderRight } = postalCodeInputStyles;
    let borderValue = '';

    if (borderTop) {
      borderValue += `border-top: ${borderTop};`;
    }
    if (borderBottom) {
      borderValue += `border-bottom: ${borderBottom};`;
    }
    if (borderRight) {
      borderValue += `border-right: ${borderRight};`;
    }
    if (borderLeft) {
      borderValue += `border-left: ${borderLeft};`;
    }

    return borderValue;
  }

  private initializeSquarePostalCode(orderFormElement: HTMLElement): ISqPaymentFormPlaceholder {
    const postalCodeItem: HTMLElement = this.getFieldFromOrderFormParent(orderFormElement, CustomField.POSTAL_CODE);

    if (postalCodeItem) {
      const postalCodeLabel: HTMLDivElement = postalCodeItem.querySelector('[data-editable-item="label"]');
      const postalCodeTargetId: string = postalCodeLabel.id;

      if (!!postalCodeTargetId) {
        return {
          elementId: `square-id-${postalCodeTargetId}-for`,
          placeholder: '',
        };
      }
    }
    return false;
  }

  private getOrderFormElementFieldId(orderFormElement: HTMLElement, fieldType: string): string {
    const field: any = orderFormElement.querySelector(`[data-item-type="${fieldType}"]`);
    return field ? field.id : '';
  }

  private getFieldFromOrderFormParent(orderFormElement: HTMLElement, fieldType: string): HTMLElement {
    const parentElement: HTMLElement = orderFormElement.parentElement;
    const webformItems: HTMLElement[] = Array.from(
      parentElement.querySelectorAll(`[data-editable="${Component.WEBFORM_NEW_ITEM}"]`),
    );

    return webformItems.find((element: HTMLElement) => {
      const elementType = element.getAttribute('data-item-name');
      return elementType === fieldType;
    });
  }

  private createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: 'USD',
      countryCode: 'US',
      total: {
        label: 'MERCHANT NAME',
        amount: '100',
        pending: false,
      },
      lineItems: [
        {
          label: 'Subtotal',
          amount: '100',
          pending: false,
        },
      ],
    };
  };

  private cardNonceResponseReceived = (
    errors: ICardNonceResponseErrors[],
    nonce: string,
    cardData: ICardNonceResponseCardData,
  ) => {
    if (errors) {
      this.requestCardNonceErrors = errors;
      return;
    }
    this.nonce = nonce;
  };

  private inputEventReceived = (inputEvent: ISqPaymentFormInputEvent) => {
    switch (inputEvent.eventType) {
      case SquareInputEventType.POSTAL_CODE_CHANGED:
        this.postalCode = inputEvent.postalCodeValue;
        break;
      case SquareInputEventType.ERROR_CLASS_REMOVED:
        this.unSetFieldErrorMessage(inputEvent.field);
        break;
      default:
        break;
    }
  };

  private unsupportedBrowserDetected = () => {
    // do nothing
  };

  private paymentFormLoaded = () => {
    // do nothing
  };

  private getSettings(orderFormElement: HTMLElement, applicationId: string, locationId: string): ISqPaymentFormConfig {
    const oThis = this;
    const defaultInputStyles: ISqPaymentFormInputStyle = {
      backgroundColor: 'transparent',
      fontFamily: 'sans-serif',
      fontWeight: '700',
      placeholderFontWeight: '700',
      color: '#525252',
      placeholderColor: '#525252',
      fontSize: '18px',
      lineHeight: '24px',
      padding: '5.5px 5px',
      _webkitFontSmoothing: 'antialiased',
      _mozOsxFontSmoothing: 'grayscale',
    };
    const inputStyles: ISqPaymentFormInputStyle = { ...defaultInputStyles, ...this.getStyles(orderFormElement) };

    return {
      applicationId,
      locationId,
      inputClass: 'sq-input',
      autoBuild: false,

      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [inputStyles],

      // Initialize Apple Pay placeholder ID
      applePay: false,

      // Initialize Masterpass placeholder ID
      masterpass: false,

      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'square-id-' + this.getOrderFormElementFieldId(orderFormElement, ORDER_FORM_ITEM_TYPE.CARD_NUMBER),
        placeholder: '',
      },
      cvv: {
        elementId: 'square-id-' + this.getOrderFormElementFieldId(orderFormElement, ORDER_FORM_ITEM_TYPE.CARD_CVC),
        placeholder: '',
      },
      expirationDate: {
        elementId: 'square-id-' + this.getOrderFormElementFieldId(orderFormElement, ORDER_FORM_ITEM_TYPE.CARD_EXPIRY),
        placeholder: '',
      },
      postalCode: this.initializeSquarePostalCode(orderFormElement),

      // SqPaymentForm callback functions
      callbacks: {
        /*
         * callback function: createPaymentRequest
         * Triggered when: a digital wallet payment button is clicked.
         * Replace the JSON object declaration with a function that creates
         * a JSON object with Digital Wallet payment details
         */
        createPaymentRequest: oThis.createPaymentRequest,

        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: oThis.cardNonceResponseReceived,

        /*
         * callback function: unsupportedBrowserDetected
         * Triggered when: the page loads and an unsupported browser is detected
         */
        unsupportedBrowserDetected: oThis.unsupportedBrowserDetected,

        /*
         * callback function: inputEventReceived
         * Triggered when: visitors interact with SqPaymentForm iframe elements.
         */
        inputEventReceived: oThis.inputEventReceived,

        /*
         * callback function: paymentFormLoaded
         * Triggered when: SqPaymentForm is fully loaded
         */
        paymentFormLoaded: oThis.paymentFormLoaded,
      },
    };
  }
}
