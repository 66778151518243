/* tslint:disable:max-line-length */
import { LinkType } from '../../../enums/linkType';
import { Component } from '../../../enums/components';

import { LPSData } from '../models/config';

import { Navigate } from '../../../actions/navigate';

import { OtoPage } from '../prototypes/otoPage';

import { isJoinTheProgramLink } from '../helpers/link';
import { getClosestParentDataAttr, scrollToElementOrRedirect } from '../helpers/dom';
import { logger } from '../helpers/logger';

export const linksModifier = {
  isPreview: false,
  initialize(isPreview: boolean = false): void {
    document.body.addEventListener('click', linksModifier._handleBodyClick, false);
    linksModifier.isPreview = isPreview;
  },

  _handleBodyClick(e: MouseEvent): void {
    const target = e.target as Node;
    const targetNodeName = target.nodeName;

    switch (targetNodeName) {
      case 'A':
      case 'SPAN':
        linksModifier._handleAnchorClick(e);
        break;
      case 'IMG':
        linksModifier._handleImageClick(e);
        break;
    }
  },

  _handleImageClick(e: MouseEvent): void {
    const imageElement = e.target as HTMLImageElement;
    const componentType = linksModifier._getComponentType(imageElement);
    const imageParentElement = imageElement.parentNode as HTMLAnchorElement;
    const href = imageParentElement.getAttribute('href');

    if (componentType === Component.IMAGE) {
      scrollToElementOrRedirect(e, href);
    }
  },

  _handleAnchorClick(e: MouseEvent): void {
    const anchorElement = e.target as HTMLAnchorElement;
    const componentType = linksModifier._getComponentType(anchorElement);
    const linkType = anchorElement.getAttribute('data-link-type') as LinkType;

    if (isJoinTheProgramLink(anchorElement)) {
      return linksModifier._handleJoinTheProgramClick(e);
    }

    switch (linkType) {
      case LinkType.OTO_PAGE_ACCEPT:
        return linksModifier._handleOTOPageAcceptClick(e);
      case LinkType.OTO_PAGE_DISMISS:
        return linksModifier._handleOTOPageDismissClick(e);
    }

    if (componentType === Component.PAYPAL_BUTTON) {
      return;
    }

    switch (componentType) {
      case Component.BUTTON:
        return linksModifier._handleButtonClick(e);
      case Component.TEXT:
        return linksModifier._handleTextClick(e);
    }
  },

  _handleJoinTheProgramClick(e: MouseEvent): void {
    e.preventDefault();
    const orderFormUrl = LPSData.getOrderFormUrl();

    if (orderFormUrl && orderFormUrl.length > 1) {
      Navigate.toExternal(LPSData.getOrderFormUrl());
    } else {
      logger.warn('Missing order form url');
    }
  },

  _handleOTOPageAcceptClick(e: MouseEvent): void {
    e.preventDefault();
    const anchorElement = e.target as HTMLAnchorElement;
    const anchorWrapper = anchorElement.parentNode as HTMLDivElement;

    if (anchorWrapper.classList.contains('processing') || !LPSData.isOtoPageActionEnabled()) {
      return;
    }

    if (!linksModifier.isPreview) {
      const productId = getClosestParentDataAttr(anchorElement, 'data-product-id');
      OtoPage.startOtoPageProcessPayment(productId);
    }
  },

  _handleOTOPageDismissClick(e: MouseEvent): void {
    e.preventDefault();
    linksModifier.skipOTOPage();
  },
  skipOTOPage(): void {
    const otoPageDismissUrl = LPSData.getOTOPageDismissUrl();

    if (!LPSData.isOtoPageActionEnabled()) {
      return;
    }
    if (otoPageDismissUrl && otoPageDismissUrl.length > 1) {
      Navigate.toExternal('?skip=1');
    }
  },

  _handleButtonClick(e: MouseEvent): void {
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute('href');

    scrollToElementOrRedirect(e, href);
  },

  _handleTextClick(e: MouseEvent): void {
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute('href');

    scrollToElementOrRedirect(e, href);
  },

  _getComponentType(element: HTMLElement): Component {
    const editableAttribute = getClosestParentDataAttr(element, 'data-editable');

    switch (editableAttribute) {
      case Component.BUTTON:
        return Component.BUTTON;
      case 'paypalbutton':
        return Component.PAYPAL_BUTTON;
      case Component.TEXT:
        return Component.TEXT;
      case 'multimedia:image':
        return Component.IMAGE;
      default:
        logger.warn('Unhandled component type');
    }

    return null;
  },
};
