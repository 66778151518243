import $ from 'jquery';

const phoneCustom = {
  $body: null,
  $curtain: null,
  $forms: null,
  $list: null,
  opened: false,
  phonePrefixData: null,

  initialize: function (phonePrefixData) {
    this.$body = $(document.body);
    this.$forms = this.$body.find('[data-editable="webformNew"], [data-editable="webinarNew"]');

    this.phonePrefixData = phonePrefixData.sort(function (a, b) {
      if (a.cc === 'us') return -1;
      if (b.cc === 'us') return 1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    //phonePrefixData
    this.initializeEventListeners();
  },

  initializeEventListeners: function () {
    var stashedValue = '';

    this.$forms.on(
      'click',
      '[data-feature-part="directional"]',
      function (e) {
        var $directional = $(e.currentTarget);
        var $target = $(e.target);
        var $webformNewItemPhone = $target.parents('[data-editable="webformNewItem"][data-item-what="phone"]');

        if ($webformNewItemPhone.attr('data-disabled') === 'true') {
          //Don't open when element is disabled
          return;
        }

        if ($target.parents('[data-editable-item="label"]').length) {
          //Don't open the list when user clicked on the label
          return;
        }

        var $editable = $directional.parents('[data-editable="webformNewItem"]');
        var $form = $directional.parents('[data-editable="webformNew"], [data-editable="webinarNew"]');

        this.toggleList($form, $editable);
      }.bind(this),
    );

    if (!this.getAndroidVersion()) {
      this.$forms.on(
        'focus',
        '[data-editable="webformNewItem"][data-item-what="phone"] input',
        function (e) {
          //Stash current value when input is focused
          stashedValue = this.getPureNumber($(e.currentTarget).val());
        }.bind(this),
      );

      this.$forms.on(
        'keyup',
        '[data-editable="webformNewItem"][data-item-what="phone"] input',
        function (e) {
          const $input = $(e.currentTarget);
          const cc = $input.attr('data-country-code');
          const format = this.getFormat(cc);
          const inputWithoutBreak = $input.val().replace(/\(|\)|-|\s/g, '');

          //Backspace or delete
          if (e.keyCode === 8 || e.keyCode === 46) {
            stashedValue = stashedValue.substr(0, Math.min(stashedValue.length - 1, $input.val().length));
            //stashedValue = stashedValue.substr(0, $input.val().length);
          } else if (e.keyCode === 13 || e.keyCode === 9) {
            //do nothing for enter and tab
          } else if (e.key && e.key.length === 1) {
            stashedValue = inputWithoutBreak;
          } else {
            // handle autofill
            stashedValue = inputWithoutBreak;
          }

          //Dont allow to store more characters
          const numberOfAllowedChars = format.replace(/\(|\)|-|\s/g, '').length || Infinity; //if length is 0, it means that there is no format prepared, take all characters
          stashedValue = stashedValue.substr(0, numberOfAllowedChars);
          const newValue = this.getFormattedNumber(stashedValue, format);

          $input.val(newValue);

          $input
            .parents('[data-editable-item="field"]')
            .find('[data-label-type="placeholder"] [data-editable-item="label"]')
            .toggle(newValue === '');
        }.bind(this),
      );
    } else {
      this.$forms.on(
        'focusout',
        '[data-editable="webformNewItem"][data-item-what="phone"] input',
        function (e) {
          var $input = $(e.currentTarget);
          var cc = $input.attr('data-country-code');
          var format = this.getFormat(cc);
          var stashedValue = this.getPureNumber($(e.currentTarget).val());
          var numberOfAllowedChars = format.replace(/\(|\)|-|\s/g, '').length || Infinity; //if length is 0, it means that there is no format prepared, take all characters
          stashedValue = stashedValue.substr(0, numberOfAllowedChars);
          var newValue = this.getFormattedNumber(stashedValue, format);

          $input.val(newValue);

          $input
            .parents('[data-editable-item="field"]')
            .find('[data-label-type="placeholder"] [data-editable-item="label"]')
            .toggle(newValue === '');
        }.bind(this),
      );
    }
  },

  getAndroidVersion: function () {
    var ua = navigator.userAgent.toLowerCase();
    var match = ua.match(/android\s([0-9\.]*)/);
    return match ? match[1] : false;
  },

  getPureNumber: function (number) {
    return number.replace(/\(|\)|-|\s/g, '');
  },

  getFormat: function (cc) {
    const phonePrefixData = this.phonePrefixData;

    for (var i = 0; i < phonePrefixData.length; i++) {
      if (phonePrefixData[i].cc === cc) {
        return phonePrefixData[i].format || '';
      }
    }
    return '';
  },

  toggleList: function ($form, $editable) {
    if (this.opened) {
      this.hideList($editable);
    } else {
      this.buildList($form, $editable);
    }
  },

  buildList: function ($form, $editable) {
    var str =
      '<div id="custom-phone-curtain"><div class="custom-phone-list-wrapper" data-feature-part="list-wrapper"><ul class="custom-phone-list">';
    var $directional = $editable.find('[data-feature-part="directional"]');
    var phonePrefixData = this.phonePrefixData;

    var selectedCC = $directional.attr('data-country-code');

    for (var i = 0; i < phonePrefixData.length; i++) {
      var rowData = phonePrefixData[i];

      str +=
        '<li data-feature-part="list-item" data-country-code="' +
        rowData.cc +
        '" data-format="' +
        (rowData.format || '') +
        '" data-code="' +
        rowData.code +
        '" class="custom-phone-list-item ' +
        (selectedCC === rowData.cc ? 'selected' : '') +
        '">' +
        '<label>' +
        '<input type="radio" name="unique_name" value="' +
        rowData.cc +
        '">' +
        '<span class="custom-phone-list-item-label" data-flag="' +
        rowData.cc +
        '">' +
        rowData.name +
        ' (+' +
        rowData.code +
        ')</span>' +
        '</label>' +
        '</li>';
    }

    str += '</div></div>';

    this.$body.append(str);
    this.$curtain = this.$body.find('#custom-phone-curtain');

    this.$list = this.$curtain.find('[data-feature-part="list-wrapper"]');

    var pos = $editable.offset();
    var width = $editable.width();
    var height = $editable.height();

    $editable.addClass('opened');

    this.$list.css({
      top: pos.top + height,
      left: pos.left,
      width: width,
    });

    this.$list.on(
      'click',
      '[data-feature-part="list-item"]',
      function (e) {
        var $item = $(e.currentTarget);
        var format = $item.data('format');
        var $input = $editable.find('[data-editable-item="field"] input');
        var $directional = $editable.find('[data-feature-part="directional"] .directional');

        var cc = $item.data('country-code');

        $directional.find('[data-feature-part="directional-container"]').html('+' + $item.data('code'));
        $directional.attr('data-country-code', cc);
        $directional.attr('data-flag', cc);

        $input.attr('data-country-code', cc);

        $input.val(this.getFormattedNumber(this.getPureNumber($input.val()), this.getFormat(cc)));
      }.bind(this),
    );

    this.$curtain.on(
      'click',
      function () {
        this.hideList($editable);
      }.bind(this),
    );

    this.opened = true;
  },

  hideList: function ($editable) {
    this.$list.off();
    this.$curtain.off().remove();
    this.opened = false;

    $editable.removeClass('opened');
  },

  getFormattedNumber: function (number, format) {
    return this.formatNumber(this.clear(number), format);
  },

  formatNumber: function (number, format) {
    var onlyFormatersChar = false;
    if (!format) {
      return number;
    }
    number = String(number || '').split('');
    return format.replace(/./g, function (char) {
      var ret = '';
      if (!number.length) {
        if (onlyFormatersChar && 'X' !== char) {
          return char;
        }
        onlyFormatersChar = false;
        return '';
      }
      ret = char;
      if ('X' === char) {
        ret = number.shift();
        if (!number.length) {
          onlyFormatersChar = true;
        }
      }
      return ret;
    });
  },

  clear: function (number) {
    var stringToNumber = {
      a: 2,
      b: 2,
      c: 2,
      d: 3,
      e: 3,
      f: 3,
      g: 4,
      h: 4,
      i: 4,
      j: 5,
      k: 5,
      l: 5,
      m: 6,
      n: 6,
      o: 6,
      p: 7,
      q: 7,
      r: 7,
      s: 7,
      t: 8,
      u: 8,
      v: 8,
      w: 9,
      x: 9,
      y: 9,
      z: 9,
    };

    return String(number).replace(/((\d)|(.))/g, function (all, char, number, otherChar) {
      if (number) {
        return number;
      }
      if (otherChar) {
        if (/[a-zA-Z]/.test(otherChar)) {
          otherChar = otherChar.toLowerCase();
          return stringToNumber[otherChar] || '';
        }
        return '';
      }
    });
  },
};

export default phoneCustom;
