export default {
  enableScripts: () => {
    const isCustomCodeAllowed = window.grLpsInitialData.isCustomCodeAllowed;

    if (!isCustomCodeAllowed) {
      $('[data-editable="customCode"]').remove();
    } else {
      //Hide custom codes elements on show
      $('[data-editable="customCode"] [data-editable-item="caption-wrapper"]').remove();

      $('[data-editable="customCode"] [data-editable-item="code"]').each(function (index, code) {
        var $code = $(code);

        //First parse string to HTML
        $code.html($code.text());
        //Then show HTML to don't show '<script>' tags etc..
        $code.css({
          display: 'block',
        });
      });
    }
  },
};
