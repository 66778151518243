import { TrackingCodePlatformName } from '../../../enums/trackingCodePlatformName';
import { ILpsShowInitialData } from '../../../interfaces/lpsShow';

import { LPSData } from '../models/config';

import { logger } from './logger';

export class TrackingHelper {
  public static sendTrackingEvent(): void {
    Object.keys(LPSData.getTrackingCodes()).forEach((platform: TrackingCodePlatformName) => {
      switch (platform) {
        case TrackingCodePlatformName.FACEBOOK_PIXEL:
          TrackingHelper.sendFacebookPixelEvent();
          break;
        case TrackingCodePlatformName.GOOGLE_ANALYTICS:
          TrackingHelper.sendGoogleAnalyticsEvent();
          break;
        case TrackingCodePlatformName.MIXPANEL:
          TrackingHelper.sendMixPanelEvent();
          break;
        case TrackingCodePlatformName.GOOGLE_TAG_MANAGER:
          TrackingHelper.sendGoogleTagManagerEvent();
          break;
      }
    });
  }

  public static sendAdWordsEvent(callback?: () => void): void {
    const hasCallback = typeof callback === 'function';
    const grLpsInitialData = window.grLpsInitialData as ILpsShowInitialData;

    if (TrackingHelper.isGoogleAdWordsEnabled()) {
      const img = new Image();

      if (hasCallback) {
        img.onload = () => {
          callback();
        };
      }

      img.src = `//www.googleadservices.com/pagead/conversion/${grLpsInitialData.google_adwords_id}/?label=${grLpsInitialData.google_adwords_conversion_label}&guid=ON&script=0`;
    } else if (hasCallback) {
      callback();
    }
  }

  public static hasInitialData(): any {
    return LPSData.getInitialData();
  }

  public static isGoogleAdWordsEnabled(): boolean {
    return (
      TrackingHelper.hasInitialData() &&
      LPSData.getInitialData().google_adwords_id !== '' &&
      LPSData.getInitialData().google_adwords_conversion_label !== ''
    );
  }

  private static sendFacebookPixelEvent(): void {
    const { fbq } = window;
    if (fbq && typeof fbq === 'function') {
      fbq('track', 'Lead');
    } else {
      logger.warn('Missing Facebook Pixel Script');
    }
  }

  private static sendGoogleAnalyticsEvent(): void {
    const { gtag } = window;
    if (gtag && typeof gtag === 'function') {
      gtag('event', 'sign_up', {
        method: 'GetResponse Landing Page',
      });
    } else {
      logger.warn('Missing Google Analytics Script');
    }
  }

  private static sendMixPanelEvent(): void {
    const { mixpanel } = window;
    if (mixpanel && mixpanel.track && typeof mixpanel.track === 'function') {
      mixpanel.track('lead', {
        form: 'register',
        lps_name: document.title,
      });
    } else {
      logger.warn('Missing MixPanel Script');
    }
  }

  private static sendGoogleTagManagerEvent(): void {
    const { dataLayer } = window;
    if (Array.isArray(dataLayer)) {
      window.dataLayer.push({
        event: 'Lead',
      });
    } else {
      logger.warn('Missing Google Tag Manager Script');
    }
  }
}
