import * as $ from 'jquery';
import { OfferFormElement } from './offerFormElement';

import AutoFunnelHelper from '../../helpers/autofunnel';
import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';
import helperForm from '../../helpers/form';

import { LPSData } from '../../models/config';
import { l10n } from '../../helpers/l10n';
import { translationKeys } from '../../../editor/constants/translationKeys';

import { CustomField } from '../../../../enums/custom_field';

export class SquareFormElement extends OfferFormElement {
  private cachedUuid: string = null; // Store Uuid to don't create new transactions every time

  public proceed(): void {
    const serializedData = $(this.form).serializeArray();

    logger.log('Starting Square process');
    const formData = this.getFormRequestData();
    this.orderForm
      .getCardNonce()
      .then((cardNonce) => {
        const squarePaymentData = JSON.stringify({
          ...formData,
          products: this.productList.getSelectedProducts(),
          cartId: LPSData.getAutoFunnelCartId(),
          uuid: this.cachedUuid,
          token: cardNonce,
        });

        AutoFunnelHelper.startProcessPayment(squarePaymentData, LPSData.getProcessPaymentUrl(), (paymentResponse) => {
          this.proceedWithSubscription(
            () => {
              const { code, message } = paymentResponse;
              const isError = code && message;
              // Store Uuid to don't create new transactions every time
              if (paymentResponse.uuid) {
                this.cachedUuid = paymentResponse.uuid;
              }

              if (isError) {
                const translation = l10n().getTranslation(translationKeys[code]) || message;
                notify.alert(translation);
                this.enableForm();
              } else {
                AutoFunnelHelper.checkPaymentStatus(paymentResponse.uuid, this.checkPaymentStatusCallback.bind(this));
              }
            },
            null,
            serializedData,
          );
        });
      })
      .catch((errors) => {
        if (errors) {
          errors.forEach((error) => {
            (this.orderForm.strategy as any).setFieldErrorMessage(error.field, error.message);
          });
        }
        this.enableForm();
      });
  }

  protected getFormRequestData(): any {
    const dataToSend = helperForm.getDataToSend(this.form);
    if (this.orderForm && LPSData.hasSquareIntegration()) {
      /**
       * We need to get postal code from square field
       */
      dataToSend[`custom_${CustomField.POSTAL_CODE}`] = this.orderForm.getPostalCode();
    }

    return dataToSend;
  }
}
