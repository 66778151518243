import { Component } from '../enums/components';

export class ZIndexHelper {
  public static getSortedComponents(
    components: any[],
    getRectFn: any,
    getTypeFn: any,
    getForcedPositionFn: any,
  ): any[] {
    return components.sort((a, b) => {
      const rectA = getRectFn(a);
      const rectB = getRectFn(b);
      const forcedPositionA = getForcedPositionFn(a);
      const forcedPositionB = getForcedPositionFn(b);

      if (forcedPositionA || forcedPositionB) {
        return forcedPositionB - forcedPositionA;
      } else {
        const topA = rectA.top;
        const topB = rectB.top;
        const bottomA = topA + rectA.height;
        const bottomB = topB + rectB.height;

        /**
         * Button should be always last
         */
        if (getTypeFn(a) === Component.WEBFORM_NEW_BUTTON) {
          return -1;
        } else if (getTypeFn(b) === Component.WEBFORM_NEW_BUTTON) {
          return 1;
        }

        if (topA >= bottomB) {
          return -1;
        }
        if (bottomA <= topB) {
          return 1;
        }
        return rectB.left - rectA.left;
      }
    });
  }

  public static getAscendingSortedComponents(components: any[], getRectFn: any, getTypeFn: any): any[] {
    return components.sort((a, b) => {
      const rectA = getRectFn(a);
      const rectB = getRectFn(b);
      const diff = rectA.top - rectB.top;

      //When there are two elements with the same position, and one of it is a button, then give priority to the item
      if (diff === 0) {
        if (getTypeFn(a) === Component.WEBFORM_NEW_BUTTON) {
          return rectB.top + 1;
        } else if (getTypeFn(b) === Component.WEBFORM_NEW_BUTTON) {
          return rectA.top + 1;
        }
      } else {
        return diff;
      }
    });
  }

  public static getDescendingSortedComponents(components: any[], getRectFn: any, getTypeFn: any): any[] {
    return components.sort((a, b) => {
      const rectA = getRectFn(a);
      const rectB = getRectFn(b);
      const diff = rectB.top - rectA.top;

      //When there are two elements with the same position, and one of it is a button, then give priority to the item
      if (diff === 0) {
        if (getTypeFn(a) === Component.WEBFORM_NEW_BUTTON) {
          return rectB.top + 1;
        } else if (getTypeFn(b) === Component.WEBFORM_NEW_BUTTON) {
          return rectA.top + 1;
        }
      } else {
        return diff;
      }
    });
  }
}
