import { Component } from '../../../enums/components';
import { BaseLogger } from '../../../helpers/logger';
import tplStyleContainer from '../components/order_form_item/templates/order_form_style_container_template.html';
import { ORDER_FORM_ITEM_TYPE } from '../enums/order_form_item_types';
import { StringHelper } from './stringHelper';

export default {
  _getSettings: function (callbacks, styles) {
    const settings = {
      '3DS': false,
      onFieldEventHandler: {
        onFocus: callbacks.onFocus || function (tagId) {}, // Handle focus
        onBlur: callbacks.onBlur || function (tagId) {}, // Handle blur
        onError: callbacks.onError || function (tagId, errorCode /*, errorDescription*/) {}, // Handle a change in validation

        onType:
          callbacks.onType ||
          function (tagId, cardType, cardData) {
            /* cardType will give card type, and only applies to ccn: CarteBleue, Visa, MasterCard, AmericanExpress, Discover, DinersClub, JCB, Solo, MaestroUK, ChinaUnionPay */
            if (null != cardData) {
              /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType and binCategory details (only applies to ccn) in a JsonObject */
              BaseLogger.log(cardData);
            }
          },

        onValid: callbacks.onValid || function (tagId) {}, // Handle a change in validation
      },

      style: {
        ':focus': {
          //"color": "orange"
        },
        input: Object.assign(
          {},
          {
            color: '#7f7f7f',
            'font-size': '14px',
          },
          styles,
        ),
        '.invalid': {
          color: '#eb1c26',
        },
        '::placeholder': Object.assign(
          {},
          {
            color: '#7f7f7f',
            'font-size': '14px',
          },
          styles,
        ),
      },
      //ccnPlaceHolder: "1234 5678 9012 3456", //for example
      //cvvPlaceHolder: "123", //for example
      //expPlaceHolder: "MM/YY", //for example
      //expDropDownSelector: false //set to true for exp. date dropdown
    };

    return settings;
  },
  initializeForm: function (orderFormController, token) {
    const orderFormItems = orderFormController.getChildrenComponents().filter((orderFormItem) => {
      return orderFormItem.getType() === Component.ORDER_FORM_ITEM;
    });

    const styles = this.getStyles(orderFormItems[0].getElementNode());
    const settings = this._getSettings({}, styles);

    orderFormController.getIFrameWindowScope().APP.blueSnapIntegration.initializeForm(token, settings);
  },

  initializeFormOnLPCShow: function (orderFormElement, token, callbacks) {
    const orderFormItemElement = orderFormElement.querySelector('[data-editable="orderFormItem"]');
    const styles = this.getStyles(orderFormItemElement);
    const settings = this._getSettings(callbacks, styles);

    //Run the following command after Document Object Model (DOM) is fully loaded
    window.bluesnap.hostedPaymentFieldsCreation(token, settings);
  },

  initializeOrderFormItem: function (orderFormItemElement) {
    const orderFormItemType = orderFormItemElement.getAttribute('data-item-type');

    let styleContainer = orderFormItemElement.querySelector('[data-editable-item="container"]');

    if (!styleContainer) {
      //Need it to simulate styles for order form item
      const editableElement = orderFormItemElement.querySelector('.editable');

      if (editableElement) {
        //In editor
        editableElement.innerHTML = tplStyleContainer;
      } else {
        //On show
        orderFormItemElement.innerHTML = tplStyleContainer;
      }

      styleContainer = orderFormItemElement.querySelector('[data-editable-item="container"]');
    }

    styleContainer.setAttribute('data-bluesnap', this.getBlueSnapAttributeValue(orderFormItemType));
  },

  getBlueSnapAttributeValue: function (fieldType) {
    switch (fieldType) {
      case ORDER_FORM_ITEM_TYPE.CARD_NUMBER:
        return 'ccn';
      case ORDER_FORM_ITEM_TYPE.CARD_CVC:
        return 'cvv';
      case ORDER_FORM_ITEM_TYPE.CARD_EXPIRY:
        return 'exp';
    }
  },

  getStyles: function (orderFormItemElement) {
    const inlineStyles = orderFormItemElement.getAttribute('data-styles') || '';
    const styles = {};

    const allowedStyles = [
      'color',
      'font',
      'font-family',
      'font-size',
      'font-style',
      'font-weight',
      'line-height',
      'opacity',
      'outline',
      'text-shadow',
      'transition',
      'left',
      'margin-right',
      'width',
      'height',
      'background-color',
    ];

    inlineStyles.split(';').forEach((item) => {
      if (item) {
        const itemStyle = item.split(':');
        const styleName = StringHelper.camelCaseToDashCase(itemStyle[0]);
        const styleValue = itemStyle[1];

        if (allowedStyles.indexOf(styleName) > -1) {
          styles[styleName] = styleValue;
        }
      }
    });

    return styles;
  },
};
