import { ViewingMode } from '../../../enums/viewingMode';

var viewportSizes = {};

/**
 * IMPORATANT!!!
 *
 * Due to problems with insertRule there are some hacks targeting mobile view placed in the end of core.css.
 */

viewportSizes[ViewingMode.DESKTOP] = {
  workspace: 980, // default one, can be changed with attribute <body data-workspace-desktop-width="123"
  viewport: '100%',
};

viewportSizes[ViewingMode.PHONE] = {
  workspace: 400, // default one, can be changed with attribute <body data-workspace-phone-width="123"
  viewport: 600, // Używany dla media-queries
};

export default {
  getViewportWidth(viewingMode) {
    return viewportSizes[viewingMode].viewport;
  },

  getWorkspaceWidth(viewingMode) {
    return viewportSizes[viewingMode].workspace;
  },
};
