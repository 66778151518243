import AWN from 'awesome-notifications';
import '../styles/awesome-notifications/style.less';

import constantsGrowlerStatuses from '../constants/growlerStatuses';
import constantsGrowlerIcons from '../constants/growlerIcons';
import { TranslationKey } from '../../../interfaces/lpsShow';

import { l10n } from './l10n';

export interface IGrowlerCore {
  success(message: string): void;

  info(message: string): void;

  warning(message: string): void;

  alert(message: string): void;
}

class Growler {
  private growlerCore: IGrowlerCore;

  constructor(growlerCore: IGrowlerCore) {
    this.growlerCore = growlerCore;
  }

  public success(message: string): void {
    this.growlerCore.success(message);
  }

  public info(message: string): void {
    this.growlerCore.info(message);
  }

  public warning(message: string): void {
    this.growlerCore.warning(message);
  }

  public alert(message: string): void {
    this.growlerCore.alert(message);
  }
}

/*
 * top-center is our custom positioning
 */
export const notify = new Growler(
  new AWN({
    position: 'top-center',
    icons: {
      enabled: false,
      prefix: '<span data-part="icon" class="gr-growler-lps-show-icon">',
      success: constantsGrowlerIcons[constantsGrowlerStatuses.SUCCESS],
      suffix: '</span>',
    },
    labels: {
      info: l10n().getTranslation(TranslationKey.ModSqueezePageLPCCreatePageGrowlerInfoName),
      success: l10n().getTranslation(TranslationKey.ModSqueezePageLPCCreatePageGrowlerSuccessName),
      warning: l10n().getTranslation(TranslationKey.ModSqueezePageLPCCreatePageGrowlerWarningName),
      alert: l10n().getTranslation(TranslationKey.ModSqueezePageLPCCreatePageGrowlerErrorName),
    },
  }),
);
