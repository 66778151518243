import { OtoPageStrategy } from './otoPage';

import { OtoPage } from '../../prototypes/otoPage';

import { Navigate } from '../../../../actions/navigate';

import { logger } from '../../helpers/logger';
import { notify } from '../../helpers/growler';
import AutoFunnelHelper from '../../helpers/autofunnel';

interface IPaymentResponse {
  uuid: string;
  url: string;
  error?: string;
}

export class PayPalOtoPageStrategy extends OtoPageStrategy {
  public proceedPayment(otoPageAcceptUrl: string, productId: string): void {
    AutoFunnelHelper.startProcessPayment(this.getOTOPaymentData(productId), otoPageAcceptUrl, this.paymentDoneCallback);
  }

  private paymentDoneCallback(response: IPaymentResponse): void {
    if (response.error) {
      notify.alert(response.error);
      OtoPage.enableProductBoxButtons();
    } else {
      if (response.uuid) {
        AutoFunnelHelper.checkPaymentStatus(response.uuid, AutoFunnelHelper.checkPaymentStatusCallback.bind(this));
      } else if (response.url) {
        Navigate.toExternal(response.url);
      } else {
        logger.info('Unexpected case');
        OtoPage.enableProductBoxButtons();
      }
    }
  }
}
