import $ from 'jquery';
import { FieldValidityType } from '../enums/fieldValidityType';
import Handles from './handles';
import AutoFunnelHelper from '../helpers/autofunnel';
import { notify } from '../helpers/growler';

/**
 * @param fieldElement {FieldElement}
 */
function removeFieldBlocker(fieldElement) {
  // field blocker is necessary for editor, but not for user on showpage
  const fieldBlocker = fieldElement.parentNode.querySelector('.field-blocker');

  if (fieldBlocker) {
    fieldBlocker.parentNode.removeChild(fieldBlocker);
  }
}

function FieldElement(element) {
  const oThis = this;
  const attrs = Array.prototype.slice.call(element.attributes) || [];
  const rect = element.getBoundingClientRect();

  this.element = element;
  this.parentNode = this.element.parentNode;
  this.rect = {
    top: rect.top,
  };

  removeFieldBlocker(this);

  Object.defineProperties(this, {
    validationMessage: {
      get() {
        return this.element.getAttribute('data-error-msg');
      },
      set(value) {
        this.element.setAttribute('data-error-msg', value);
      },
      enumerable: true,
    },
  });

  attrs.forEach((attr) => {
    if (['type', 'name', 'id'].includes(attr.name)) {
      oThis[attr.name] = attr.value;
    }
  });

  new Handles(this);

  this.fillField();
}

FieldElement.prototype = Object.create({
  on(name, action) {
    if (!this.observer) {
      this.observer = {};
    }

    if (!this.observer[name]) {
      this.observer[name] = [];
    }

    if (this.observer[name].includes(action)) {
      this.observer[name][this.observer[name].indexOf(action)] = action;
    } else {
      this.observer[name].push(action);
    }
  },
  off(name, action) {
    var ix;

    if (!this.observer) {
      return;
    }
    if (!this.observer[name]) {
      return;
    }

    ix = this.observer[name].indexOf(action);

    if (ix > -1) {
      this.observer[name].splice(ix, 1);
    }
  },
  notify(name) {
    var oThis = this,
      args;
    if (!this.observer) {
      return;
    }

    if (!this.observer[name]) {
      return;
    }

    args = Array.prototype.slice.call(args);

    this.observer[name].forEach((fn) => {
      fn.apply(oThis, args);
    });
  },
});

FieldElement.prototype.fillField = function fillField() {
  const elementName = this.element.name;
  const elementValue = this.element.value;
  const elementType = this.element.type;
  const elementTagName = this.element.tagName;
  let autofillValue;

  if (elementTagName === 'INPUT') {
    if (elementType === 'text' || elementType === 'email') {
      autofillValue = AutoFunnelHelper.getTextFieldAutoFillValue(elementName);

      if (autofillValue) {
        this.element.value = autofillValue;
      }
    } else if (elementType === 'checkbox' || elementType === 'radio') {
      autofillValue = AutoFunnelHelper.getProductFieldAutoFillChecked(elementName, elementValue);

      if (autofillValue) {
        this.element.checked = autofillValue;
      }
    }
  } else if (elementTagName === 'SELECT') {
    autofillValue = AutoFunnelHelper.getTextFieldAutoFillValue(elementName);

    if (autofillValue) {
      $(this.element).val(autofillValue);
    }
  }
};

/**
 *
 * @param msg {string}
 * @param validityType {FieldValidityType}
 * @return {boolean}
 */
FieldElement.prototype.reportValidity = function reportValidity(msg, validityType = null) {
  switch (validityType) {
    case FieldValidityType.Notify:
      if (msg) {
        this.notifyError(msg);
      }
      break;
    case FieldValidityType.Inline:
    default:
      if (msg) {
        this.setErrorMessage(msg);
      } else {
        this.unsetErrorMessage();
      }
  }

  return !!msg;
};

FieldElement.prototype.notifyError = function notifyError(message) {
  notify.alert(message);
};

FieldElement.prototype.setErrorMessage = function setErrorMessage(customMessage) {
  const rxErrorHas = /(^|\\s+)error(\\s+|$)/;
  const parent = this.parentNode;
  const $parent = $(parent);
  const webformNewItem = $parent.closest('[data-editable="webformNewItem"]');

  const message = customMessage || this.validationMessage;

  if (message) {
    const $field = $parent.closest('[data-editable-item="field"]');
    const { className } = $field[0];

    $field.attr('data-error-msg', message);
    if (!rxErrorHas.test(className)) {
      $field[0].className = [className, 'error'].join(' ').trim();
    }

    webformNewItem.addClass('make-error-msg-visible');
  } else {
    this.unsetErrorMessage();
  }
};

FieldElement.prototype.unsetErrorMessage = function unsetErrorMessage() {
  const parent = this.parentNode;
  const $parent = $(parent);
  const errorClassName = 'error';

  $parent.closest('[data-editable-item="field"]').removeAttr('data-error-msg');
  $parent.closest('[data-editable-item="field"]').removeClass(errorClassName);

  $parent.parent('[data-editable="webformNewItem"]').removeClass('make-error-msg-visible');
};

export default FieldElement;
