export enum Component {
  ARENA = 'arena',
  BUTTON = 'button',
  BUTTON_COOKIE_NOTIFICATION = 'buttonCookieNotification',
  BOX = 'box',
  BOX_COOKIE_NOTIFICATION = 'boxCookieNotification',
  TEXT = 'text',
  TEXT_COOKIE_NOTIFICATION = 'textCookieNotification',
  TEXT_PRODUCT_LIST_QUANTITY = 'textProductListQuantity',
  PAYPAL_BUTTON = 'paypal_button',
  VIDEO = 'video',
  IMAGE = 'image',
  VLINE = 'vline',
  HLINE = 'hline',
  WEBFORM = 'webform',
  WEBFORM_APP = 'webformApp',
  WEBFORM_NEW = 'webformNew',
  WEBFORM_NEW_BUTTON = 'webformNewButton',
  WEBFORM_NEW_ITEM = 'webformNewItem',
  SOCIAL = 'social',
  SECTION = 'section',
  WEBINAR = 'webinar',
  WEBINAR_NEW = 'webinarNew',
  WEBINAR_TEXT = 'webinarText',
  WEBINAR_BUTTON = 'webinarButton',
  CUSTOM_IFRAME = 'customIframe',
  CUSTOM_CODE = 'customCode',
  CIRCLE = 'circle',
  COUNTDOWN_TIMER = 'countdownTimer',
  COUNTDOWN_TIMER_DIGIT = 'countdownTimerDigit',
  COUNTDOWN_TIMER_LABEL = 'countdownTimerLabel',
  COUNTDOWN_TIMER_BOX = 'countdownTimerBox',
  PRODUCT_LIST = 'productList',
  PRODUCTS_BOX = 'productsBox',
  ORDER_FORM = 'orderForm',
  ORDER_FORM_ITEM = 'orderFormItem',
  STRIPE_PAYMENT_BUTTON = 'stripePaymentButton',
}
