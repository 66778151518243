import { PaymentProcessor } from '../../../enums/paymentProcessors';
import { ButtonType } from '../../../enums/buttonTypes';

import { LPSData } from '../models/config';

import { logger } from '../helpers/logger';

import { OtoPageStrategy } from '../strategies/oto_page/otoPage';
import { PayPalOtoPageStrategy } from '../strategies/oto_page/payPal';
import { StripeOtoPageStrategy } from '../strategies/oto_page/stripe';

export class OtoPage {
  private static strategy: OtoPageStrategy;

  private static setStrategy(strategy: OtoPageStrategy): void {
    OtoPage.strategy = strategy;
  }

  public static register(): void {
    const paymentProcessor = LPSData.getPaymentProcessor();

    switch (paymentProcessor) {
      case PaymentProcessor.PAYMENT_PROCESSOR_STRIPE:
        OtoPage.setStrategy(new StripeOtoPageStrategy());
        break;
      case PaymentProcessor.PAYMENT_PROCESSOR_PAYPAL:
        OtoPage.setStrategy(new PayPalOtoPageStrategy());
        break;
      default:
        logger.info('OTO Page Default Strategy');
        OtoPage.setStrategy(new OtoPageStrategy());
        break;
    }
  }

  public static disableProductBoxButtons(): void {
    const productBoxButtons = document.querySelectorAll<HTMLDivElement>(
      `[data-button-type="${ButtonType.OTO_PAGE_ACCEPT}"]`,
    );
    productBoxButtons.forEach((buttonComponent) => {
      buttonComponent.classList.add('processing');
    });
  }

  public static enableProductBoxButtons(): void {
    const productBoxButtons = document.querySelectorAll<HTMLDivElement>(
      `[data-button-type="${ButtonType.OTO_PAGE_ACCEPT}"]`,
    );
    productBoxButtons.forEach((buttonComponent) => {
      buttonComponent.classList.remove('processing');
    });
  }

  public static startOtoPageProcessPayment(productId: string): void {
    const otoPageAcceptUrl = LPSData.getOTOPageAcceptUrl();

    if (!otoPageAcceptUrl || !productId) {
      logger.log('Missing values: ', {
        productId,
        otoPageAcceptUrl,
      });
      return;
    }

    if (OtoPage.strategy) {
      OtoPage.disableProductBoxButtons();
      OtoPage.strategy.proceedPayment(otoPageAcceptUrl, productId);
    } else {
      logger.error('No strategy');
    }
  }
}
