import { catchTheMoment, getMoment, timeZones } from '@gr/translations';
import { Moment } from 'moment-timezone/moment-timezone';

interface ITimeParts {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isInThePast: boolean;
}

export class DatetimeHelper {
  private static moment = getMoment();

  private static getDateDiff(date1: Moment, date2: Moment): number {
    return date1.diff(date2); //nowDubaj.diff(nowPolska) / (1000*60*60)
  }

  public static now(): Moment {
    return catchTheMoment();
  }

  public static getDataSecondsFromNow(seconds: number): Moment {
    return DatetimeHelper.now().add(seconds, 'seconds');
  }

  public static getDate(date: string, timeZoneId: string): Moment {
    const timeZone = timeZones.find((tz) => tz.id === timeZoneId)?.name;
    return DatetimeHelper.moment.tz(date, timeZone);
  }

  public static isInThePast(date: Moment): boolean {
    return date.diff(DatetimeHelper.now()) <= 0; // DatetimeHelper.getRemainingTime(date, DatetimeHelper.now()).isInThePast;
  }

  public static getRemainingTime(date1: Moment, date2: Moment): ITimeParts {
    const diff = DatetimeHelper.getDateDiff(date1, date2);
    return DatetimeHelper.getTimeParts(diff);
  }

  public static getTimeParts(milliseconds: number): ITimeParts {
    const millisecondsInSecond = 1000;
    const secondInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;
    const millisecondsInMinute = millisecondsInSecond * secondInMinute;
    const millisecondsInHour = millisecondsInMinute * minutesInHour;
    const millisecondsInDay = millisecondsInHour * hoursInDay;

    const daysLeft = parseInt(String(milliseconds / millisecondsInDay), 10);
    const hoursLeft = parseInt(String((milliseconds % millisecondsInDay) / millisecondsInHour), 10);
    const minutesLeft = parseInt(String((milliseconds % millisecondsInHour) / millisecondsInMinute), 10);
    const secondsLeft = parseInt(String((milliseconds % millisecondsInMinute) / millisecondsInSecond), 10);

    return {
      days: daysLeft,
      hours: hoursLeft,
      minutes: minutesLeft,
      seconds: secondsLeft,
      isInThePast: milliseconds <= 0,
    };
  }
}
