import { BaseLogger } from '../helpers/logger';

export class Navigate {
  public static toExternal(url: string): void {
    BaseLogger.log('Redirecting to :: ', url);
    try {
      window.top.location.assign(url);
    } catch (topLevelException) {
      BaseLogger.warn('There is no top');
      try {
        window.location.assign(url);
      } catch (e) {
        /**
         * There can be security violation or url can be invalid
         */
        BaseLogger.error(e);
      }
    }
  }
}
