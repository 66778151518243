import webScreenerHelper from '../../preview/helpers/webScreener';

export default {
  /**
   * Initialize gr_badge modifier after badgeImage load
   *
   * @async
   * @function initialize
   * @param {boolean} isPreview
   * */
  initialize: async function (isPreview = false) {
    if (webScreenerHelper.isWebscreener()) {
      return;
    }

    const badgeData = this.getBadgeData();
    let sectionsHeight = this.getFullHeight();
    let img;

    try {
      img = await this.initializeImage(badgeData.imageUrl);
    } catch (e) {
      return;
    }

    const { width: imageWidth, height: imageHeight } = img;

    const anchorConfig = {
      styles: {
        position: 'absolute',
        left: 'calc(50vw - ' + imageWidth / 2 + 'px)',
        top: sectionsHeight + imageHeight / 2 + 'px',
        width: imageWidth + 'px',
        height: imageHeight + 'px',
        zIndex: 999999999,
      },
      attributes: {
        href: badgeData.redirectUrl,
        rel: 'nofollow',
        target: '_blank',
      },
    };

    const anchor = this.initializeAnchor(anchorConfig, img);

    this.adjustLastSection(imageHeight);
    this.placeBadge(anchor, isPreview);

    if (this.hasCustomCode()) {
      this.protectBadge();
    }
  },

  /**
   * Create new Image
   *
   * @function initializeImage
   * @param {string} imageUrl - The URL of badgeimage
   * @return {Promise<HTMLElement|undefined>} Loaded image or nothing if an error occurs
   * */
  initializeImage: function (imageUrl) {
    return new Promise((resolve, reject) => {
      this.badgeImage = new Image();

      Object.assign(this.badgeImage.style, {
        height: '100%',
      });

      this.badgeImage.onload = function () {
        resolve(this);
      };
      this.badgeImage.onerror = function () {
        reject();
      };
      this.badgeImage.src = imageUrl;
    });
  },

  /**
   * Create new Anchor with image
   *
   * @function initializeAnchor
   * @param {object} config - Configuration of new anchor element
   * @param {HTMLElement} img - HTML Element od image
   * @return {HTMLElement} Created anchor element
   * */
  initializeAnchor: function (config, img) {
    const { styles, attributes } = config;
    const anchor = document.createElement('a');
    const randomString = (Math.random() + 1).toString(36).substring(2, 9);

    anchor.className += 'link-' + randomString;

    Object.assign(anchor, { ...attributes });
    Object.assign(anchor.style, { ...styles });

    anchor.appendChild(img);

    return anchor;
  },

  /**
   * Get sum of height of all sections on the page
   *
   * @function getFullHeight
   * @return {Number} sum of height of all sections
   * */
  getFullHeight: function () {
    const sections = document.querySelectorAll('[data-section="section"]');
    let sectionsHeight = 0;
    for (let i = 0; i < sections.length; i++) {
      const sectionRect = sections[i].getBoundingClientRect();
      sectionsHeight += sectionRect.height;
    }
    return sectionsHeight;
  },

  /**
   * Place anchor element with image in random place of page body
   *
   * @function placeBadge
   * @param {HTMLElement} anchor - HTML Element od image
   * @param {boolean} isPreview
   * */
  placeBadge: function (anchor, isPreview) {
    const root = isPreview ? document.getElementById('Preview') : document.body;
    /**
     * @var {Array} rootChildren
     * */
    const rootChildren = root.children;
    const randomIndex = Math.floor(Math.random() * Math.floor(rootChildren.length - 1));

    root.insertBefore(anchor, rootChildren[randomIndex]);
  },

  /**
   * Increase height of last section by double image size
   *
   * @function adjustLastSection
   * @param {Number} imageHeight - Height of an image
   * */
  adjustLastSection: function (imageHeight) {
    const sections = document.querySelectorAll('[data-section="section"]');
    const lastSection = sections[sections.length - 1];

    Object.assign(lastSection.style, {
      height: lastSection.getBoundingClientRect().height + imageHeight * 2 + 'px',
      // marginBottom: (imageHeight * 2) + 'px',
    });
  },

  /**
   * Get grBadge data from window.grLpsInitialData
   *
   * @function getBadgeData
   * @return {Object|null} Object with image url and redirectUrl or null if on of them doesn't exist in initialData
   * */
  getBadgeData: function () {
    /**
     * @var {Object} grLpsInitialData
     * */
    const { grBadge } = window.grLpsInitialData;
    const { imageUrl, redirectUrl } = grBadge || {};

    if (!imageUrl || !redirectUrl) {
      return null;
    } else {
      return {
        imageUrl,
        redirectUrl,
      };
    }
  },

  /**
   * Check if page contains customCode element
   *
   * @function hasCustomCode
   * @return {boolean} true if page contains any customCode element
   * */
  hasCustomCode: function () {
    const customCode = document.querySelector('[data-editable="customCode"]');
    return !!customCode;
  },

  /**
   * A bit of protection to prevent badge removal.
   * Should starts only if page contains customCode element.
   * If badgeImage has been removed add it again.
   * Every time after adding badge, new intervalId is generated.
   *
   * @function protectBadge
   * */
  protectBadge: function () {
    const _self = this;
    const intervalId = setInterval(function () {
      if (!document.contains(_self.badgeImage)) {
        _self.initialize();
        clearInterval(intervalId);
      }
    }, 500);
  },
};
