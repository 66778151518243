import { LPSData } from '../models/config';

import { Navigate } from '../../../actions/navigate';
import { notify } from './growler';

export default {
  paymentStatusRequestInProgress: false,
  paymentStatusRequestTimer: null,

  getTextFieldAutoFillValue(name) {
    const fieldsValues = LPSData.getFormFieldsAutoFillValues();
    const field = fieldsValues.find((field) => {
      return field.name === name;
    });

    return field ? field.value : null;
  },

  getProductFieldAutoFillChecked(name, value) {
    const fieldsValues = LPSData.getFormFieldsAutoFillValues();
    const field = fieldsValues.find((field) => {
      return field.name === name && field.value === value;
    });

    return field ? true : null;
  },

  /**
   *
   * @param {Object|string|Array} data
   * @param {string} processPaymentUrl
   * @param {function} callback
   *
   * @return undefined
   */
  startProcessPayment(data, processPaymentUrl, callback) {
    $.ajax({
      url: processPaymentUrl,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: data,
    }).done(callback);
  },

  prepareProcessPayment(data, callback) {
    $.ajax({
      url: 'process-payment/bluesnap/prepare',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: data,
    }).done(callback);
  },

  _checkPaymentStatus(uuid, callback) {
    if (!this.paymentStatusRequestInProgress) {
      this.paymentStatusRequestInProgress = true;

      $.ajax({
        url: LPSData.getPaymentStatusUrl(),
        method: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        data: {
          uuid: uuid,
        },
      }).done(
        function (response) {
          if (response.isSuccess || response.isError) {
            window.clearInterval(this.paymentStatusRequestTimer);
          }

          callback(response);

          this.paymentStatusRequestInProgress = false;
        }.bind(this),
      );
    }
  },

  checkPaymentStatus(uuid, callback) {
    this._checkPaymentStatus(uuid, callback);

    this.paymentStatusRequestTimer = setInterval(
      function () {
        this._checkPaymentStatus(uuid, callback);
      }.bind(this),
      3000,
    );
  },

  enableAllFields(formElement, orderForm) {
    this._toggleFormFields(formElement, false);

    if (orderForm) {
      orderForm.updateFields({ disabled: false });
    }
  },

  disableAllFields(formElement, orderForm) {
    this._toggleFormFields(formElement, true);

    if (orderForm) {
      orderForm.updateFields({ disabled: true });
    }
  },

  /**
   * @param {Object} response
   * @param {boolean} response.isSuccess
   * @param {boolean} response.isError
   * @param {string} response.confirmationUrl
   * @param {string} response.statusNote
   *
   * @param {function} onErrorCb
   */
  checkPaymentStatusCallback(response, onErrorCb) {
    if (response.isSuccess) {
      Navigate.toExternal(response.confirmationUrl);
    } else if (response.isError) {
      notify.alert(response.statusNote);
      onErrorCb();
    }
  },

  _toggleFormFields(formElement, disabled) {
    const editableSelectors = [
      '[data-editable="webformNewItem"]',
      '[data-editable="webformNewButton"]',
      '[data-editable="productList"]',
      '[data-editable="orderForm"]',
    ];

    const inputsSelectors = ['input', 'textarea', 'select', 'button'];

    const formEditableElements = [...formElement.querySelectorAll(editableSelectors.join(', '))];

    formEditableElements.forEach((formEditableElement) => {
      const inputsElements = [...formEditableElement.querySelectorAll(inputsSelectors.join(', '))];

      formEditableElement.classList[disabled ? 'add' : 'remove']('processing');

      inputsElements.forEach((inputElement) => {
        inputElement.disabled = disabled;
      });
    });

    const phoneFieldsElements = [
      ...formElement.querySelectorAll('[data-editable="webformNewItem"][data-item-what="phone"]'),
    ];

    phoneFieldsElements.forEach((phoneFieldElement) => {
      phoneFieldElement.setAttribute('data-disabled', disabled);
    });
  },
};
