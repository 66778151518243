export default {
  initialize: function () {
    return new Promise((resolve, reject) => {
      const isConvertedToNewMobile = $('body').attr('data-optimize-phone') === 'true';

      //Jeśli nie jest skonvertowany do starego mobile view, dodaj mu stare style CSS
      if (!isConvertedToNewMobile) {
        const styleTag = document.createElement('link');
        styleTag.type = 'text/css';
        styleTag.rel = 'stylesheet';
        styleTag.href = window.grLpsInitialData.url_to_old_mobile_styles;

        styleTag.onload = function () {
          resolve(this);
        };

        styleTag.onerror = function () {
          reject();
        };

        (document.head || document.getElementsByTagName('head')[0]).appendChild(styleTag);
      } else {
        resolve();
      }
    });
  },
};
