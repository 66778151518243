import { IAutoFunnel, IECommerceData } from '../../../interfaces/lpsShow';

export const AutoFunnelDefault: IAutoFunnel = {
  autoFunnelType: null,
  autoFunnelPaymentStatusUrl: '',
  boughtProducts: [],
  cartId: null,
  currency: '',
  formFieldsValues: [],
  id: '',
  orderFormUrl: '',
  paymentProcessor: null,
  paymentProcessorToken: '',
  paymentStripeAPIKey: '',
  paymentStripeCountryCode: 'US',
  processPaymentUrl: '',
  stepId: '',
  stepType: '',
  stripePaymentRequestButtonUrl: '',
  autoFunnelPaymentSquareLocationId: '',
  otoPageDismissUrl: '#',
  otoPageAcceptUrl: '#',
  enableOtoPageAction: false,
};

export const ECommerceDataDefault: IECommerceData = {
  shops: [
    {
      shopId: '',
      currency: '',
      productsVariants: [],
    },
  ],
};
