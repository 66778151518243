/* tslint:disable:max-line-length */
import { TranslationKey } from '../../../interfaces/lpsShow';
import { LPSData } from '../models/config';
import { IL10n } from '../../../interfaces/l10n';

export const defaultTranslations = {
  [TranslationKey.ModSqueezePageLPCCreatePageAutoFunnelBtnProcessingCaption]: 'Processing',
  [TranslationKey.ModSqueezePageLPCCreatePageAutoFunnelProductsNotSelected]: 'Please select a product',
  [TranslationKey.ModSqueezePageLPCCreatePageGrowlerErrorName]: 'Error',
  [TranslationKey.ModSqueezePageLPCCreatePageGrowlerInfoName]: 'Info',
  [TranslationKey.ModSqueezePageLPCCreatePageGrowlerSuccessName]: 'Success',
  [TranslationKey.ModSqueezePageLPCCreatePageGrowlerWarningName]: 'Warning',
  [TranslationKey.ModSqueezePageLPCCreatePageWebformWebformFieldsRequiredField]: 'This information is required',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageDownload]: 'Download',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageGoToURL]: 'Go to URL',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageJoinTheWebinar]: 'Join the webinar',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageProductDeliveryTokenTitle]: 'Token to join:',
  [TranslationKey.ModSqueezePageLPCFunnelOrderFormPaymentGrowlerNoPostalCodeError]:
    "We're sorry, but a postal code is required to complete this transaction. Please contact the vendor.",
  [TranslationKey.ModSqueezePageLPCCreatePageInvalidDateFormat]: 'Datetime format not recognized.',
  [TranslationKey.ModSqueezePageLPCCreatePageDatePlaceholder]: 'yyyy-mm-dd',
  [TranslationKey.ModSqueezePageLPCFunnelConfirmationPageQuantity]: 'Quantity:',
};

export function l10n(): IL10n {
  return {
    getTranslation(key: string): string {
      const translations = LPSData.getL10n();

      return translations[key] || defaultTranslations[key];
    },
  };
}
