import { EditorLogger } from './logger';

window.socialButtonsStorage = {};

export default {
  socialrequire(opt) {
    const _self = this;
    try {
      let t;
      const doc = opt.doc || document;
      const elementName = opt.elementName || 'script';
      const script = doc.getElementsByTagName(elementName)[0];

      if (Array.isArray(opt)) {
        opt.forEach((el) => {
          _self.socialrequire(el);
        });
        return;
      }

      if (doc.getElementById(opt.id)) {
        return;
      }

      const js = doc.createElement(elementName);
      js.id = opt.id;
      js.src = opt.src;
      script.parentNode.insertBefore(js, script);

      if (opt.exports) {
        window.socialButtonsStorage[opt.exports] =
          window.socialButtonsStorage[opt.exports] ||
          (t = {
            _e: [],
            ready(f) {
              t._e.push(f);
            },
          });
      }
    } catch (ex) {
      EditorLogger.error(ex);
    }
  },
};
