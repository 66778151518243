import { LPSData } from '../models/config';

export interface IStripePaymentRequest {
  label: string;
  amount: number;
  country: string;
  currency: string;
  requestPayerName: boolean;
  requestPayerEmail: boolean;
}

export function StripePaymentRequestHelper(): IStripePaymentRequest {
  const currency = LPSData.getAutoFunnelCurrency() || '';
  const country = LPSData.getPaymentStripeCountryCode();
  return {
    label: 'No products selected',
    amount: 0,
    country,
    currency: currency.toLowerCase(),
    requestPayerName: true,
    requestPayerEmail: true,
  };
}
